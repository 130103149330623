import React, { useState } from "react"
import { NavLink } from "react-router-dom"
import { DateTime } from "luxon"
import axios from "axios"

import {
  Box,
  Link,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
  useMediaQuery
} from "@mui/material"

import RegistrationStepIntro from "./RegistrationStepIntro.component"
import RegistrationStepAccreditation from "./RegistrationStepAccreditation.component"
import RegistrationStepUserInfo from "./RegistrationStepUserInfo.component"
import RegistrationStepAccount from "./RegistrationStepAccount.compnent"
import RegistrationStepConfirmation from "./RegistrationStepConfirmation.component"
import MobileStepActions from "../../common/mui/MobileStepActions.component"
import DesktopStepActions from "../../common/mui/DesktopStepActions"

const steps = ["Welcome!", "Investor Status", "Personal Info", "Credentials"]

const Registration = () => {
  const [activeStep, setActiveStep] = useState(0)
  const [validateStep, setValidateStep] = useState(null)
  const [registrationError, setRegistrationError] = useState("")
  const [formValues, setFormValues] = useState({
    investorStatus: "",
    riskTolerance: "",
    investmentExperience: "",
    investmentYears: "",
    investmentSize: "",
    username: "",
    fname: "",
    lname: "",
    company: "",
    phone: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    country: ""
  })
  const [passwordValues, setPasswordValues] = useState({
    password: "",
    confirmPassword: ""
  })

  const mobile = useMediaQuery("(max-width: 600px)")

  const handleFormChange = (prop) => (event) => setFormValues({ ...formValues, [prop]: event.target.value })

  const validateBeforeNext = () => {
    setValidateStep(activeStep)
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
    window.scrollTo(0, 0)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
    window.scrollTo(0, 0)
  }

  const addUser = (userObj, password) => {
    const dbObj = {
      address: {
        city: userObj.city,
        country: userObj.country,
        state: userObj.state,
        street: userObj.address,
        zip: userObj.zip
      },
      company: userObj.company,
      created: DateTime.now().setZone('America/New_York').toISO(),
      fname: userObj.fname,
      lname: userObj.lname,
      phone: userObj.phone,
      questionnaire: {
        investmentExperience: userObj.investmentExperience,
        investmentSize: userObj.investmentSize,
        investmentYears: userObj.investmentYears,
        investorStatus: userObj.investorStatus,
        riskTolerance: userObj.riskTolerance
      },
      role: "user",
      status: "pending",
      username: userObj.username
    }

    const authObj = {
      email: userObj.username,
      fname: userObj.fname,
      lname: userObj.lname,
      password,
      sendEmail: false
    }

    axios.post("/api/firebase/auth/create", authObj).then(res => {
      const userId = res.data

      // Add user to rtdb
      axios.post("/api/users/add", {
        userId,
        dbObj
      }).then(() => {
        // advance to final step
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
        window.scrollTo(0, 0)

        // notify admins by email
        const emailOptions = {
          from: "Invstor Portal Admin <noreply@mg.chenelcapital.com>",
          to: 'admin@chenelcapital.com',
          subject: "Action Required: New Investor Registration",
          template: "investor-registration",
          mgVariables: {
            'v:fname': userObj.fname,
            'v:company': userObj.company,
            'v:country': userObj.country,
            'v:investorStatus': userObj.investorStatus,
            'v:riskTolerance': userObj.riskTolerance,
            'v:investmentExperience': userObj.investmentExperience,
            'v:investmentYears': userObj.investmentYears,
            'v:investmentSize': userObj.investmentSize,
          }
        }
        axios.post("/api/mail/send", emailOptions).catch(error => {
          console.log('error sending admin email')
        })

      }).catch(error => {
        console.log('error creating user in database: ', error.message)
        // remove auth account that was just created
        axios.post("/api/firebase/auth/delete", { 
          email: authObj.email
        }).then(() => {
          console.log('auth account deleted')
        }).catch(error => {
          console.log('error deleting auth acount', error.message)
        })
      })
    }).catch(error => {
      console.log('error creating auth account', error.message)
    })

  }

  const handleSubmit = () => addUser(formValues, passwordValues.password)

  return (
    <>
      {!mobile && (
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
      )}
      {activeStep === steps.length ? (
        <RegistrationStepConfirmation />
      ) : (
        <>
          {activeStep === 0 ? (
            <RegistrationStepIntro
              validateStep={validateStep}
              setValidateStep={setValidateStep}
              handleNext={handleNext}
            />
          ) : (
            <Box sx={{
              border: '1px solid',
              borderColor: 'grey.200',
              borderRadius: 1,
              overflowY: 'auto',
              p: 2,
              ...(!mobile && { maxHeight: '50vh', })
            }}>
              {activeStep === 1 && (
                <RegistrationStepAccreditation
                  formValues={formValues}
                  handleFormChange={handleFormChange}
                  validateStep={validateStep}
                  setValidateStep={setValidateStep}
                  handleNext={handleNext}
                  setRegistrationError={setRegistrationError}
                />
              )}
              {activeStep === 2 && (
                <RegistrationStepUserInfo
                  formValues={formValues}
                  setFormValues={setFormValues}
                  handleFormChange={handleFormChange}
                  validateStep={validateStep}
                  setValidateStep={setValidateStep}
                  handleNext={handleNext}
                  setRegistrationError={setRegistrationError}
                />
              )}
              {activeStep === 3 && (
                <RegistrationStepAccount
                  formValues={formValues}
                  passwordValues={passwordValues}
                  setPasswordValues={setPasswordValues}
                  validateStep={validateStep}
                  setValidateStep={setValidateStep}
                  handleSubmit={handleSubmit}
                />
              )}
            </Box>
          )}
          <Stack spacing={2} mt={2}>
            {mobile ? (
              <MobileStepActions
                activeStep={activeStep}
                handleNext={validateBeforeNext}
                handleBack={handleBack}
                error={registrationError}
              />
            ) : (
              <DesktopStepActions
                steps={steps}
                activeStep={activeStep}
                handleNext={validateBeforeNext}
                handleBack={handleBack}
                error={registrationError}
              />
            )}
            <Typography textAlign="center">
              Already have an account?{" "}
              <Link component={NavLink} to="/login" underline="none">Login</Link>
            </Typography>
          </Stack>
        </>
      )}
    </>
  )
}
 
export default Registration
