import React, { useState } from "react"
import { useSelector } from "react-redux"

import { styled } from "@mui/material/styles"
import { Box, Paper, Typography } from "@mui/material"

import { selectCommunicationsList } from "../../../app/store/communicationsSlice"

import PageTitle from "../../../common/sharedComponents/PageTitle.component"
import CommunicationsTableToolbar from "./CommunicationsTableToolbar.component"
import CommunicationsTable from "./CommunicationsTable.component"

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: '.25rem',
  width: '100%',
  [theme.breakpoints.up('md')]: {
    padding: '1rem',
  }
}))

const CommunicationsPage = () => {
  const communications = useSelector(selectCommunicationsList)
  const [search, setSearch] = useState("")
  
  const getVisibleCommunications = (allCommunications) => {
    return allCommunications.filter(communication => {
      return communication.subject.toLowerCase().includes(search.toLowerCase()) ||
             communication.body.toLowerCase().includes(search.toLowerCase())
    })
  }
  
  return (
    <Box>
      <PageTitle title="Communications" />
      <StyledPaper>
        <CommunicationsTableToolbar search={search} setSearch={setSearch} />
        {communications.length > 0 ? (
          <CommunicationsTable communications={getVisibleCommunications(communications)} />
        ) : (
          <Typography fontSize=".875rem" p={4}>
            No communications found
          </Typography>
        )}
      </StyledPaper>
    </Box>
  )
  }
 
export default CommunicationsPage
