import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { DateTime } from "luxon"

import { fetchUser, setLastActive } from './authFetch'

const initialState = {
  status: "idle",
  uid: "",
  id: "",
  company: "",
  fname: "",
  lname: "",
  role: "",
  superAdmin: false,
  username: "",
}

export const startSetAuth = createAsyncThunk(
  'user/fetchUser',
  async (user) => {
    const response = await fetchUser(user.uid)
    const lastActive = DateTime.now().setZone('America/New_York').toISO()
    setLastActive(user.uid, lastActive)
    return {
      uid: user.uid,
      lastActive,
      ...response
    }
  }
)

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuth: (state, action) => ({
      ...action.payload
    }),
    updateAuth: (state, action) => ({
      ...state,
      ...action.payload
    }),
    clearAuth: () => (initialState)
  },
  extraReducers: (builder) => {
    builder
      .addCase(startSetAuth.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(startSetAuth.fulfilled, (state, action) => ({
        status: 'idle',
        ...action.payload
      }))
  },
})

export const { setAuth, updateAuth, clearAuth } = authSlice.actions

export const selectUser = (state) => state.auth
export const selectUserId = (state) => state.auth.uid
export const selectUserName = (state) => ({ fname: state.auth.fname, lname: state.auth.lname })
export const selectUserRole = (state) => state.auth.role
export const selectSuperAdmin = (state) => state.auth.superAdmin
export const selectUserEmail = (state) => state.auth.username
export const selectUserInvestments = (state) => state.auth.investments || null
export const selectUserInvestmentById = (id) => (state) => state.auth.investments ? state.auth.investments[id] : null

export default authSlice.reducer
