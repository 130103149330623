import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { DateTime } from "luxon"
import axios from "axios"

import useMediaQuery from "@mui/material/useMediaQuery"
import { styled } from "@mui/material/styles"
import { Grid } from "@mui/material"

import { selectUserById, selectUsersList } from "../../../app/store/usersSlice"
import { updateCommunication } from "../../../app/store/communicationsSlice"

import CommunicationsDrawer from "../../../common/sharedComponents/CommunicationsDrawer.component"

const StyledRow = styled(Grid)(({ theme }) => ({
  borderBottom: '1px solid ' + theme.palette.grey[300],
  cursor: 'pointer',
  overflow: 'hidden',
  '&:hover': {
    backgroundColor: theme.palette.grey[100],
  },
}))

const StyledCell = styled(Grid)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  padding: '.875rem .5rem',
  width: '200px', // prevents cell from expanding to fit text
  [theme.breakpoints.up('sm')]: {
    padding: '1rem .5rem',
  },
}))

const StyledSpanTruncated = styled('span')(() => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}))

const CommunicationsRow = ({ communication }) => {
  const isSmScreen = useMediaQuery("(min-width: 600px)")
  const dispatch = useDispatch()
  const sender = useSelector(selectUserById(communication.sender))
  const users = useSelector(selectUsersList)
  let recipient = ""
  if (communication.recipientId) {
    if (communication.recipientId === "admins") {
      recipient = "Chenel Capital"
    } else {
      const recipientObj = users.filter(user => user.id === communication.recipientId)[0]
      recipient = `${recipientObj.lname}, ${recipientObj.fname}`
    }
  }

  const [drawerOpen, setDrawerOpen] = useState(false)
  const [activeCommunication, setActiveCommunication] = useState(null)

  const handleOpenCommunication = communication => {
    // mark communication as read
    if (!communication.read) {
      axios.post("/api/communications/mark-read-admin", {
        communicationId: communication.id
      }).then(() => {
        dispatch(updateCommunication({
          id: communication.id,
          adminRead: true
        }))
      }).catch(error => {
        console.log("error marking communication as read")
      })
    }

    // open drawer
    setActiveCommunication(communication)
    setDrawerOpen(true)
  }

  const handleCloseCommunication = () => {
    setDrawerOpen(false)
    setActiveCommunication(null)
  }

  return (
    <>
      <StyledRow
        container
        onClick={() => handleOpenCommunication(communication)}
        role="checkbox"
        tabIndex={-1}
        fontWeight={!communication.adminRead ? "600" : "400"}
      >
        <StyledCell item xs={6} sm={4} md={4} lg={6}>
          <StyledSpanTruncated>
            {communication.subject}
          </StyledSpanTruncated>
        </StyledCell>
        <StyledCell item xs={3} sm={3} md={3} lg={2}>
          <StyledSpanTruncated>
            {recipient}
          </StyledSpanTruncated>
        </StyledCell>
        {isSmScreen && (
          <StyledCell item sm={3} md={3} lg={2}>
            <StyledSpanTruncated>
              {`${sender.lname}, ${sender.fname}`}
            </StyledSpanTruncated>
          </StyledCell>
        )}
        <StyledCell item xs={3} sm={2} md={2} lg={2}>
          <StyledSpanTruncated>
            {communication.created && (
              isSmScreen ? (
                DateTime.fromISO(communication.created).toFormat('LL/dd/yyyy')
              ) : (
                DateTime.fromISO(communication.created).toFormat('L/d/yy')
              )
            )}
          </StyledSpanTruncated>
        </StyledCell>
      </StyledRow>
      <CommunicationsDrawer
        drawerOpen={drawerOpen}
        handleDrawerClose={handleCloseCommunication}
        communicationId={activeCommunication ? activeCommunication.id : ""}
      />
    </>
  )
}
 
export default CommunicationsRow
