import React from "react"
import { useSelector } from "react-redux"

import { Box, Grid } from "@mui/material"

import { selectFundsList } from "../../../app/store/fundsSlice"

import CardTitle from "../../../common/sharedComponents/CardTitle.component"
import PortfolioCard from "../portfolio/PortfolioCard.component"

const PortfolioOverview = () => {
  const portfolio = useSelector(selectFundsList)

  return (
    <Box mt={4}>
      <CardTitle title="Chenel Capital Investment Opportunities" />
      <Grid container spacing={3}>
        {portfolio
          .slice()
          .sort((a, b) => {
            if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
            if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
            return 0
          })
          .map((fund) => (
            <Grid item key={fund.name} xs={12} sm={6} xl={3}>
              <PortfolioCard fund={fund} />
            </Grid>
          ))}
      </Grid>
    </Box>
  )
}

export default PortfolioOverview
