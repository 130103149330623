import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { getDatabase, ref, set } from "firebase/database"

import useMediaQuery from "@mui/material/useMediaQuery"
import { Box, Button } from "@mui/material"

import { setAlert } from "../../../app/store/alertsSlice"
import { updateUser } from "../../../app/store/usersSlice"

import CardTitle from "../../../common/sharedComponents/CardTitle.component"
import UserAccreditationStatusForm from "../../../common/sharedComponents/UserAccreditationStatusForm.component"

const AccreditationStatus = ({ user }) => {
  const dispatch = useDispatch()
  const database = getDatabase()
  const isLarge = useMediaQuery("(min-width: 1200px)")

  const [formValues, setFormValues] = useState({
    investorStatus: user.questionnaire ? user.questionnaire.investorStatus || "" : "",
    riskTolerance: user.questionnaire ? user.questionnaire.riskTolerance || "" : "",
    investmentExperience: user.questionnaire ? user.questionnaire.investmentExperience || "" : "",
    investmentYears: user.questionnaire ? user.questionnaire.investmentYears || "" : "",
    investmentSize: user.questionnaire ? user.questionnaire.investmentSize || "" : ""
  })

  const handleFormChange = (prop) => (event) => setFormValues({ ...formValues, [prop]: event.target.value })

  const handleSaveChanges = () => {
    const questionnaire = {
      investorStatus: formValues.investorStatus,
      riskTolerance: formValues.investorStatus === "Natural Person" ? formValues.riskTolerance : "",
      investmentExperience: formValues.investorStatus === "Natural Person" ? formValues.investmentExperience : "",
      investmentYears: formValues.investorStatus === "Natural Person" ? formValues.investmentYears : "",
      investmentSize: formValues.investorStatus === "Natural Person" ? formValues.investmentSize : ""
    }

    // Update user in rtdb
    set(ref(database, "users/" + user.uid + "/questionnaire"), questionnaire).catch(error => {
      console.error(error)
    }).then(() => {
      // Update document in store
      dispatch(updateUser({
        id: user.id,
        questionnaire
      }))

      // udpate is complete
      dispatch(setAlert({ message: "Changes have been saved", severity: "success" }))
    }).catch(error => {
      console.log("error writing to db", error.response.data)
      dispatch(setAlert({ message: "Error saving changes. Please referesh and try again.", severity: "error" }))
    })

  }

  return (
    <Box mt={isLarge ? 2 : 0}>
      <CardTitle title="Updated Your Accreditation Status" />
      <UserAccreditationStatusForm
        formValues={formValues}
        handleFormChange={handleFormChange}
        disabled={false}
      />
      <Button
        onClick={() => handleSaveChanges()}
        variant="contained"
      >
        Save Changes
      </Button>
    </Box>
  )
}
 
export default AccreditationStatus
