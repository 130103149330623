import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import axios from "axios"

import useMediaQuery from "@mui/material/useMediaQuery"
import { Button, Card, Grid, Stack, TextField, Typography } from "@mui/material"

import {
  selectSystemInfo,
  updateSystemInfo,
} from "../../../app/store/systemInfoSlice"
import { setAlert } from "../../../app/store/alertsSlice"

const ManageSystemInfo = () => {
  const isLarge = useMediaQuery("(min-width: 1200px)")
  const systemInfo = useSelector(selectSystemInfo)
  const dispatch = useDispatch()
  const [text, setText] = useState(systemInfo?.alert?.text || "")
  const [actionText, setActionText] = useState(
    systemInfo?.alert?.action?.text || ""
  )
  const [actionUrl, setActionUrl] = useState(
    systemInfo?.alert?.action?.location || ""
  )

  const onTextChange = (e) => setText(e.target.value)
  const onActionTextChange = (e) => setActionText(e.target.value)
  const onActionUrlChange = (e) => setActionUrl(e.target.value)

  const saveUpdate = (updatesObj) => {
    axios
      .post("/api/system/update", {
        updatesObj,
      })
      .then(() => {
        // Update systemInfo in store
        dispatch(updateSystemInfo(updatesObj))

        // udpate is complete
        dispatch(
          setAlert({
            message: `Alert updates saved`,
            severity: "success",
          })
        )
      })
      .catch((error) => {
        console.log("error writing to db", error.response.data)
        dispatch(
          setAlert({ message: "Error saving updates", severity: "error" })
        )
      })
  }

  const handleClear = () => {
    saveUpdate({
      alert: {
        text: "",
        action: {
          location: "",
          text: "",
        },
      },
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    saveUpdate({
      alert: {
        text,
        action: {
          location: actionUrl,
          text: actionText,
        },
      },
    })
  }

  return (
    <Card sx={{ fontSize: ".875rem", p: { xs: 2, md: 4 }, width: "100%" }}>
      <Typography fontSize=".875rem" pb={1}>
        Set a system alert. This alert will display on the dashboard for all
        Investors.
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Alert Text"
              name="text"
              onChange={onTextChange}
              required
              size={isLarge ? "medium" : "small"}
              value={text}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextField
              fullWidth
              label="Action (Button) Text"
              name="actionText"
              onChange={onActionTextChange}
              size={isLarge ? "medium" : "small"}
              value={actionText}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextField
              fullWidth
              label="Action (Button) URL"
              name="text"
              onChange={onActionUrlChange}
              size={isLarge ? "medium" : "small"}
              value={actionUrl}
            />
          </Grid>
          <Grid item xs={12} sx={{ mt: ".75rem" }}>
            <Stack spacing={2} direction="row">
              <Button
                type="submit"
                variant="contained"
                size={isLarge ? "medium" : "small"}
              >
                Save Alert
              </Button>
              <Button
                color="error"
                onClick={handleClear}
                size={isLarge ? "medium" : "small"}
                variant="outlined"
              >
                Clear Alert
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </Card>
  )
}

export default ManageSystemInfo
