import { getDatabase, ref, get } from "firebase/database"
import axios from "axios"

const database = getDatabase()

export const fetchAllCommunications = () => {
  return new Promise((resolve) => {
    axios
      .get("/api/communications/all")
      .then((res) => {
        const data = res.data
        const dataArray = []
        for (const [key, value] of Object.entries(data)) {
          dataArray.push({
            id: key,
            ...value,
          })
        }
        resolve(dataArray)
      })
      .catch((error) => {
        console.log(error.response.data)
        resolve([])
      })
  })
}

export const fetchCommunicationsByUser = (uid) => {
  return new Promise((resolve) => {
    get(ref(database, "userCommunications/" + uid))
      .then(async (userCommsSnapshot) => {
        const communicationArray = []
        if (userCommsSnapshot.exists()) {
          const communicationIdArray = Object.keys(userCommsSnapshot.val())

          await Promise.all(
            communicationIdArray.map(async (communicationId) => {
              await get(ref(database, "communications/" + communicationId))
                .then((commsSnapshot) => {
                  if (commsSnapshot.exists()) {
                    const data = commsSnapshot.val()
                    communicationArray.push({
                      id: communicationId,
                      read: userCommsSnapshot.val()[communicationId].read,
                      ...data,
                    })
                  }
                })
                .catch((error) => {
                  console.error(error)
                })
            })
          )
        }
        resolve(communicationArray)
      })
      .catch((error) => {
        console.error(error)
        resolve([])
      })
  })
}
