import React, { useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useSelector } from "react-redux"

import useMediaQuery from "@mui/material/useMediaQuery"
import { styled } from "@mui/styles"
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material"

import { selectUserInvestmentById } from "../../../app/store/authSlice"
import { selectFundByKey } from "../../../app/store/fundsSlice"

import PageTitle from "../../../common/sharedComponents/PageTitle.component"
import InvestmentOverview from "./InvestmentOverview.component"
import FundInquiry from "./FundInquiry.component"

const StyledTableContainer = styled(Box)(({ theme }) => ({
  fontSize: ".875rem",
  marginTop: theme.spacing(2),
  "& th": {
    margin: 0,
    textAlign: "left",
  },
  "& th, & td": {
    borderBottom: "1px solid",
    borderBottomColor: theme.palette.grey[300],
    padding: theme.spacing(0.5, 1),
  },
}))

const FundPage = () => {
  const isMedium = useMediaQuery("(min-width: 900px)")
  const navigate = useNavigate()
  const { fundKey } = useParams()
  const fund = useSelector(selectFundByKey(fundKey))
  const investment = useSelector(selectUserInvestmentById(fund.id))

  useEffect(() => {
    if (!fund) navigate("/portfolio")
  }, [fund, navigate])

  return (
    <Box>
      <PageTitle title={fund.name} />
      <Card>
        <CardMedia
          alt=""
          component="img"
          height={isMedium ? 300 : 200}
          image={isMedium ? fund.imageLg : fund.imageSm}
          pb={4}
        />
        <CardContent>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={investment?.investedCapital ? 12 : 8}>
              <Typography
                dangerouslySetInnerHTML={{ __html: fund.overview }}
                sx={{ whiteSpace: "pre-line" }}
              />
            </Grid>
            {!investment?.investedCapital && (
              <Grid
                item
                xs={12}
                sm={4}
                sx={{
                  alignSelf: "flex-start",
                  display: "flex",
                  justifyContent: { xs: "center", sm: "flex-end" },
                }}
              >
                <FundInquiry fundName={fund.name} />
              </Grid>
            )}
          </Grid>
          {investment?.investedCapital && (
            <Grid item xs={12}>
              <InvestmentOverview investment={investment} />
            </Grid>
          )}
          <Grid container mb={4} mt={2} spacing={4}>
            {fund.section1Title && fund.section1Content && (
              <Grid item xs={12} md={6}>
                <Typography
                  component="span"
                  fontWeight="500"
                  pb={0.5}
                  sx={{
                    borderBottom: "2px solid",
                    borderBottomColor: "tertiary.main",
                  }}
                >
                  {fund.section1Title}
                </Typography>
                <StyledTableContainer
                  mt={2}
                  dangerouslySetInnerHTML={{ __html: fund.section1Content }}
                  sx={{ whiteSpace: "pre-line" }}
                />
              </Grid>
            )}
            {fund.section2Title && fund.section2Content && (
              <Grid item xs={12} md={6}>
                <Typography
                  component="span"
                  fontWeight="500"
                  pb={0.5}
                  sx={{
                    borderBottom: "2px solid",
                    borderBottomColor: "tertiary.main",
                  }}
                >
                  {fund.section2Title}
                </Typography>
                <StyledTableContainer
                  mt={2}
                  dangerouslySetInnerHTML={{ __html: fund.section2Content }}
                  sx={{ whiteSpace: "pre-line" }}
                />
              </Grid>
            )}
            {fund.section3Title && fund.section3Content && (
              <Grid item xs={12} md={6}>
                <Typography
                  component="span"
                  fontWeight="500"
                  pb={0.5}
                  sx={{
                    borderBottom: "2px solid",
                    borderBottomColor: "tertiary.main",
                  }}
                >
                  {fund.section3Title}
                </Typography>
                <StyledTableContainer
                  mt={2}
                  dangerouslySetInnerHTML={{ __html: fund.section3Content }}
                  sx={{ whiteSpace: "pre-line" }}
                />
              </Grid>
            )}
            {fund.section4Title && fund.section4Content && (
              <Grid item xs={12} md={6}>
                <Typography
                  component="span"
                  fontWeight="500"
                  pb={0.5}
                  sx={{
                    borderBottom: "2px solid",
                    borderBottomColor: "tertiary.main",
                  }}
                >
                  {fund.section4Title}
                </Typography>
                <StyledTableContainer
                  mt={2}
                  dangerouslySetInnerHTML={{ __html: fund.section4Content }}
                  sx={{ whiteSpace: "pre-line" }}
                />
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
    </Box>
  )
}

export default FundPage
