import React from "react"

import useMediaQuery from "@mui/material/useMediaQuery"
import { styled } from '@mui/material/styles'
import { Box, Divider, Drawer } from "@mui/material"

import NavDrawerLogo from "./NavDrawerLogo.component"
import NavDrawerAdmin from "./NavDrawerAdmin.component"
import NavDrawerUser from "./NavDrawerUser.component"

export const drawerWidth = 240

export const StyledDrawer = styled(Drawer)(({ theme }) => ({
  flexShrink: 0,
  width: drawerWidth,
  '& .MuiDrawer-paper': {
    backgroundColor: theme.palette.grey[900],
    color: 'white',
    width: drawerWidth,
    boxSizing: 'border-box',
  },
  '& svg': {
    color: 'white'
  },
}))

const NavigationDrawer = ({ handleDrawerToggle, mobileOpen, window, adminRoute }) => {
  const isLarge = useMediaQuery("(min-width: 1200px)")
  const container = window !== undefined ? () => window().document.body : undefined
  
  const drawer = (
    <div>
      <NavDrawerLogo />
      <Divider />
      {adminRoute ? (
        <NavDrawerAdmin handleDrawerToggle={handleDrawerToggle} />
      ) : (
        <NavDrawerUser handleDrawerToggle={handleDrawerToggle} />
      )}
    </div>
  );

  return (
    <Box
      component="nav"
      sx={{ width: { lg: drawerWidth }, flexShrink: { lg: 0 } }}
      aria-label="mailbox folders"
    >
      <StyledDrawer
        container={container}
        onClose={handleDrawerToggle}
        open={isLarge ? true : mobileOpen}
        ModalProps={{
          keepMounted: true,
        }}
        variant={isLarge ? "permanent" : "temporary"}
        sx={{
          display: { ...(isLarge ? { xs: 'none', lg: 'block' } : { xs: 'block', lg: 'none' }) },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
      >
        {drawer}
      </StyledDrawer>
    </Box>
  )
}

export default NavigationDrawer
