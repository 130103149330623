import React from "react"

import { Box, Button, Typography } from "@mui/material"

const DesktopStepActions = ({ steps, activeStep, handleNext, handleBack, error }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
      <Button
        color="inherit"
        disabled={activeStep === 0}
        onClick={handleBack}
        sx={{ mr: 1 }}
      >
        Back
      </Button>
      <Box sx={{
        alignItems: 'center',
        display: 'flex',
        flex: '1 1 auto',
        justifyContent: 'flex-end',
        paddingRight: '1rem', 
      }}>
        {error && <Typography color="error" fontSize=".875rem">{error}</Typography>}
      </Box>
      <Button onClick={handleNext} variant={activeStep === steps.length - 1 ? "contained" : "text"}>
        {activeStep === steps.length - 1 ? "Finish & Submit" : "Next"}
      </Button>
    </Box>
  )
}
 
export default DesktopStepActions
