import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { DateTime } from "luxon"
import { nanoid } from "nanoid"
import axios from "axios"

import { styled } from "@mui/material/styles"
import { Box, Drawer, IconButton } from "@mui/material"
import { ChevronRight as ChevronRightIcon } from "@mui/icons-material"

import { selectUser } from "../../app/store/authSlice"
import { addReply, selectCommunicationById } from "../../app/store/communicationsSlice"
import { setAlert } from "../../app/store/alertsSlice"

import Communication from "./Communication.component"
import CommunicationReplies from "./CommunicationReplies.component"

const StyledDrawer = styled(Drawer)(() => ({
  '& .MuiBackdrop-root': {
    backgroundColor: 'rgba(0, 0, 0, 0.12) !important',
  },
}))

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  flexGrow: 1,
  maxWidth: '900px',
  width: '90vw',
  overflowY: 'auto',
  [theme.breakpoints.up('sm')]: {
    padding: '.5rem',
  }
}))

const CommunicationsDrawer = ({ drawerOpen, handleDrawerClose, communicationId }) => {
  const dispatch = useDispatch()
  const communication = useSelector(selectCommunicationById(communicationId))
  const sender = useSelector(selectUser)

  const sendReply = (body) => {
    const replyId = nanoid()

    const replyObj = {
      body,
      created: DateTime.now().setZone('America/New_York').toISO(),
      sender: sender.uid
    }

    // Add user to rtdb
    axios.post("/api/communications/reply", {
      communicationId: communication.id,
      replyId,
      replyObj
    }).then(() => {     
      // Add new document to store
      dispatch(addReply({
        communicationId: communication.id,
        replyId,
        ...replyObj
      }))

      // Mark communication unread for user or admin when reply is added
      if (sender.role === 'admin') {
        const recipients = Object.keys(communication.recipients)
        if (recipients[0] === 'admins') {
          axios.post("/api/communications/mark-unread-user", {
            userId: communication.sender,
            communicationId: communication.id
          }).catch(error => {
            console.log("error marking communication as unread")
          })
        } else {
          for (let i = 0; i < recipients.length; i++) {
            axios.post("/api/communications/mark-unread-user", {
              userId: recipients[i],
              communicationId: communication.id
            }).catch(error => {
              console.log("error marking communication as unread")
            })
          }
        }
      } else if (sender.role === 'user') {
        axios.post("/api/communications/mark-unread-admin", {
          communicationId: communication.id
        }).catch(error => {
          console.log("error marking communication as unread")
        })
      }

      dispatch(setAlert({ message: "Reply sent", severity: "success" }))
    }).catch(error => {
      console.log("error sending communication: ", error.message)
      dispatch(setAlert({ message: "Error sending reply", severity: "error" }))
    })

  }

  return (
    <StyledDrawer
      anchor={"right"}
      elevation={2}
      open={drawerOpen}
      onClose={handleDrawerClose}
    >
      <StyledBox
        role="presentation"
      >
        <Box mb={2}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronRightIcon />
          </IconButton>
        </Box>
        {communication && (
          <Box p={2}>
            <Communication communication={communication} />
            <CommunicationReplies replies={communication.replies} sendReply={sendReply} />
          </Box>
        )}
      </StyledBox>
    </StyledDrawer>
  )
}

export default CommunicationsDrawer
