import React, { useState, useEffect } from "react"

import { Box, Button, Modal } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"

const PrivacyPolicyModal = ({ modalOpen, handleCloseModal }) => {
  const [privacyPolicy, setPrivacyPolicy] = useState(null)

  useEffect(() => {
    fetch("https://www.iubenda.com/api/privacy-policy/60370380")
      .then((response) => response.json())
      .then((data) => setPrivacyPolicy(data))
  }, [])

  const style = {
    bgcolor: "background.paper",
    boxShadow: 24,
    height: "80vh",
    left: "50%",
    maxWidth: "1200px",
    overflowY: "auto",
    padding: ".5rem 2rem 2rem",
    position: "absolute",
    top: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
  }

  return (
    <Modal
      open={modalOpen}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}>
          <Button onClick={handleCloseModal}>
            <CloseIcon sx={{ fontSize: 18, mr: 1 }} />
            Close
          </Button>
        </Box>
        <div className="privacy-policy">
          {privacyPolicy && (
            <div
              className="privacy-policy"
              dangerouslySetInnerHTML={{ __html: privacyPolicy.content }}
            ></div>
          )}
        </div>
      </Box>
    </Modal>
  )
}

export default PrivacyPolicyModal
