import React, { useState } from "react"
import { useNavigate } from "react-router-dom"

import { styled } from "@mui/material/styles"
import { Box, Checkbox, Grid, TablePagination } from "@mui/material"

import GroupsTableHead from "./GroupsTableHead.component"

const StyledContainer = styled(Box)(({ theme }) => ({
  fontSize: ".875rem",
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    fontSize: ".875rem",
  },
  "& .MuiCheckbox-root": {
    padding: "4px",
    [theme.breakpoints.up("sm")]: {
      padding: "9px",
    },
  },
}))

const StyledHead = styled(Grid)(({ theme }) => ({
  borderBottom: "1px solid " + theme.palette.grey[300],
}))

const StyledRow = styled(Grid)(({ theme }) => ({
  borderBottom: "1px solid " + theme.palette.grey[300],
  cursor: "pointer",
  overflow: "hidden",
  "&:hover": {
    backgroundColor: theme.palette.grey[100],
  },
}))

const StyledCell = styled(Grid)(({ theme }) => ({
  alignItems: "center",
  display: "flex",
  padding: ".5rem",
  [theme.breakpoints.up("sm")]: {
    padding: "0.25rem",
  },
  width: "200px", // prevents cell from expanding to fit text
}))

const StyledSpanTruncated = styled("span")(() => ({
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
}))

const StyledTablePagination = styled(TablePagination)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    marginTop: ".5rem",
    "& .MuiToolbar-root": {
      justifyContent: "space-between",
      "& .MuiTablePagination-spacer": {
        display: "none",
      },
      "& .MuiTablePagination-selectLabel": {
        display: "none",
      },
      "& .MuiInputBase-root": {
        display: "none",
      },
      "& .MuiTablePagination-actions": {
        margin: 0,
      },
    },
  },
}))

const GroupsTable = ({ groups, selected, setSelected }) => {
  const navigate = useNavigate()

  const [order, setOrder] = useState("asc")
  const [orderBy, setOrderBy] = useState("lname")
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)

  const handleRequestSort = (e, property) => {
    const isAsc = orderBy === property && order === "asc"
    setOrder(isAsc ? "desc" : "asc")
    setOrderBy(property)
  }

  const handleSelectAllClick = (e) => {
    if (e.target.checked) {
      const newSelecteds = groups.map((n) => n.id)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  const handleClick = (e, name) => {
    const selectedIndex = selected.indexOf(name)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }

    setSelected(newSelected)
  }

  const handleChangePage = (e, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10))
    setPage(0)
  }

  const isSelected = (id) => selected.indexOf(id) !== -1

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - groups.length) : 0

  return (
    <StyledContainer>
      <StyledHead container>
        <GroupsTableHead
          numSelected={selected.length}
          order={order}
          orderBy={orderBy}
          onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          rowCount={groups.length}
        />
      </StyledHead>
      {groups
        .slice()
        .sort((a, b) => {
          if (orderBy === "name" && order === "asc") {
            return a.name > b.name ? 1 : -1
          } else if (orderBy === "name" && order === "desc") {
            return a.name < b.name ? 1 : -1
          } else {
            return 1
          }
        })
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((group, index) => {
          const isItemSelected = isSelected(group.id)
          const labelId = `checkbox-${index}`

          return (
            <StyledRow
              container
              aria-checked={isItemSelected}
              key={group.id}
              onClick={() => navigate(`/admin/groups/${group.id}`)}
              role="checkbox"
              selected={isItemSelected}
              tabIndex={-1}
            >
              <StyledCell item id={group.id} xs={12}>
                <Checkbox
                  color="primary"
                  checked={isItemSelected}
                  id={labelId}
                  inputProps={{ "aria-labelledby": labelId }}
                  onClick={(e) => {
                    e.stopPropagation()
                    handleClick(e, group.id)
                  }}
                  padding="checkbox"
                />
                <StyledSpanTruncated>{group.name}</StyledSpanTruncated>
              </StyledCell>
            </StyledRow>
          )
        })}
      {emptyRows > 0 && (
        <Grid item xs={12} style={{ height: 53 * emptyRows }}>
          <></>
        </Grid>
      )}
      <StyledTablePagination
        rowsPerPageOptions={[25, 50, 75]}
        component="div"
        count={groups.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </StyledContainer>
  )
}

export default GroupsTable
