import React from "react"

import { styled } from '@mui/material/styles'
import {
  Card,
  Link,
  Stack,
  Typography
} from "@mui/material"

import logo from "../../assets/images/chenel-capital-logo-wide.png"
const StyledLogo = styled('img')(() => ({
  marginBottom: '2rem',
  maxWidth: '180px',
  width: '100%'
}))

const NoAccessPage = () => {
  return (
    <Card sx={{ maxWidth: '500px', padding: 4, width: '90%',}}>
      <Stack spacing={2}>
        <StyledLogo src={logo} alt="Chenel Capital" />
        <Typography component="h1" variant="h5" sx={{ maxWidth: '400px', pb: 4 }}>
          Investor Portal Access
        </Typography>
        <Stack spacing={3} alignItems="center">
          <Typography>
            This account does not currently have access to the Chenel Capital Investor Portal.
          </Typography>
          <Typography>
            Please contact us at{" "}
            <Link href="mailto:admin@chenelcapital.com?subject=Investor Portal Access Inquiry">admin@chenelcapital.com</Link>
            {" "}if you have any questions.
          </Typography>
          <Typography alignSelf="flex-start">
            <Link href="https://chenelcapital.com" underline="none">
              Head to ChenelCapital.com
            </Link>
          </Typography>
        </Stack>
      </Stack>
    </Card>
  )
}

export default NoAccessPage
