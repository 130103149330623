import React, { useState } from "react"

import { Autocomplete, TextField } from "@mui/material"

const AssignUsersUserSearch = ({ unassignedUsers, assignUser }) => {
  const [selectValue, setSelectValue] = useState("")
  const [selectInputValue, setSelectInputValue] = useState("")

  const handleAssignUser = (e, value) => {
    if (value) {
      assignUser(value)
      setSelectValue("")
      setSelectInputValue("")
    }
  }

  return (
    <Autocomplete
      disableClearable
      disablePortal
      getOptionLabel={(option) =>
        option ? `${option.lname}, ${option.fname} (${option.username})` : ""
      }
      id="select-users"
      options={unassignedUsers}
      renderInput={(params) => (
        <TextField {...params} label="Search by name or email" />
      )}
      size="small"
      sx={{ flexGrow: 1 }}
      value={selectValue}
      onChange={handleAssignUser}
      inputValue={selectInputValue}
      onInputChange={(e, newInputValue) => setSelectInputValue(newInputValue)}
    />
  )
}

export default AssignUsersUserSearch
