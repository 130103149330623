import React, { useState } from "react"

import { Box, CssBaseline } from '@mui/material'

import AppHeader from "./AppHeader.component"
import NavDrawer from "./NavDrawer.component"
import MainContent from "./MainContent.component"
import AlertStack from "./AlertStack.component"

const PageLayout = ({ adminRoute }) => {
  const [mobileOpen, setMobileOpen] = useState(false)
  const handleDrawerToggle = () => setMobileOpen(!mobileOpen)

  return (
    <Box sx={{ display: 'flex', minHeight: '100vh', minWidth: '320px' }}>
      <CssBaseline />
      <AppHeader
        adminRoute={adminRoute}
        handleDrawerToggle={handleDrawerToggle}
      />
      <NavDrawer
        adminRoute={adminRoute}
        handleDrawerToggle={handleDrawerToggle}
        mobileOpen={mobileOpen}
      />
      <MainContent />
      <AlertStack />
    </Box>
  )
}

export default PageLayout
