import { getDatabase, ref, get } from "firebase/database"

const database = getDatabase()

export const fetchSystemInfo = () => {
  return new Promise(resolve => {
    get(ref(database, "system")).then((snapshot) => {
      resolve(snapshot.val())
    }).catch(error => {
      console.error(error)
      resolve({})
    })
  })
}
