import React from "react"
import { NavLink } from "react-router-dom"

import useMediaQuery from "@mui/material/useMediaQuery"
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Link,
  Typography,
} from "@mui/material"
import {
  Email as EmailIcon,
  Map as MapIcon,
  Phone as PhoneIcon,
} from "@mui/icons-material"

import PageTitle from "../../../common/sharedComponents/PageTitle.component"

import investorImg800 from "../../../assets/images/investor-relations-800.jpg"
import investorImg2400 from "../../../assets/images/investor-relations-2400.jpg"
import InvestmentTeam from "./InvestmentTeam.component"

const InvestorRelationsPage = () => {
  const isLarge = useMediaQuery("(min-width: 1200px)")

  return (
    <Box>
      <PageTitle title="Investor Relations" />
      <Grid container spacing={4}>
        <Grid item xs={12} md={7}>
          <Card>
            <CardMedia
              alt=""
              component="img"
              height={isLarge ? 250 : 150}
              image={isLarge ? investorImg2400 : investorImg800}
              pb={4}
            />
            <CardContent>
              <Box>
                <Typography component="h3" pb={2} variant="h5">
                  Why Invest with Chenel Capital?
                </Typography>
                <Typography sx={{ fontSize: { xs: ".875rem", md: "1rem" }, pb: 2}}>
                  Chenel Capital's diverse composition allows us to apply a
                  multicultural and multidisciplinary lens to our investment
                  vehicles, as well as, overall investment strategy. We pride
                  ourselves on being creative with how we underwrite our
                  investments and develop our investment pipeline.
                </Typography>
                <Typography sx={{ fontSize: { xs: ".875rem", md: "1rem" }, pb: 2}}>
                  Where others see gaps, we see bridges. If there isn't a
                  bridge, we make one. We “build our bridge” through a variety
                  of bespoke investment vehicles such as co-investments, special
                  purpose vehicles (SPVs), and collateralized debt offerings
                  (CDOs) uniquely developed by Chenel Capital.
                </Typography>
              </Box>
              <Box mt={2}>
                <Typography component="h3" pb={2} variant="h5">
                  Contact Chenel Capital
                </Typography>
                <Typography sx={{ display: "flex", fontSize: { xs: ".875rem", md: "1rem" }, pb: 2}}>
                  <EmailIcon sx={{ marginRight: 1 }} />
                  <NavLink component={Link} to="/communications">
                    Send us a message
                  </NavLink>
                </Typography>
                <Typography sx={{ display: "flex", fontSize: { xs: ".875rem", md: "1rem" }, pb: 2}}>
                  <PhoneIcon sx={{ marginRight: 1 }} />
                  +1 212 220 7992
                </Typography>
                <Typography sx={{ display: "flex", fontSize: { xs: ".875rem", md: "1rem" }, pb: 2}}>
                  <MapIcon sx={{ marginRight: 1 }} />
                  1 World Trade Center, Suite 8500, New York, NY 10007
                </Typography>
              </Box>
              <Box mt={2}>
                <Typography component="h3" pb={2} variant="h5">
                  News and Results
                </Typography>
                <Typography sx={{ fontSize: { xs: ".875rem", md: "1rem" }}}>
                  <a
                    href="https://www.chenelcapital.com/press/kalgene-pharmaceuticals-inc-investment"
                    target="_blank"
                    rel="noreferrer"
                  >
                    KalGene Pharmaceuticals, Inc. Investment
                  </a>
                </Typography>
                <Typography sx={{ fontSize: { xs: ".875rem", md: "1rem" }, pb: 2}}>
                  Chenel Capital is thrilled to announce the successful closing
                  of a Convertible Note Financing for KalGene Pharmaceuticals,
                  Inc...
                </Typography>
                <Typography sx={{ fontSize: { xs: ".875rem", md: "1rem" }}}>
                  <a
                    href="https://www.chenelcapital.com/press/leading-our-seed-nvestment-in-the-future-of-space"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Leading Our Seed Investment in the Future of Space
                  </a>
                </Typography>
                <Typography sx={{ fontSize: { xs: ".875rem", md: "1rem" }, pb: 2}}>
                  In 2020, humans launched more satellites in one year than has
                  ever been launched before. Today, there are over four hundred
                  satellite...
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={5}>
          <InvestmentTeam />
        </Grid>
      </Grid>
    </Box>
  )
}

export default InvestorRelationsPage
