import React, { useState, useEffect } from "react"

import {
  Box,
  Grid,
  IconButton,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography
} from "@mui/material"
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon
} from "@mui/icons-material"
import PasswordStrengthMeter from "../../common/sharedComponents/PasswordStrengthMeter.component"

const RegistrationStepAccount = ({
  formValues,
  passwordValues,
  setPasswordValues,
  validateStep,
  setValidateStep,
  handleSubmit
}) => {
  const [showPassword, setShowPassword] = useState(false)
  const [passwordError, setPasswordError] = useState("")
  const [confirmPasswordError, setConfirmPasswordError] = useState("")

  const isPasswordLength = () => passwordValues.password.length >= 8
  const doPasswordsMatch = () => passwordValues.password === passwordValues.confirmPassword

  const validatePasswords = () => {
    if (passwordValues.password && !isPasswordLength()) setPasswordError("Password must be at least 8 characters")
    else if (passwordValues.confirmPassword && !doPasswordsMatch()) setConfirmPasswordError("Passwords do not match")
  }

  const handlePasswordChange = prop => e => {
    setPasswordError("")
    setConfirmPasswordError("")
    setPasswordValues({ ...passwordValues, [prop]: e.target.value })
  }

  // Validate all fields in this registration step when "Next" is clicked
  useEffect(() => {
    if (validateStep === 3) {
      setValidateStep(null)
      if (isPasswordLength() && doPasswordsMatch()) {
        handleSubmit()
      } else {
        validatePasswords()
      }
    }
  // only want to re-render when validate step changes, ignore other data
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validateStep])

  return (
    <Box py={4}>
      <Typography component={"h2"} variant={"h5"} pb={2}>Account Credentials</Typography>
      <form>
        <Grid container spacing={4} py={2}>
          <Grid item xs={12}>
            <TextField
              disabled
              error={!formValues.username}
              fullWidth
              helperText={!formValues.username && "Please enter a valid email in the previous step"}
              label="Username"
              name="username"
              type="text"
              value={formValues.username || "user@emailaddress.com"}
            />
          </Grid>
          <Grid item xs={12} md={6} display="flex" flexDirection="column">
            <FormControl
              error={!!passwordError}
              variant="outlined"
              sx={{ width: '100%' }}
            >
              <InputLabel htmlFor="outlined-adornment-password">Password (min. 8 characters)</InputLabel>
              <OutlinedInput
                fullWidth
                id="outlined-adornment-password"
                label="Password (min. 8 characters)"
                name="password"
                onBlur={validatePasswords}
                onChange={handlePasswordChange("password")}
                type={showPassword ? "text" : "password"}
                value={passwordValues.password}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      onMouseDown={e => e.preventDefault()}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <FormHelperText>{passwordError}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl
                error={!!confirmPasswordError}
              variant="outlined"
              sx={{ width: '100%' }}
            >
              <InputLabel htmlFor="outlined-adornment-confirm-password">Confirm Password</InputLabel>
              <OutlinedInput
                fullWidth
                id="outlined-adornment-password"
                label="Confirm Password"
                name="confirmPassword"
                onBlur={validatePasswords}
                onChange={handlePasswordChange("confirmPassword")}
                type={showPassword ? "text" : "password"}
                value={passwordValues.confirmPassword}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      onMouseDown={e => e.preventDefault()}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <FormHelperText>{confirmPasswordError}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <PasswordStrengthMeter password={passwordValues.password} />
          </Grid>
        </Grid>
      </form>
    </Box>
  )
}
 
export default RegistrationStepAccount
