import React, { useState } from "react"
import { useSelector } from "react-redux"
import MuiPhoneNumber from "material-ui-phone-number"

import useMediaQuery from "@mui/material/useMediaQuery"
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormControl,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from "@mui/material"

import { selectSuperAdmin } from "../../../app/store/authSlice"

const UserForm = ({ user = {}, handleSaveUser }) => {
  const isLarge = useMediaQuery("(min-width: 1200px)")
  const isSuperAdmin = useSelector(selectSuperAdmin)
  const disableForm = user.role === "admin" && !isSuperAdmin ? true : false

  const initialFormValues = {
    city: user.address ? user.address.city || "" : "",
    company: user.company || "",
    country: user.address ? user.address.country || "" : "",
    fname: user.fname || "",
    lname: user.lname || "",
    phone: {
      formatted: user.phone
        ? user.phone.dialCode && user.phone.subscriber
          ? `+${user.phone.dialCode} ${user.phone.subscriber}`
          : "+1"
        : "+1",
      dialCode: user.phone ? user.phone.dialCode || "" : "",
      subscriber: user.phone ? user.phone.subscriber || "" : "",
    },
    role: user.role || "user",
    superAdmin: user.superAdmin || false,
    state: user.address ? user.address.state || "" : "",
    street: user.address ? user.address.street || "" : "",
    username: user.username || "",
    zip: user.address ? user.address.zip || "" : "",
  }
  const initialErrorValues = {
    company: "",
    fname: "",
    lname: "",
    phone: "",
    username: "",
  }
  const [formValues, setFormValues] = useState(initialFormValues)
  const [errorValues, setErrorValues] = useState(initialErrorValues)
  const [sendEmail, setSendEmail] = useState(false)

  const handleFormChange = (property, value) => {
    setFormValues({ ...formValues, [property]: value })
  }

  const handleErrorChange = (property, value) => {
    setErrorValues({ ...errorValues, [property]: value })
  }

  const onRoleChange = (e) => {
    const role = e.target.value
    if (role === "super") {
      setFormValues({ ...formValues, role: "admin", superAdmin: true })
      return
    }
    setFormValues({ ...formValues, role, superAdmin: false })
  }

  const handlePhoneChange = (value, country) => {
    handleErrorChange("phone", "")

    const regex = new RegExp("(^\\+" + country.dialCode + ")")
    const subscriber = value.replace(/[^0-9+]/gi, "").replace(regex, "")

    handleFormChange("phone", {
      formatted: value,
      dialCode: country.dialCode,
      subscriber,
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    let error = false

    if (formValues.fname === "") {
      handleErrorChange("fname", "First Name is required")
      error = true
    }
    if (formValues.lname === "") {
      handleErrorChange("lname", "Last Name is required")
      error = true
    }
    if (formValues.username === "") {
      handleErrorChange("username", "Email Address is required")
      error = true
    }
    if (formValues.company === "") {
      handleErrorChange("company", "Company is required")
      error = true
    }

    if (!error) {
      handleSaveUser({
        id: user.id,
        ...formValues,
        sendEmail,
      })
    }
  }

  return (
    <Box sx={{ py: 3 }}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              disabled={disableForm}
              error={!!errorValues.fname}
              fullWidth
              helperText={errorValues.fname}
              label="First Name"
              name="fname"
              onChange={(e) => {
                handleErrorChange("fname", null)
                handleFormChange("fname", e.target.value)
              }}
              required
              size={isLarge ? "medium" : "small"}
              value={formValues.fname}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              disabled={disableForm}
              error={!!errorValues.lname}
              fullWidth
              helperText={errorValues.lname}
              label="Last Name"
              name="lname"
              onChange={(e) => {
                handleErrorChange("lname", null)
                handleFormChange("lname", e.target.value)
              }}
              required
              size={isLarge ? "medium" : "small"}
              value={formValues.lname}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled={disableForm}
              error={!!errorValues.username}
              fullWidth
              helperText={errorValues.username}
              label="Email"
              name="username"
              onChange={(e) => {
                handleErrorChange("username", null)
                handleFormChange("username", e.target.value)
              }}
              required
              size={isLarge ? "medium" : "small"}
              value={formValues.username}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              disabled={disableForm}
              error={!!errorValues.company}
              fullWidth
              helperText={errorValues.company}
              label="Company"
              name="company"
              onChange={(e) => {
                handleErrorChange("company", null)
                handleFormChange("company", e.target.value)
              }}
              required
              size={isLarge ? "medium" : "small"}
              value={formValues.company}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <MuiPhoneNumber
              disabled={disableForm}
              defaultCountry={"us"}
              disableAreaCodes={true}
              error={!!errorValues.phone}
              fullWidth
              helperText={errorValues.phone}
              label="Phone"
              onChange={handlePhoneChange}
              preferredCountries={["us", "uk", "cn", "de", "fr", "ca"]}
              size={isLarge ? "medium" : "small"}
              value={formValues.phone.formatted}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled={disableForm}
              error={!!errorValues.street}
              fullWidth
              helperText={errorValues.street}
              label="Address"
              name="street"
              onChange={(e) => {
                handleErrorChange("street", null)
                handleFormChange("street", e.target.value)
              }}
              size={isLarge ? "medium" : "small"}
              value={formValues.street}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              disabled={disableForm}
              error={!!errorValues.city}
              fullWidth
              helperText={errorValues.city}
              label="City"
              name="city"
              onChange={(e) => {
                handleErrorChange("city", null)
                handleFormChange("city", e.target.value)
              }}
              size={isLarge ? "medium" : "small"}
              value={formValues.city}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              disabled={disableForm}
              error={!!errorValues.state}
              fullWidth
              helperText={errorValues.state}
              label="State"
              name="state"
              onChange={(e) => {
                handleErrorChange("state", null)
                handleFormChange("state", e.target.value)
              }}
              size={isLarge ? "medium" : "small"}
              value={formValues.state}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              disabled={disableForm}
              error={!!errorValues.zip}
              fullWidth
              helperText={errorValues.zip}
              label="Zip"
              name="zip"
              onChange={(e) => {
                handleErrorChange("zip", null)
                handleFormChange("zip", e.target.value)
              }}
              size={isLarge ? "medium" : "small"}
              value={formValues.zip}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              disabled={disableForm}
              error={!!errorValues.country}
              fullWidth
              helperText={errorValues.country}
              label="Country"
              name="country"
              onChange={(e) => {
                handleErrorChange("country", null)
                handleFormChange("country", e.target.value)
              }}
              size={isLarge ? "medium" : "small"}
              value={formValues.country}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl component="fieldset" disabled={disableForm}>
              <FormLabel component="legend">Role</FormLabel>
              <RadioGroup
                aria-label="role"
                name="row-radio-buttons-group"
                onChange={onRoleChange}
                required
                row
                value={formValues.superAdmin ? "super" : formValues.role}
              >
                <FormControlLabel
                  value="user"
                  control={<Radio />}
                  label="User"
                />
                <FormControlLabel
                  value="admin"
                  control={<Radio />}
                  label="Admin"
                />
                <FormControlLabel
                  disabled={!isSuperAdmin}
                  value="super"
                  control={<Radio />}
                  label="Super Admin"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          {!user.id && (
            <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox checked={sendEmail} />}
                label="Send Email to User"
                onChange={() => setSendEmail(!sendEmail)}
              />
            </Grid>
          )}
          <Grid item xs={12} sx={{ mt: ".75rem" }}>
            <Stack spacing={2} direction="row">
              <Button
                disabled={disableForm}
                size={isLarge ? "medium" : "small"}
                type="submit"
                variant="contained"
              >
                {user.id ? "Save User" : "Add User"}
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </Box>
  )
}

export default UserForm
