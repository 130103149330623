import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { fetchAllUsers } from "./usersFetch"

const initialState = {
  status: "idle",
  list: [],
}

export const getAllUsersAsync = createAsyncThunk(
  "users/fetchAllUsers",
  async () => {
    const response = await fetchAllUsers()
    return response
  }
)

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setUsers: (state, action) => ({
      ...state,
      list: action.payload,
    }),
    addUser: (state, action) => ({
      ...state,
      list: [...state.list, action.payload],
    }),
    updateUser: (state, action) => ({
      ...state,
      list: state.list.map((user) => {
        if (user.id === action.payload.id) {
          return {
            ...user,
            ...action.payload,
          }
        } else {
          return user
        }
      }),
    }),
    removeUser: (state, action) => ({
      ...state,
      list: [...state.list.filter((user) => user.id !== action.payload)],
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllUsersAsync.pending, (state) => {
        state.status = "loading"
      })
      .addCase(getAllUsersAsync.fulfilled, (state, action) => ({
        status: "idle",
        list: action.payload,
      }))
  },
})

export const { setUsers, addUser, updateUser, removeUser } = usersSlice.actions

export const selectUsersList = (state) => state.users.list
export const selectUsersUserList = (state) =>
  state.users.list.filter((user) => user.role === "user")
export const selectUsersAdminList = (state) =>
  state.users.list.filter((user) => user.role === "admin")
export const selectUserById = (id) => (state) =>
  state.users.list.filter((user) => user.id === id)[0]

export default usersSlice.reducer
