import React from "react"

import {
  Box,
  Button,
  IconButton,
  Modal,
  Stack,
  Typography
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"

const style = {
  bgcolor: 'background.paper',
  border: '1px solid #666',
  borderRadius: '5px',
  boxShadow: 24,
  left: '50%',
  maxHeight: '100vh',
  maxWidth: '500px',
  overflow: 'auto',
  px: 3,
  py: 2,
  position: 'absolute',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%'
}

const ConfirmModal = ({ modalOpen, handleCloseModal, message, handleResponse }) => {
  return (
    <Modal
      open={modalOpen}
      onClose={handleCloseModal}
      aria-labelledby="modal-title"
    >
      <Box sx={style}>
        <Box alignItems="center" display="flex" justifyContent="space-between">
          <Typography component="h2" variant="h6" id="modal-title">
            Confirm Action
          </Typography>
          <IconButton onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box sx={{ pt: 3 }}>
          <Typography paddingBottom={2}>
            {message}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="flex-end" paddingTop={4}>
          <Stack spacing={2} direction="row">
            <Button
              color="primary"
              onClick={() => {
                handleResponse(true)
                handleCloseModal()
              }}
              type="submit"
              variant="contained"
            >
              Confirm
            </Button>
            <Button
              onClick={() => {
                handleResponse(false)
                handleCloseModal()
              }}
              variant="outlined"
            >
              Cancel
            </Button>
          </Stack>
        </Box>
      </Box>
    </Modal>
  )
}
 
export default ConfirmModal
