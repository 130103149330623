import React, { useState } from "react"
import PropTypes from "prop-types"

import { alpha } from "@mui/material/styles"
import {
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material"
import DeleteIcon from "@mui/icons-material/Delete"

import AddFundModal from "./AddFundModal.component"
import ConfirmDeleteModal from "./ConfirmDeleteModal.component"

const FundsTableToolbar = ({
  funds,
  selected,
  handleDeleteFunds,
  search,
  setSearch,
}) => {
  const numSelected = selected.length
  const [addFundModalOpen, setAddFundModalOpen] = useState(false)
  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false)

  return (
    <Box
      p={2}
      sx={{
        height: { xs: "110px", sm: "65px" },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Grid container spacing={2}>
          <Grid item xs={8} style={{ display: "flex", alignItems: "center" }}>
            <Typography
              sx={{ flex: "1 1 100%" }}
              color="inherit"
              variant="subtitle1"
              component="div"
            >
              {numSelected} selected
            </Typography>
          </Grid>
          <Grid
            item
            xs={4}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Tooltip title="Delete">
              <IconButton
                onClick={(e) => {
                  e.currentTarget.blur()
                  setConfirmDeleteModalOpen(true)
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
            <ConfirmDeleteModal
              modalOpen={confirmDeleteModalOpen}
              handleCloseModal={() => setConfirmDeleteModalOpen(false)}
              handleDeleteFunds={() => {
                setConfirmDeleteModalOpen(false)
                handleDeleteFunds(selected)
              }}
              funds={funds}
              selected={selected}
            />
          </Grid>
        </Grid>
      ) : (
        <>
          <Stack
            spacing={2}
            direction={{ xs: "column-reverse", sm: "row" }}
            sx={{ width: "100%" }}
          >
            <TextField
              fullWidth
              id="search-funds"
              label="Search Funds"
              onChange={(e) => setSearch(e.target.value)}
              size="small"
              value={search}
            />
            <Button
              onClick={(e) => {
                e.currentTarget.blur()
                setAddFundModalOpen(true)
              }}
              sx={{ whiteSpace: "nowrap" }}
              variant="contained"
            >
              Add Fund
            </Button>
          </Stack>
          <AddFundModal
            modalOpen={addFundModalOpen}
            handleCloseModal={() => setAddFundModalOpen(false)}
          />
        </>
      )}
    </Box>
  )
}

FundsTableToolbar.propTypes = {
  selected: PropTypes.array.isRequired,
}

export default FundsTableToolbar
