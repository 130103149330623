import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { nanoid } from "nanoid"
import axios from "axios"

import {
  Box,
  IconButton,
  LinearProgress,
  Modal,
  Typography,
} from "@mui/material"
import { Close as CloseIcon } from "@mui/icons-material"

import { setAlert } from "../../../app/store/alertsSlice"
import { addGroup } from "../../../app/store/groupsSlice"

import GroupForm from "./GroupForm.component"

const outerStyle = {
  bgcolor: "background.paper",
  border: "1px solid #666",
  borderRadius: "5px",
  boxShadow: 24,
  left: "50%",
  maxHeight: "90vh",
  maxWidth: "800px",
  overflow: "auto",
  position: "absolute",
  top: "50%",
  transform: "translate(-50%, -50%)",
  width: "98%",
}

const innerStyle = {
  px: 3,
  py: 2,
}

const AddGroupModal = ({ modalOpen, handleCloseModal }) => {
  const dispatch = useDispatch()
  const [saving, setSaving] = useState(false)

  const handleSaveGroup = (formValues) => {
    setSaving(true)

    const groupId = nanoid()

    const dbObj = {
      name: formValues.name,
      members: formValues.members
    }

    axios
      .post("/api/groups/add", {
        groupId,
        dbObj,
      })
      .then((res) => {
        // Add new group to store
        dispatch(
          addGroup({
            ...dbObj,
            id: groupId,
          })
        )

        handleCloseModal()
        dispatch(
          setAlert({
            message: `Group ${formValues.name} has been added`,
            severity: "success",
          })
        )
        setSaving(false)
      })
      .catch((error) => {
        console.log("error creating group in database: ", error.message)
        dispatch(
          setAlert({
            message: `Error creating group: ${error.message}`,
            severity: "error",
          })
        )
        setSaving(false)
      })
  }

  return (
    <Modal
      open={modalOpen}
      onClose={handleCloseModal}
      aria-labelledby="modal-title"
    >
      <Box sx={outerStyle}>
        <Box sx={innerStyle}>
          <Box
            alignItems="center"
            display="flex"
            justifyContent="space-between"
          >
            <Typography component="h2" variant="h6" id="modal-title">
              Add a New Group
            </Typography>
            <IconButton onClick={handleCloseModal}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box sx={{ pt: 3 }}>
            <GroupForm
              handleCloseModal={handleCloseModal}
              handleSaveGroup={handleSaveGroup}
            />
          </Box>
        </Box>
        {saving && <LinearProgress />}
      </Box>
    </Modal>
  )
}

export default AddGroupModal
