import React, { useState } from 'react'
import { useDispatch, useSelector } from "react-redux"
import axios from "axios"

import { Box, Button, Modal, Stack, TextField, Typography } from "@mui/material"

import { selectUserId } from "../../app/store/authSlice"
import { setAlert } from "../../app/store/alertsSlice"

import PasswordStrengthMeter from "./PasswordStrengthMeter.component"

const style = {
  bgcolor: 'background.paper',
  boxShadow: 24,
  left: '50%',
  maxWidth: "600px",
  p: 4,
  position: 'absolute',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  width: "100%",
};

const ChangePasswordModal = ({ modalOpen, handleCloseModal }) => {
  const dispatch = useDispatch()
  const uid = useSelector(selectUserId)
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [confirmPasswordError, setConfirmPasswordError] = useState(null)

  const handlePasswordReset = () => {
    if (password !== confirmPassword) {
      setConfirmPasswordError("Passwords do not match")
    } else {
      axios.post("/api/firebase/auth/update", {
        uid,
        updates: {
          password
        }
      }).then(() => {
        handleCloseModal()
        setPassword("")
        setConfirmPassword("")
        dispatch(setAlert({ message: "Password has been updated", severity: "success" }))
      }).catch(() => {
        dispatch(setAlert({ message: "Error updating password", severity: "error" }))
      })
    }
  }

  return (
    <Modal
      open={modalOpen}
      onClose={handleCloseModal}
      aria-labelledby="password-modal-title"
    >
      <Box sx={style}>
        <Typography id="password-modal-title" variant="h6" component="h2">
          Change Password
        </Typography>
        <Stack spacing={2} mt={2}>
          <TextField
            fullWidth
            label="New Password"
            name="password"
            onChange={e => setPassword(e.target.value)}
            size="small"
            type="password"
            value={password}
          />
          <TextField
            error={!!confirmPasswordError}
            fullWidth
            helperText={confirmPasswordError}
            label="Confirm Password"
            name="confirmPassword"
            onChange={e => {
              setConfirmPasswordError(null)
              setConfirmPassword(e.target.value)
            }}
            size="small"
            type="password"
            value={confirmPassword}
          />
          <PasswordStrengthMeter password={password} />
          <Box pt={2}>
            <Button
              onClick={handlePasswordReset}
              variant="contained"
              sx={{ alignSelf: "flex-start" }}
            >
              Change Password
            </Button>
          </Box>
        </Stack>
      </Box>
    </Modal>
  )
}
 
export default ChangePasswordModal
