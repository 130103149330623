import React, { useState } from "react"
import { useDispatch } from 'react-redux'
import { DateTime } from "luxon"
import axios from "axios"

import {
  Box,
  IconButton,
  LinearProgress,
  Modal,
  Typography
} from "@mui/material"
import { Close as CloseIcon } from "@mui/icons-material"

import { setAlert } from "../../../app/store/alertsSlice"
import { addUser } from "../../../app/store/usersSlice"

import UserForm from "./UserForm.component"

const outerStyle = {
  bgcolor: 'background.paper',
  border: '1px solid #666',
  borderRadius: '5px',
  boxShadow: 24,
  left: '50%',
  maxHeight: '90vh',
  maxWidth: '800px',
  overflow: 'auto',
  position: 'absolute',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  width: '98%',
}

const innerStyle = {
  px: 3,
  py: 2
}

const AddUserModal = ({ modalOpen, handleCloseModal }) => {
  const dispatch = useDispatch()
  const [saving, setSaving] = useState(false)

  const handleSaveUser = (formValues) => {
    setSaving(true)

    const dbObj = {
      address: {
        city: formValues.city,
        country: formValues.country,
        street: formValues.street,
        state: formValues.state,
        zip: formValues.zip
      },
      company: formValues.company,
      created: DateTime.now().setZone('America/New_York').toISO(),
      fname: formValues.fname,
      lname: formValues.lname,
      phone: formValues.phone,
      role: formValues.role,
      superAdmin: formValues.superAdmin,
      username: formValues.username
    }

    const authObj = {
      email: formValues.username,
      fname: formValues.fname,
      lname: formValues.lname,
      sendEmail: formValues.sendEmail
    }

    axios.post("/api/firebase/auth/create", authObj).then(res => {
      const userId = res.data

      // Add user to rtdb
      axios.post("/api/users/add", {
        userId,
        dbObj
      }).then(res => {     
        // Add new user to store
        dispatch(addUser({
          ...dbObj,
          id: userId
        }))

        handleCloseModal()
        dispatch(setAlert({ message: `User ${formValues.fname} ${formValues.lname} has been added`, severity: "success" }))
        setSaving(false)
      }).catch(error => {
        console.log('error creating user in database: ', error.message)
        dispatch(setAlert({ message: `Error creating user: ${error.message}`, severity: "error" }))
        
        // remove auth account that was just created
        axios.post("/api/firebase/auth/delete", { 
          email: authObj.email
        }).then(() => {
          console.log('auth account deleted')
        }).catch(error => {
          console.log('error deleting auth acount', error.message)
        })

        setSaving(false)
      })
    }).catch(error => {
      console.log('error creating auth account', error.response.data.message)
      dispatch(setAlert({ message: `Error creating user: ${error.response.data.message}`, severity: "error" }))
      setSaving(false)
    })

  }

  return (
    <Modal
      open={modalOpen}
      onClose={handleCloseModal}
      aria-labelledby="modal-title"
    >
      <Box sx={outerStyle}>
        <Box sx={innerStyle}>
          <Box alignItems="center" display="flex" justifyContent="space-between">
            <Typography component="h2" variant="h6" id="modal-title">
              Add a New User
            </Typography>
            <IconButton
              onClick={handleCloseModal}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Box sx={{ pt: 3 }}>
            <UserForm
              handleCloseModal={handleCloseModal}
              handleSaveUser={handleSaveUser}
            />
          </Box>
        </Box>
        {saving && <LinearProgress />}
      </Box>
    </Modal>
  )
}
 
export default AddUserModal
