import React from "react"
import PropTypes from "prop-types"

import useMediaQuery from "@mui/material/useMediaQuery"
import { styled } from "@mui/material/styles"
import { visuallyHidden } from "@mui/utils"
import { Box, Checkbox, Grid, TableSortLabel } from "@mui/material"
import GroupIcon from "@mui/icons-material/Group"

const StyledCell = styled(Grid)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  fontSize: '.75rem',
  fontWeight: '500',
  padding: '.5rem .5rem 0',
  [theme.breakpoints.up('sm')]: {
    padding: '0 .25rem',
  },
}))

const DocumentsTableHead = (props) => {
  const isSmScreen = useMediaQuery("(min-width: 600px)")
  const isMdScreen = useMediaQuery("(min-width: 900px)")

  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props
  const createSortHandler = property => e => {
    onRequestSort(e, property)
  }

  return (
    <>
      <StyledCell item xs={8} sm={6} md={4}>
        <Checkbox
          color="primary"
          indeterminate={numSelected > 0 && numSelected < rowCount}
          checked={rowCount > 0 && numSelected === rowCount}
          onChange={onSelectAllClick}
          inputProps={{
            'aria-label': 'select all documents',
          }}
        />
        <TableSortLabel
          active={orderBy === "description"}
          direction={orderBy === "description" ? order : "asc"}
          onClick={createSortHandler("description")}
        >
          Filename
          {orderBy === "description" ? (
            <Box component="span" sx={visuallyHidden}>
              {order === "desc" ? "sorted descending" : "sorted ascending"}
            </Box>
          ) : null}
        </TableSortLabel>
      </StyledCell>
        {isMdScreen && (
          <StyledCell item md={3}>
            <TableSortLabel
              active={orderBy === "filename"}
              direction={orderBy === "filename" ? order : "asc"}
              onClick={createSortHandler("filename")}
            >
              File
              {orderBy === "filename" ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </StyledCell>
        )}
      <StyledCell item xs={3} sm={2} md={2}>
        <TableSortLabel
          active={orderBy === "created"}
          direction={orderBy === "created" ? order : "asc"}
          onClick={createSortHandler("created")}
        >
          Created
          {orderBy === "created" ? (
            <Box component="span" sx={visuallyHidden}>
              {order === "desc" ? "sorted descending" : "sorted ascending"}
            </Box>
          ) : null}
        </TableSortLabel>
      </StyledCell>
      {isSmScreen && (
        <StyledCell item sm={3} md={2}>
          <TableSortLabel
            active={orderBy === "createdBy"}
            direction={orderBy === "createdBy" ? order : "asc"}
            onClick={createSortHandler("createdBy")}
          >
            By
            {orderBy === "createdBy" ? (
              <Box component="span" sx={visuallyHidden}>
                {order === "desc" ? "sorted descending" : "sorted ascending"}
              </Box>
            ) : null}
          </TableSortLabel>
        </StyledCell>
      )}
      <StyledCell item xs={1} sm={1} md={1} justifyContent="center">
        {isMdScreen ? 'Assigned' : <GroupIcon />}
      </StyledCell>
    </>
  )
}

DocumentsTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
}

export default DocumentsTableHead
