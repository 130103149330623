import React from "react"
import { useSelector } from "react-redux"

import { Box, Grid } from "@mui/material"

import { selectFundsList } from "../../../app/store/fundsSlice"
import { selectUserInvestments } from "../../../app/store/authSlice"

import CardTitle from "../../../common/sharedComponents/CardTitle.component"
import PortfolioCard from "./PortfolioCard.component"
import MyInvestments from "./MyInvestments.component"

const PortfolioPage = () => {
  const funds = [...useSelector(selectFundsList)]
  const investments = useSelector(selectUserInvestments)

  return (
    <Box>
      {investments && (
        <MyInvestments funds={funds} investments={investments} />
      )}
      <CardTitle title="The Chenel Capital Portfolio" />
      <Grid container spacing={3}>
        {funds
          .sort((a, b) => {
            if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
            if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
            return 0
          })
          .map((fund) => (
            <Grid item key={fund.key} xs={12} sm={6} lg={3}>
              <PortfolioCard fund={fund} />
            </Grid>
          ))}
      </Grid>
    </Box>
  )
}

export default PortfolioPage
