import React, { useEffect, useState } from "react"

import useMediaQuery from "@mui/material/useMediaQuery"
import {
  Box,
  Button,
  FormHelperText,
  Grid,
  Stack,
  TextField,
} from "@mui/material"

const FundForm = ({ fund = {}, handleSaveFund }) => {
  const isLarge = useMediaQuery("(min-width: 1200px)")
  const [name, setName] = useState(fund.name || "")
  const [key, setKey] = useState(fund.key || "")
  const [overview, setOverview] = useState(fund.overview || "")
  const [imageLg, setImageLg] = useState(fund.imageLg || "")
  const [imageSm, setImageSm] = useState(fund.imageSm || "")
  const [section1Title, setSection1Title] = useState(fund.section1Title || "")
  const [section1Content, setSection1Content] = useState(fund.section1Content || "")
  const [section2Title, setSection2Title] = useState(fund.section2Title || "")
  const [section2Content, setSection2Content] = useState(fund.section2Content || "")
  const [section3Title, setSection3Title] = useState(fund.section3Title || "")
  const [section3Content, setSection3Content] = useState(fund.section3Content || "")
  const [section4Title, setSection4Title] = useState(fund.section4Title || "")
  const [section4Content, setSection4Content] = useState(fund.section4Content || "")

  const onNameChange = (e) => setName(e.target.value)
  const onOverviewChange = (e) => setOverview(e.target.value)
  const onImageLgChange = e => setImageLg(e.target.value)
  const onImageSmChange = e => setImageSm(e.target.value)
  const onSection1TitleChange = e => setSection1Title(e.target.value)
  const onSection1ContentChange = e => setSection1Content(e.target.value)
  const onSection2TitleChange = e => setSection2Title(e.target.value)
  const onSection2ContentChange = e => setSection2Content(e.target.value)
  const onSection3TitleChange = e => setSection3Title(e.target.value)
  const onSection3ContentChange = e => setSection3Content(e.target.value)
  const onSection4TitleChange = e => setSection4Title(e.target.value)
  const onSection4ContentChange = e => setSection4Content(e.target.value)

  useEffect(() => {
    if (name) setKey(name.trim().substring(0, 48).replaceAll(" ", "-").toLowerCase())
  }, [name])

  const handleSubmit = (e) => {
    e.preventDefault()
    handleSaveFund({
      id: fund.id,
      name,
      key,
      overview,
      imageLg,
      imageSm,
      section1Title,
      section1Content,
      section2Title,
      section2Content,
      section3Title,
      section3Content,
      section4Title,
      section4Content,
    })
  }

  return (
    <Box
      sx={{
        py: { xs: 1, md: 3 },
      }}
    >
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Fund Name"
              name="name"
              onChange={onNameChange}
              required
              size={isLarge ? "medium" : "small"}
              value={name}
            />
            <FormHelperText><strong>URL Parameter: </strong>{key}</FormHelperText>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Image URL (large screens)"
              name="imageLg"
              onChange={onImageLgChange}
              required
              size={isLarge ? "medium" : "small"}
              value={imageLg}
            />
            <FormHelperText>Suggested image size: 2400 x 1350</FormHelperText>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Image URL (small screens)"
              name="imageSm"
              onChange={onImageSmChange}
              required
              size={isLarge ? "medium" : "small"}
              value={imageSm}
            />
            <FormHelperText>Suggested image size: 800 x 450</FormHelperText>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Overview"
              multiline
              name="overview"
              onChange={onOverviewChange}
              required
              rows={4}
              size={isLarge ? "medium" : "small"}
              value={overview}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Section 1 Title"
              name="section1Title"
              onChange={onSection1TitleChange}
              size={isLarge ? "medium" : "small"}
              value={section1Title}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Section 1 Body"
              multiline
              name="section1Content"
              onChange={onSection1ContentChange}
              rows={isLarge ? 4 : 2}
              size={isLarge ? "medium" : "small"}
              value={section1Content}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Section 2 Title"
              name="section2Title"
              onChange={onSection2TitleChange}
              size={isLarge ? "medium" : "small"}
              value={section2Title}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Section 2 Body"
              multiline
              name="section2Content"
              onChange={onSection2ContentChange}
              rows={isLarge ? 4 : 2}
              size={isLarge ? "medium" : "small"}
              value={section2Content}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Section 3 Title"
              name="section3Title"
              onChange={onSection3TitleChange}
              size={isLarge ? "medium" : "small"}
              value={section3Title}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Section 3 Body"
              multiline
              name="section3Content"
              onChange={onSection3ContentChange}
              rows={isLarge ? 4 : 2}
              size={isLarge ? "medium" : "small"}
              value={section3Content}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Section 4 Title"
              name="section4Title"
              onChange={onSection4TitleChange}
              size={isLarge ? "medium" : "small"}
              value={section4Title}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Section 4 Body"
              multiline
              name="section4Content"
              onChange={onSection4ContentChange}
              rows={isLarge ? 4 : 2}
              size={isLarge ? "medium" : "small"}
              value={section4Content}
            />
          </Grid>
          <Grid item xs={12} sx={{ mt: ".75rem" }}>
            <Stack spacing={2} direction="row">
              <Button
                type="submit"
                variant="contained"
                size={isLarge ? "medium" : "small"}
              >
                {fund.id ? "Save Fund" : "Add Fund"}
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </Box>
  )
}

export default FundForm
