import axios from "axios"

export const fetchAllUsers = () => {
  return new Promise(resolve => {
    axios.get("/api/users/all").then(res => {
      const data = res.data
      const dataArray = []
      for (const [key, value] of Object.entries(data)) {
        dataArray.push({
          id: key,
          ...value
        })
      }
      resolve(dataArray)
    }).catch(error => {
      console.log(error.response.data)
      resolve([])
    })
  })
}
