import React from "react"

import { Box, Grid, IconButton } from "@mui/material"
import { Close as CloseIcon } from "@mui/icons-material"

const AssignUsersList = ({ assignedUsers, handleUnassignUser }) => (
  <Box
    sx={{
      borderTop: 1,
      borderColor: "grey.300",
      mt: 2,
      maxHeight: { xs: "14rem", md: "20rem", lg: "28rem" },
      overflowY: "auto",
    }}
  >
    {assignedUsers.map((user) => (
      <Grid
        container
        key={user.id}
        sx={{
          borderBottom: 1,
          borderColor: "grey.300",
          fontSize: { xs: ".75rem", md: ".875rem" },
          padding: ".5rem 0",
        }}
      >
        <Grid
          item
          xs={10}
          sm={11}
          sx={{
            alignItems: "center",
            display: "flex",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {`${user.lname}, ${user.fname} (${user.username})`}
        </Grid>
        <Grid
          item
          xs={2}
          sm={1}
          sx={{
            alignItems: "center",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <IconButton
            color="error"
            onClick={() => handleUnassignUser(user)}
            size="small"
            title="Remove user access"
          >
            <CloseIcon fontSize="1rem" />
          </IconButton>
        </Grid>
      </Grid>
    ))}
  </Box>
)

export default AssignUsersList
