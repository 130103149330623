import React, { useState } from "react"
import { useSelector } from "react-redux"
import { DateTime } from "luxon"

import { Box, Card, Grid, Typography } from "@mui/material"

import { selectCommunicationsList } from "../../../app/store/communicationsSlice"

import CardTitle from "../../../common/sharedComponents/CardTitle.component"
import CommunicationsDrawer from "../../../common/sharedComponents/CommunicationsDrawer.component"

import { RecentDocumentsSkeleton } from "./RecentDocuments.component"

const RecentCommunications= () => {
  const communications = useSelector(selectCommunicationsList)

  const [drawerOpen, setDrawerOpen] = useState(false)
  const [activeCommunication, setActiveCommunication] = useState(null)

  const handleOpenCommunication = communicationId => {
    setActiveCommunication(communicationId)
    setDrawerOpen(true)
  }

  const handleCloseCommunication = () => {
    setDrawerOpen(false)
    setActiveCommunication(null)
  }

  const getVisibleCommunications = (allCommunications, sliceSize) => {
    const communicationsArray = [...allCommunications]
    return communicationsArray.sort((a, b) => a.created > b.created ? -1 : 1).slice(0, sliceSize)
  }

  return (
    <Box mt={4}>
      <CardTitle title="Recent Communications" />
      <Card sx={{ fontSize: '.875rem', p: { xs: 2, md: 4 }, mt: { xs: 1, md: 2 } }}>
        <Grid container columnSpacing={1} rowSpacing={1}>
          <Grid item xs={8}>
            <Typography component="span" fontSize=".875rem" fontWeight="500">Communication</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography component="span" fontSize=".875rem" fontWeight="500">Date</Typography>
          </Grid>
          {communications.length ? (
            getVisibleCommunications(communications, 5).map(communication => (
              <React.Fragment key={communication.id} >
                <Grid item xs={8}>
                  <Typography
                    component="span"
                    fontWeight={!communication.read ? "600" : "400"}
                    onClick={() => handleOpenCommunication(communication.id)}
                    sx={{
                      color: 'primary.main',
                      cursor: 'pointer',
                      fontSize: '.875rem',
                      textDecoration: 'underline',
                      textDecorationColor: 'rgba(25, 118, 210, 0.4)',
                      '&: hover': {
                        textDecorationColor: 'rgba(25, 118, 210)',
                      }
                    }}
                  >
                    {communication.subject}
                  </Typography>
                </Grid>
                <Grid item xs={4} fontWeight={!communication.read ? "600" : "400"}>
                  {DateTime.fromISO(communication.created).toLocaleString()}
                </Grid>
              </React.Fragment>
            ))
          ) : (
            <RecentDocumentsSkeleton />
          )}
        </Grid>
      </Card>
      <CommunicationsDrawer
        drawerOpen={drawerOpen}
        handleDrawerClose={handleCloseCommunication}
        communicationId={activeCommunication ? activeCommunication : ""}
      />
    </Box>
  )
}
 
export default RecentCommunications
