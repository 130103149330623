import {
  Bar,
  BarChart,
  CartesianGrid,
  // LabelList,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts"

const GrossMoicChart = ({ grossMoic }) => {
  const data = []
  for (const [key, value] of Object.entries(grossMoic)) {
    data.push({ name: key, "MOIC": value})
  }

  return (
    <ResponsiveContainer width="99%" aspect={2}>
      <BarChart
        data={data}
        fontSize=".875rem"
        height={300}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
        width={500}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Bar dataKey="MOIC" fill="#165ecc">
          {/* <LabelList
            formatter={(value) => value + "x"}
            position="top"
            style={{ fontSize: ".75rem" }}
          /> */}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  )
}

export default GrossMoicChart
