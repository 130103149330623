import React, { useState } from "react"
import { NavLink } from "react-router-dom"
import { useDispatch } from 'react-redux'
import { getAuth, signInWithEmailAndPassword } from "firebase/auth"

import { styled } from '@mui/material/styles'
import {
  Button,
  Card,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  OutlinedInput,
  Stack,
  TextField,
  Typography
} from "@mui/material"
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon
} from "@mui/icons-material"

import { startSetAuth } from "../../app/store/authSlice"

import logo from "../../assets/images/chenel-capital-logo-wide.png"
const StyledLogo = styled('img')(() => ({
  marginBottom: '2rem',
  maxWidth: '180px',
  width: '100%'
}))

const formStyle = { width: '100%', }

const LoginPage = () => {
  const dispatch = useDispatch()
  const auth = getAuth()

  const [email, setEmail] = useState("")
  const [emailError, setEmailError] = useState("")
  const [password, setPassword] = useState("")
  const [passwordError, setPasswordError] = useState("")
  const [showPassword, setShowPassword] = useState(false)

  const handleSubmit = async e => {
    e.preventDefault()
    setPasswordError("")

    if (!email) setEmailError("Please enter a valid email")
    else if (!password) setPasswordError("Enter your password")
    else {
      // Sign in with Firebase Auth
      signInWithEmailAndPassword(auth, email, password).then(userCredential => {
        // get user from firebase and dispatch to store
        dispatch(startSetAuth(userCredential.user))
      }).catch((error) => {
        if (error.code === "auth/invalid-email") setEmailError("Please enter a valid email")
        else if (error.code === "auth/user-not-found") setEmailError("Email address not found")
        else if (error.code === "auth/wrong-password") setPasswordError("Incorrect password")
        else setPasswordError("Error authenticating user" + error.code)
      })
    }
    
  }

  return (
    <Card sx={{ maxWidth: '500px', padding: 4, width: '90%',}}>
      <Stack spacing={2}>
        <StyledLogo src={logo} alt="Chenel Capital" />
        <Typography component="h1" variant="h5" sx={{ maxWidth: '400px', pb: 4 }}>
          Log in to the Chenel Capital Investor Portal.
        </Typography>
        <form
          method="post"
          onSubmit={e => handleSubmit(e)}
          style={formStyle}
        >
          <Stack spacing={3} alignItems="center">
            <TextField
              error={!!emailError}
              fullWidth
              helperText={emailError}
              label="Email"
              name="email"
              onChange={e => {
                setEmailError("")
                setEmail(e.target.value)
              }}
              value={email}
              variant="outlined"
            />
            <FormControl variant="outlined" sx={{ width: '100%' }}>
              <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
              <OutlinedInput
                error={!!passwordError}
                id="outlined-adornment-password"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={e => {
                  setPasswordError("")
                  setPassword(e.target.value)
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      onMouseDown={e => e.preventDefault()}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
              <FormHelperText error={!!passwordError}>
                {passwordError}
              </FormHelperText>
            </FormControl>
            <Button fullWidth type="submit" variant="contained">
              Log in
            </Button>
            <Typography>
              Don't have an account?{" "}
              <Link component={NavLink} to="/register" underline="none">Register now</Link>
            </Typography>
            <Typography>
              <Link component={NavLink} to="/forgot-password" underline="none">
                Forgot Password?
              </Link>
            </Typography>
          </Stack>
        </form>
      </Stack>
    </Card>
  )
}

export default LoginPage
