import React, { useState, useEffect } from "react"
import { NavLink } from "react-router-dom"
import { useSelector } from "react-redux"

import { Badge, ListItem, ListItemIcon, ListItemText } from "@mui/material"
import { FilePresent as FilePresentIcon } from "@mui/icons-material"

import { selectUserRole } from "../../app/store/authSlice"
import { selectDocumentsList } from "../../app/store/documentsSlice"

const NavDrawerDocuments = ({ to, handleDrawerToggle }) => {
  const documents = useSelector(selectDocumentsList)
  const role = useSelector(selectUserRole)
  const [undreadDocs, setUnreadDocs] = useState(0)
  
  useEffect(() => {
    let unread = 0
    for (let i = 0; i < documents.length; i++) {
      if (role === 'user' && !documents[i].read) unread += 1
    }
    setUnreadDocs(unread)
  }, [documents, role])

  return (
    <ListItem
      component={NavLink}
      onClick={handleDrawerToggle}
      to={to}
    >
      <ListItemIcon>
      <Badge badgeContent={undreadDocs} color="error">
        <FilePresentIcon />
      </Badge>
      </ListItemIcon>
      <ListItemText primary="Documents" />
    </ListItem>
  )
}
 
export default NavDrawerDocuments
