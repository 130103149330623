import React from "react"

import { Box, Link, Typography } from "@mui/material"

const RegistrationStepConfirmation = () => (
  <Box py={4}>
  <Typography pb={2}>
      Thank you for your interest in joining the Chenel Capital Investor Portal. Your
      information has been submitted and our team will review your responses and follow 
      up directly with you asap.
    </Typography>
    <Typography pb={2}>
      Feel free to contact us at{" "}
      <Link href="mailto:admin@chenelcapital.com?subject=Investor Portal Registration Status" underline="none">
        admin@chenelcapital.com
      </Link>{" "}
      with any questions about your registration status.
    </Typography>
    <Typography pb={2}>
      <Link href="https://chenelcapital.com" underline="none">Return to ChenelCapital.com</Link>
    </Typography>
  </Box>
)
 
export default RegistrationStepConfirmation
