import React from "react"
import { useSelector } from "react-redux"
import { DateTime } from "luxon"

import { Box, Card, Grid, Link, Skeleton, Typography } from "@mui/material"

import { selectDocumentsList } from "../../../app/store/documentsSlice"

import CardTitle from "../../../common/sharedComponents/CardTitle.component"

export const RecentDocumentsSkeleton = () => {
  const rows = []
  for (let i = 0; i < 5; i++) {
    rows.push(
      <React.Fragment key={i}>
        <Grid item xs={8}>
          <Skeleton />
        </Grid>
        <Grid item xs={4}>
          <Skeleton />
        </Grid>
      </React.Fragment>
    )
  }

  return rows
}

const RecentDocuments = () => {
  const documents = useSelector(selectDocumentsList)

  const getVisibleDocuments = (allDocuments, sliceSize) => {
    const documentsArray = [...allDocuments]
    return documentsArray.sort((a, b) => a.created > b.created ? -1 : 1).slice(0, sliceSize)
  }

  return (
    <Box mt={4}>
      <CardTitle title="Recent Documents" />
      <Card sx={{ fontSize: '.875rem', p: { xs: 2, md: 4 }, mt: { xs: 1, md: 2 } }}>
        <Grid container columnSpacing={1} rowSpacing={1}>
          <Grid item xs={8}>
            <Typography component="span" fontSize=".875rem" fontWeight="500">Document</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography component="span" fontSize=".875rem" fontWeight="500">Date</Typography>
          </Grid>
          {documents.length ? (
            getVisibleDocuments(documents, 5).map(document => (
              <React.Fragment key={document.id}>
                <Grid item xs={8} fontWeight={!document.read ? "600" : "400"}>
                  <Link href={document.downloadUrl} target="_blank" rel="noreferrer">
                    {document.description}
                  </Link>
                </Grid>
                <Grid item xs={4} fontWeight={!document.read ? "600" : "400"}>
                  {DateTime.fromISO(document.created).toLocaleString()}
                </Grid>
              </React.Fragment>
            ))
          ) : (
            <RecentDocumentsSkeleton />
          )}
        </Grid>
      </Card>
    </Box>
  )
}
 
export default RecentDocuments
