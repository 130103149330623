import React from "react"
import PropTypes from "prop-types"

import { styled } from "@mui/material/styles"
import { visuallyHidden } from "@mui/utils"
import { Box, Checkbox, Grid, TableSortLabel } from "@mui/material"

const StyledCell = styled(Grid)(({ theme }) => ({
  alignItems: "center",
  display: "flex",
  fontSize: ".75rem",
  fontWeight: "500",
  padding: ".5rem .5rem 0",
  [theme.breakpoints.up("sm")]: {
    padding: "0 .25rem",
  },
}))

const GroupsTableHead = (props) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props
  const createSortHandler = (property) => (e) => {
    onRequestSort(e, property)
  }

  return (
    <>
      <StyledCell item xs={8} sm={5} md={3}>
        <Checkbox
          color="primary"
          indeterminate={numSelected > 0 && numSelected < rowCount}
          checked={rowCount > 0 && numSelected === rowCount}
          onChange={onSelectAllClick}
          inputProps={{
            "aria-label": "select all groups",
          }}
        />
        <TableSortLabel
          active={orderBy === "name"}
          direction={orderBy === "name" ? order : "asc"}
          onClick={createSortHandler("name")}
        >
          Name
          {orderBy === "name" ? (
            <Box component="span" sx={visuallyHidden}>
              {order === "desc" ? "sorted descending" : "sorted ascending"}
            </Box>
          ) : null}
        </TableSortLabel>
      </StyledCell>
    </>
  )
}

GroupsTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
}

export default GroupsTableHead
