import React, { useState } from "react"

import { styled } from "@mui/material/styles"
import { Box, Grid, TablePagination } from "@mui/material"

import DocumentsTableHead from "./DocumentsTableHead.component"
import DocumentsRow from "./DocumentsRow.compoonent"

const StyledContainer = styled(Box)(({ theme }) => ({
  fontSize: '.875rem',
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    fontSize: '.875rem',
  },
  '& .MuiCheckbox-root': {
    padding: '4px',
    [theme.breakpoints.up('sm')]: {
      padding: '9px',
    },
  }
}))

const StyledHead = styled(Grid)(({ theme }) => ({
  borderBottom: '1px solid ' + theme.palette.grey[300],
}))

const StyledTablePagination = styled(TablePagination)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    marginTop: '.5rem',
    '& .MuiToolbar-root': {
      justifyContent: 'space-between',
      '& .MuiTablePagination-spacer': {
        display: 'none',
      },
      '& .MuiTablePagination-selectLabel': {
        display: 'none',
      },
      '& .MuiInputBase-root': {
        display: 'none',
      },
      '& .MuiTablePagination-actions': {
        margin: 0,
      }
    },
  }
}))

const DocumentsTable = ({ documents }) => {
  const [order, setOrder] = useState("desc")
  const [orderBy, setOrderBy] = useState("created")
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc"
    setOrder(isAsc ? "desc" : "asc")
    setOrderBy(property)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const getVisibleDocuments = (allDocuments) => {
    return allDocuments
      .slice()
      .sort((a, b) => {
        if (orderBy === "description" && order === "asc") {
          return a.description > b.description ? 1 : -1
        } else if (orderBy === "description" && order === "desc") {
          return a.description < b.description ? 1 : -1
        } else if (orderBy === "created" && order === "asc") {
          return a.created > b.created ? 1 : -1
        } else if (orderBy === "created" && order === "desc") {
          return a.created < b.created ? 1 : -1
        } else { return 1 }
      })
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
  }

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - documents.length) : 0

  return (
    <StyledContainer>
      <StyledHead container>
        <DocumentsTableHead
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
        />
      </StyledHead>
      {getVisibleDocuments(documents).map(document => (
        <DocumentsRow key={document.id} document={document} />
      ))}
      {emptyRows > 0 && (
        <Grid item xs={12} style={{ height: 53 * emptyRows }}>
          <></>
        </Grid>
      )}
      <StyledTablePagination
        rowsPerPageOptions={[10, 25]}
        component="div"
        count={documents.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </StyledContainer>
  )
}
 
export default DocumentsTable
