import React, { useState } from "react"

import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
} from "@mui/material"
import CancelIcon from "@mui/icons-material/Cancel"

import ComposeCommunicationModal from "../../../common/sharedComponents/ComposeCommunicationModal.component"

const MessagesTableToolbar = ({ search, setSearch }) => {
  const [composeCommunicationModalOpen, setComposeCommunicationModalOpen] = useState(false)

  return (
    <Box sx={{ my: 2, px: 1 }}>
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
        <FormControl sx={{ width: '100%' }} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-search">Search Messages</InputLabel>
          <OutlinedInput
            id="outlined-adornment-search"
            value={search}
            onChange={e => setSearch(e.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  disabled={!search}
                  edge="end"
                  onClick={() => setSearch("")}
                >
                  <CancelIcon color={search ? "error" : ""} />
                </IconButton>
              </InputAdornment>
            }
            label="Search Messages"
          />
        </FormControl>
        <Button
          onClick={e => {
            e.currentTarget.blur()
            setComposeCommunicationModalOpen(true)
          }}
          variant="contained"
          sx={{ px: '2rem', whiteSpace: 'nowrap' }}
        >
          Compose
        </Button>
      </Stack>
      <ComposeCommunicationModal
        modalOpen={composeCommunicationModalOpen}
        handleCloseModal={() => setComposeCommunicationModalOpen(false)}
        senderRole="user"
      />
    </Box>
  )
}

export default MessagesTableToolbar
