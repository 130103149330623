import React from "react"
import { useSelector } from "react-redux"

import { styled } from "@mui/material/styles"
import { Alert, Button, Link, Stack } from "@mui/material"

import { selectSystemInfo } from "../../../app/store/systemInfoSlice"

export const StyledAlert = styled(Alert)(() => ({
  marginBottom: "1rem",
  "& .MuiAlert-icon": {
    alignItems: "center",
  },
  "& .MuiAlert-message": {
    alignItems: "center",
    flexGrow: 1,
  },
}))

const Alerts = () => {
  const systemInfo = useSelector(selectSystemInfo)

  return (
    <>
      {systemInfo?.alert?.text && (
        <StyledAlert severity="info">
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            justifyContent="space-between"
          >
            {systemInfo.alert.text}
            {systemInfo.alert.action?.text &&
              systemInfo.alert.action?.location && (
                <Button
                  component={Link}
                  href={systemInfo.alert.action.location}
                  size="small"
                  variant="contained"
                >
                  {systemInfo.alert.action.text}
                </Button>
              )}
          </Stack>
        </StyledAlert>
      )}
    </>
  )
}

export default Alerts
