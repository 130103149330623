import React from "react"
import { useDispatch, useSelector } from "react-redux"
import axios from "axios"

import { Box, Typography } from "@mui/material"

import { selectFundsList } from "../../../app/store/fundsSlice"
import { setAlert } from "../../../app/store/alertsSlice"
import { updateUser } from "../../../app/store/usersSlice"

import UserInvestmentForm from "./UserInvestmentForm.component"

const UserInvestments = ({ user }) => {
  const dispatch = useDispatch()
  const funds = useSelector(selectFundsList)
  const investments = user.investments || null

  const getVisibleFunds = () => {
    return funds.slice().sort((a, b) => (a.name > b.name ? 1 : -1))
  }

  const handleSaveData = (investment) => {
    const dbObj = {
      investments: {
        ...investments,
        ...investment
      },
    }

    // Update user in rtdb
    axios
      .post("/api/users/update", {
        userId: user.id,
        dbObj,
      })
      .then(() => {
        // Update document in store
        dispatch(
          updateUser({
            id: user.id,
            ...dbObj,
          })
        )

        // udpate is complete
        dispatch(
          setAlert({
            message: `Updates to user ${user.fname} ${user.lname} have been saved`,
            severity: "success",
          })
        )
      })
      .catch((error) => {
        console.log("error writing to db", error.response.data)
        dispatch(
          setAlert({ message: "Error updating user", severity: "error" })
        )
      })
  }

  return (
    <Box>
      {funds.length > 0 &&
        getVisibleFunds().map((fund) => (
          <Box key={fund.id} mb={4}>
            <Typography fontWeight="500">{fund.name}</Typography>
            <UserInvestmentForm
              fund={fund}
              investment={investments ? investments[fund.id] : {}}
              handleSaveData={handleSaveData}
            />
          </Box>
        ))}
    </Box>
  )
}

export default UserInvestments
