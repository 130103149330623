import React, { useEffect } from "react"

import { Typography } from "@mui/material"
import UserAccreditationStatusForm from "../../common/sharedComponents/UserAccreditationStatusForm.component"

const RegistrationStepAccreditation = ({
  formValues,
  handleFormChange,
  validateStep,
  setValidateStep,
  handleNext,
  setRegistrationError
}) => {
  // Validate all fields in this registration step when "Next" is clicked
  useEffect(() => {
    let valid = false
    if (validateStep === 1) {
      
      if (formValues.investorStatus) {
        if (formValues.investorStatus === "Natural Person") {
          if (formValues.riskTolerance 
              && formValues.investmentExperience 
              && formValues.investmentYears 
              && formValues.investmentSize
          ) {
            setRegistrationError("")
            valid = true
          } else {
            setRegistrationError("Please respond to all questions")
          }
        } else {
          setRegistrationError("")
          valid = true
        }
      } else {
        setRegistrationError("Please indicate your Accreditation Status")
      }

      setValidateStep(null)
      if (valid) handleNext()
    }
  // only want to re-render when validate step changes, ignore other data
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validateStep])

  return (
    <>
      <Typography component={"h2"} variant={"h6"} pb={2}>Accredited Investor Status</Typography>
      <UserAccreditationStatusForm formValues={formValues} handleFormChange={handleFormChange} />
    </>
  )
}
 
export default RegistrationStepAccreditation