import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from 'react-redux'
import { DateTime } from "luxon"

import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Link,
  Stack,
  TextField
} from "@mui/material"

import {ContentCopy as ContentCopyIcon} from "@mui/icons-material"

import { selectUserId } from "../../../app/store/authSlice"
import { setAlert } from "../../../app/store/alertsSlice"
import { selectDocumentsList } from "../../../app/store/documentsSlice"

import { getFilenameBase, getFilenameExt } from "./AddDocumentModal.component"
import { Tooltip } from "@mui/material"
import AssignUsers from "./AssignUsers.component"

const DocumentForm = ({ document, handleSaveDocument }) => {
  const dispatch = useDispatch()
  const userId = useSelector(selectUserId)
  const documents = useSelector(selectDocumentsList)

  const [filename, setFilename] = useState("")
  const [filenameError, setFilenameError] = useState("")
  const [description, setDescription] = useState(document.description)
  const [descriptionError, setDescriptionError] = useState("")
  const [assignedUsers, setAssignedUsers] = useState([])
  const [sendEmail, setSendEmail] = useState(false)
  
  const fileExt = getFilenameExt(document.filename)
  
  const downloadUrl = document.downloadUrl || ""

  // update filename when document.filename changes
  useEffect(() => {
    setFilename(getFilenameBase(document.filename))
  }, [document.filename])

  // Check if filename is unique when it changes
  useEffect(() => {
    setFilenameError(null)
    if (documents.some(item => item.filename === filename)) {
      setFilenameError("This filename is already in use, please enter a unique filename.")
    }
  }, [documents, filename])

  const handleSubmit = e => {
    e.preventDefault()
    const created = document.created || DateTime.now().setZone('America/New_York').toISO()
    let error = false 

    if (!filename) {
      setFilenameError("Filename is required")
      error = true
    }

    if (!error && !filenameError && !descriptionError) {
      const permissions = assignedUsers.reduce((obj, item) => Object.assign(obj, { [item.id]: true }), {})
      handleSaveDocument(
        {
          created,
          createdBy: userId,
          description,
          downloadUrl,
          filename: filename + fileExt,
          path: document.path,
          permissions
        },
        sendEmail,
        sendEmail ? assignedUsers.map(user => user.username) : [])
    }
  }

  return (
    <Box sx={{ py: 3 }}>
      <form
        onSubmit={handleSubmit}
      >
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <TextField
              error={!!descriptionError}
              fullWidth
              helperText={descriptionError}
              label="Filename"
              name="lname"
              onChange={e => {
                setDescriptionError(null)
                setDescription(e.target.value)
              }}
              value={description}
            />
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row">
              <TextField
                disabled={!!document.id}
                error={!!filenameError}
                fullWidth
                helperText={filenameError}
                label="File"
                name="filename"
                onChange={e => setFilename(e.target.value)}
                value={filename}
              />
              <TextField
                disabled
                name="fileExt"
                sx={{ width: '75px', ml: '5px' }}
                value={fileExt}
              />
            </Stack>
          </Grid>
          {downloadUrl && (
            <Grid item xs={12} sx={{ pt: '1rem!important' }}>
              <Stack direction="row" spacing={1} alignItems="center">
                <Link href={downloadUrl}>Download Url</Link>
                <Tooltip title="Copy URL to clipboard">
                  <IconButton
                    onClick={() => {
                      dispatch(setAlert({ message: "URL copied to clipboard", severity: "info" }))
                      navigator.clipboard.writeText(downloadUrl)
                    }}
                  >
                    <ContentCopyIcon sx={{ color: 'grey.500', fontSize: '1.25rem' }} />
                  </IconButton>
                </Tooltip>
              </Stack>
            </Grid>
          )}
          <Grid item xs={12}>
            {document && (
              <AssignUsers
                document={document}
                assignedUsers={assignedUsers}
                setAssignedUsers ={setAssignedUsers}
              />
            )}
          </Grid>
          {!document.id && (
            <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox checked={sendEmail} />}
                disabled={!assignedUsers.length}
                label="Notify assigned users by email"
                onChange={() => setSendEmail(!sendEmail)}
              />
            </Grid>
          )}
          <Grid item xs={12} sx={{ mt: '.75rem' }}>
            <Stack spacing={2} direction="row">
              <Button
                type="submit"
                variant="contained"
              >
                Save Document
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </Box>
  )
}

DocumentForm.propTypes = {
  document: PropTypes.object.isRequired
}

export default DocumentForm
