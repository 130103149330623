import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import axios from "axios"

import { Box, LinearProgress, Paper } from "@mui/material"

import { setAlert } from "../../../app/store/alertsSlice"
import { selectDocumentById, updateDocument } from "../../../app/store/documentsSlice"

import Breadcrumbs from "../../../common/sharedComponents/Breadcrumbs.component"
import PageTitle from "../../../common/sharedComponents/PageTitle.component"
import DocumentForm from "./DocumentForm.component"

const EditDocumentPage = () => {
  const dispatch = useDispatch()
  const { documentId } = useParams()
  const document = useSelector(selectDocumentById(documentId))

  const [saving, setSaving] = useState(false)
  const [delayedAlert, setDelayedAlert] = useState(null)

  // add a delay to show user that saving is in progress
  useEffect(() => {
    if (delayedAlert) {
      const timer2 = setTimeout(() => {
        setDelayedAlert(null)
        setSaving(false)
        dispatch(setAlert(delayedAlert))
      }, 1000)
      return () => clearTimeout(timer2)
    }
  }, [delayedAlert, dispatch])

  const handleSaveDocument = (formObj) => {
    setSaving(true)

    const documentId = document.id
    // Update document in rtdb
    axios.post("/api/documents/update", {
      documentId,
      dbObj: formObj
    }).then(() => {
      // Update document in store
      dispatch(updateDocument({
        id: documentId,
        ...formObj
      }))

      // udpate is complete
      setDelayedAlert({ message: "Document updates have been saved", severity: "success" })
    }).catch(error => {
      console.log('error writing to db', error.response.data)
      dispatch(setAlert({ message: "Error updating document", severity: "error" }))
      setSaving(false)
    })

  }

  return (
    <>
      <Breadcrumbs
        pages={[
          { title: "Admin", to: "/admin" },
          { title: "Documents", to: "/admin/documents" },
          { title: document ? document.filename : "document" },
        ]}
      />
      <PageTitle title="Edit Document" />
      <Paper elevation={1}>
        <Box sx={{ pt: 2, px: { xs: 2, md: 4 }, pb: 3 }}>
          <DocumentForm
            document={document}
            handleSaveDocument={handleSaveDocument}
          />
        </Box>
        {saving && <LinearProgress />}
      </Paper>
    </>
  )
}
 
export default EditDocumentPage
