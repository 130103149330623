import React, { useState } from "react"
import { NavLink } from "react-router-dom"
import { useSelector } from "react-redux"
import { DateTime } from "luxon"

import useMediaQuery from "@mui/material/useMediaQuery"
import { styled } from "@mui/material/styles"
import { Box, Card, Grid, Link, Pagination, Stack, Typography } from "@mui/material"

import { selectUsersList } from "../../../app/store/usersSlice"

const StyledCell = styled(Grid)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  padding: '.25rem',
  width: '200px', // prevents cell from expanding to fit text
  [theme.breakpoints.up('sm')]: {
    padding: '.5rem',
  },
}))

const StyledSpanTruncated = styled('span')(() => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}))

const RecentlyActiveUsers = () => {
  const isSmall = useMediaQuery("(min-width: 600px)")
  const pageLimit = 5
  const [page, setPage] = useState(1)

  const allUsers = useSelector(selectUsersList)
    .slice()
    .sort((a, b) => {
      if (a.lastActive === b.lastActive) return 0
      else if (a.lastActive === null) return 1
      else if (b.lastActive === null) return -1
      else return a.lastActive > b.lastActive ? -1 : 1
    })
    .slice(0, 15)

  const users = allUsers.slice(((page - 1) * pageLimit), (page * pageLimit))

  const handlePageChange = (e, value) => setPage(value)

  return (
    <Box display="flex" flexDirection="column" flexGrow="1">
      <Card sx={{
        flexGrow: '1',
        fontSize: '.875rem',
        p: { xs: 2, md: 4 }
      }}>
        <Typography component="h6" variant="h6" fontSize="1rem" pb={2}>
          Recently Active
        </Typography>
        {users.length > 0 ? (
          <Grid container columnSpacing={1} rowSpacing={1}>
            <Grid item xs={8} sm={4}>
              <Typography component="span" fontSize=".875rem" fontWeight="500">User</Typography>
            </Grid>
            {isSmall && (
              <Grid item sm={5}>
                <Typography component="span" fontSize=".875rem" fontWeight="500">Company</Typography>
              </Grid>
            )}
            <Grid item xs={4} sm={3} align="right">
              <Typography component="span" fontSize=".875rem" fontWeight="500">
                Last Active
              </Typography>
            </Grid>
            {users.map(user => (
              <React.Fragment key={user.id}>
                <StyledCell item xs={8} sm={4}>
                  <StyledSpanTruncated>
                    <NavLink component={Link} to={`/admin/users/${user.id}`}>
                      {`${user.lname}, ${user.fname}`}
                    </NavLink>
                  </StyledSpanTruncated>
                </StyledCell>
                {isSmall && (
                  <StyledCell item xs={5}>
                    <StyledSpanTruncated>
                      {user.company}
                    </StyledSpanTruncated>
                  </StyledCell>
                )}
                <Grid item xs={4} sm={3} align="right">
                  {user.lastActive && (
                    isSmall ? (
                      DateTime.fromISO(user.lastActive).toFormat('LL/dd/yyyy')
                    ) : (
                      DateTime.fromISO(user.lastActive).toFormat('L/d/yy')
                    )
                  )}
                </Grid>
              </React.Fragment>
            ))}
            {Math.ceil(allUsers.length/pageLimit) > 1 && (
              <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                <Pagination count={Math.ceil(allUsers.length/pageLimit)} page={page} onChange={handlePageChange} />
              </Grid>
            )}
          </Grid>
        ) : (
          <Stack spacing={1} alignItems="center">
            <Typography fontSize={{ xs: '4rem', sm: '6rem' }}>0</Typography>
            <Typography fontSize=".875rem">Users</Typography>
          </Stack>
        )}
      </Card>
    </Box>
  )
}
 
export default RecentlyActiveUsers
