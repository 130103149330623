import React, { useState } from "react"
import { useSelector } from "react-redux"

import useMediaQuery from "@mui/material/useMediaQuery"

import { selectUser } from "../../app/store/authSlice"

import { Box, Button, Typography } from "@mui/material"
import MoreIcon from "@mui/icons-material/MoreVert"
import UserMenu from "./UserMenu.component"

const UserStack = ({ adminRoute }) => {
  const user = useSelector(selectUser)
  const fullName = `${user.fname} ${user.lname}`
  const mobile = useMediaQuery("(max-width: 600px)")

  const [menuAnchor, setMenuAnchor] = useState(null)

  const handleOpenMenu = (e) => setMenuAnchor(e.currentTarget)

  const handleCloseMenu = () => setMenuAnchor(null)

  return (
    <Box sx={{ marginLeft: "auto" }}>
      <Button
        id="user-menu-button"
        onClick={handleOpenMenu}
        color="inherit"
        sx={{ alignItems: "center", display: "flex", textTransform: "none" }}
      >
        {!mobile && (
          <Typography
            display="block"
            overflow="hidden"
            textOverflow="ellipsis"
            width="100%"
          >
            {fullName}
          </Typography>
        )}
        <MoreIcon sx={{ marginLeft: ".5rem" }} />
      </Button>
      <UserMenu
        adminRoute={adminRoute}
        menuAnchor={menuAnchor}
        handleCloseMenu={handleCloseMenu}
        role={user.role}
      />
    </Box>
  )
}

export default UserStack
