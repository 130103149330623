import React, { useState, useEffect, useRef } from "react"

import { Box, Button, Stack } from "@mui/material"
import {
  Delete as DeleteIcon,
  Reply as ReplyIcon,
  Send as SendIcon,
} from "@mui/icons-material"

import RichTextEditor from "./RichTextEditor.component"
import CommunicationReply from "./CommunicationReply.component"

const AlwaysScrollToBottom = () => {
  const elementRef = useRef()
  useEffect(() => elementRef.current.scrollIntoView())
  return <div ref={elementRef} />
}

const CommunicationReplies = ({ replies, sendReply }) => {
  const [replyOpen, setReplyOpen] = useState(false)
  const [body, setBody] = useState(null)

  const repliesArray = []
  if (replies) {
    for (const [key, value] of Object.entries(replies)) {
      repliesArray.push({
        id: key,
        ...value
      })
    }
  }

  const handleRichText = richText => {
    setBody(JSON.stringify(richText))
  }

  const handleReply = () => {
    setBody(null)
    setReplyOpen(false)
    sendReply(body)
  }

  return (
    <Box>
      {(repliesArray.length > 0) && (
        repliesArray.sort((a, b) => (a.created > b.created ? 1 : -1)).map(reply => (
          <CommunicationReply key={reply.id} reply={reply} />
        ))
      )}
      {replyOpen ? (
        <Box sx={{ alignItems: 'flex-end', display: 'flex', flexDirection: 'column', mt: '1rem' }}>
          <Box mt={1} width={{ xs: '100%', sm: '80%' }}>
            <RichTextEditor
              controls="partial"
              defaultValue=""
              handleRichText={handleRichText}
              label="reply..."
            />
          </Box>
          <Stack direction="row" mt={1} spacing={1}>
            <Button onClick={() => setReplyOpen(false)} sx={{ color: 'text.secondary' }}>
              <DeleteIcon sx={{ marginRight: '.25rem' }} /> Discard
            </Button>
            <Button onClick={handleReply}>
              <SendIcon sx={{ marginRight: '.25rem' }} /> Send
            </Button>
          </Stack>
        </Box>
      ) : (
      <Box display="flex" justifyContent="flex-end" mt={2}>
        <Button onClick={() => {
          setReplyOpen(true)
        }}>
          <ReplyIcon sx={{ marginRight: '.25rem' }} /> Reply
        </Button>
      </Box>
      )}
      <AlwaysScrollToBottom />
    </Box>
  )
}
 
export default CommunicationReplies
