import React, { useState } from "react"

import { Autocomplete, TextField } from "@mui/material"

const AssignUsersGroupSearch = ({ groupList, assignGroup }) => {
  const [selectValue, setSelectValue] = useState("")
  const [selectInputValue, setSelectInputValue] = useState("")

  const handleAssignGroup = (e, group) => {
    if (group) {
      assignGroup(group)
      setSelectValue("")
      setSelectInputValue("")
    }
  }

  return (
    <Autocomplete
      disableClearable
      disablePortal
      getOptionLabel={(option) => option.name || ""}
      id="select-groups"
      options={groupList}
      renderInput={(params) => (
        <TextField {...params} label="Search by group name" />
      )}
      size="small"
      sx={{ flexGrow: 1 }}
      value={selectValue}
      onChange={handleAssignGroup}
      inputValue={selectInputValue}
      onInputChange={(e, newInputValue) => setSelectInputValue(newInputValue)}
    />
  )
}

export default AssignUsersGroupSearch
