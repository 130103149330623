import React from "react"
import MUIRichTextEditor from "mui-rte"

import { createTheme, ThemeProvider, } from "@mui/material/styles"

const richTextTheme = createTheme({
  overrides: {
    MUIRichTextEditor: {
      container: {
        fontSize: '.875rem',
        marginTop: 0,
        position: 'relative',
      },
      editor: {
        overflowY: 'auto',
        '& figure': {
          margin: 0,
        },
        '& ul': {
          listStyleType: 'disc',
          margin: '1rem 0 1rem 1.5rem',
        },
        '& ol': {
          listStyleType: 'numeric',
          margin: '1rem 0 1rem 1.5rem',
        },
      },
      toolbar: {
        display: 'none',
      },
      blockQuote: {
        color: 'red',
      }
    }
  }
})

const RichTextDisplay = ({ defaultValue }) => (
  <ThemeProvider theme={richTextTheme}>
    <MUIRichTextEditor 
      defaultValue={defaultValue}
      readOnly={true}
    />
  </ThemeProvider>
)
 
export default RichTextDisplay
