import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"

import useMediaQuery from "@mui/material/useMediaQuery"
import { styled } from "@mui/material/styles"
import { Box, Checkbox, Grid, TablePagination } from "@mui/material"
import {
  Cancel as CancelIcon,
  CheckCircle as CheckCircleIcon,
  Pending as PendingIcon,
} from "@mui/icons-material"

import { selectSuperAdmin } from "../../../app/store/authSlice"

import UsersTableHead from "./UsersTableHead.component"

const StyledContainer = styled(Box)(({ theme }) => ({
  fontSize: ".875rem",
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    fontSize: ".875rem",
  },
  "& .MuiCheckbox-root": {
    padding: "4px",
    [theme.breakpoints.up("sm")]: {
      padding: "9px",
    },
  },
}))

const StyledHead = styled(Grid)(({ theme }) => ({
  borderBottom: "1px solid " + theme.palette.grey[300],
}))

const StyledRow = styled(Grid)(({ theme }) => ({
  borderBottom: "1px solid " + theme.palette.grey[300],
  cursor: "pointer",
  overflow: "hidden",
  "&:hover": {
    backgroundColor: theme.palette.grey[100],
  },
}))

const StyledCell = styled(Grid)(({ theme }) => ({
  alignItems: "center",
  display: "flex",
  padding: ".5rem",
  [theme.breakpoints.up("sm")]: {
    padding: "0.25rem",
  },
  width: "200px", // prevents cell from expanding to fit text
}))

const StyledSpanTruncated = styled("span")(() => ({
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
}))

const StyledTablePagination = styled(TablePagination)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    marginTop: ".5rem",
    "& .MuiToolbar-root": {
      justifyContent: "space-between",
      "& .MuiTablePagination-spacer": {
        display: "none",
      },
      "& .MuiTablePagination-selectLabel": {
        display: "none",
      },
      "& .MuiInputBase-root": {
        display: "none",
      },
      "& .MuiTablePagination-actions": {
        margin: 0,
      },
    },
  },
}))

const UsersTable = ({ users, selected, setSelected }) => {
  const isSuperAdmin = useSelector(selectSuperAdmin)
  const navigate = useNavigate()
  const isSmScreen = useMediaQuery("(min-width: 600px)")
  const isMdScreen = useMediaQuery("(min-width: 900px)")

  const [order, setOrder] = useState("asc")
  const [orderBy, setOrderBy] = useState("lname")
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)

  const handleRequestSort = (e, property) => {
    const isAsc = orderBy === property && order === "asc"
    setOrder(isAsc ? "desc" : "asc")
    setOrderBy(property)
  }

  const handleSelectAllClick = (e) => {
    if (e.target.checked) {
      const newSelecteds = users.map((n) => n.id)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  const handleClick = (e, name) => {
    const selectedIndex = selected.indexOf(name)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }

    setSelected(newSelected)
  }

  const handleChangePage = (e, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10))
    setPage(0)
  }

  const isSelected = (id) => selected.indexOf(id) !== -1

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0

  return (
    <StyledContainer>
      <StyledHead container>
        <UsersTableHead
          numSelected={selected.length}
          order={order}
          orderBy={orderBy}
          onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          rowCount={users.length}
        />
      </StyledHead>
      {users
        .slice()
        .sort((a, b) => {
          if (orderBy === "lname" && order === "asc") {
            return a.lname > b.lname ? 1 : -1
          } else if (orderBy === "lname" && order === "desc") {
            return a.lname < b.lname ? 1 : -1
          } else if (orderBy === "fname" && order === "asc") {
            return a.fname > b.fname ? 1 : -1
          } else if (orderBy === "fname" && order === "desc") {
            return a.fname < b.fname ? 1 : -1
          } else if (orderBy === "company" && order === "asc") {
            return a.company > b.company ? 1 : -1
          } else if (orderBy === "company" && order === "desc") {
            return a.company < b.company ? 1 : -1
          } else if (orderBy === "username" && order === "asc") {
            return a.username > b.username ? 1 : -1
          } else if (orderBy === "username" && order === "desc") {
            return a.username < b.username ? 1 : -1
          } else {
            return 1
          }
        })
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((user, index) => {
          const isItemSelected = isSelected(user.id)
          const labelId = `checkbox-${index}`

          return (
            <StyledRow
              container
              aria-checked={isItemSelected}
              key={user.id}
              onClick={() => navigate(`/admin/users/${user.id}`)}
              role="checkbox"
              selected={isItemSelected}
              tabIndex={-1}
            >
              <StyledCell item id={user.id} xs={8} sm={5} md={3}>
                <Checkbox
                  color="primary"
                  checked={isItemSelected}
                  disabled={user.role === "admin" && !isSuperAdmin}
                  id={labelId}
                  inputProps={{ "aria-labelledby": labelId }}
                  onClick={(e) => {
                    e.stopPropagation()
                    handleClick(e, user.id)
                  }}
                  padding="checkbox"
                />
                <StyledSpanTruncated>
                  {`${user.lname}, ${user.fname}`}
                </StyledSpanTruncated>
              </StyledCell>
              <StyledCell item xs={3} sm={2} md={1}>
                <StyledSpanTruncated>
                  {user.role.charAt(0).toUpperCase() + user.role.slice(1)}
                </StyledSpanTruncated>
              </StyledCell>
              <StyledCell item xs={1} sm={1} md={1} justifyContent="center">
                {user.status === "pending" && (
                  <PendingIcon
                    sx={{
                      color: "error.main",
                      fontSize: { xs: "1.25rem", md: "1.5rem" },
                    }}
                  />
                )}
                {user.status === "denied" && (
                  <CancelIcon
                    sx={{
                      color: "text.secondary",
                      fontSize: { xs: "1.25rem", md: "1.5rem" },
                    }}
                  />
                )}
                {(!user.status || user.status === "approved") && (
                  <CheckCircleIcon
                    sx={{
                      color: "success.light",
                      fontSize: { xs: "1.25rem", md: "1.5rem" },
                    }}
                  />
                )}
              </StyledCell>
              {isMdScreen && (
                <StyledCell item xs={3} md={3}>
                  <StyledSpanTruncated>{user.company}</StyledSpanTruncated>
                </StyledCell>
              )}
              {isSmScreen && (
                <StyledCell
                  item
                  xs={5}
                  sm={4}
                  md={4}
                  sx={{ color: { xs: "text.secondary", md: "text.primary" } }}
                >
                  <StyledSpanTruncated>{user.username}</StyledSpanTruncated>
                </StyledCell>
              )}
            </StyledRow>
          )
        })}
      {emptyRows > 0 && (
        <Grid item xs={12} style={{ height: 53 * emptyRows }}>
          <></>
        </Grid>
      )}
      <StyledTablePagination
        rowsPerPageOptions={[25, 50, 75]}
        component="div"
        count={users.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </StyledContainer>
  )
}

export default UsersTable
