import React, { useState } from "react"

import { Box, Link, Stack, Typography } from "@mui/material"
import PrivacyPolicyModal from "./PrivacyPolicyModal.component"

const Footer = () => {
  const [modalOpen, setModalOpen] = useState(false)
  const handleOpenModal = () => setModalOpen(true)
  const handleCloseModal = () => setModalOpen(false)

  return (
    <Box
      component="footer"
      display="flex"
      justifyContent={{ xs: "center", sm: "flex-end" }}
      mt={4}
      pb={2}
    >
      <Stack
        direction={{ xs: "column", sm: "row" }}
        spacing={1}
        color="text.secondary"
      >
        <Typography fontSize=".75rem">
          © {new Date().getFullYear()} Chenel Capital. All rights reserved.
        </Typography>
        <Typography
          fontSize=".75rem"
          sx={{ display: { xs: "none", sm: "block" } }}
        >
          |
        </Typography>
        <Typography fontSize=".75rem">
          <Link onClick={handleOpenModal} sx={{ "&:hover": { cursor: "pointer" } }}>Privacy Policy</Link>
          <PrivacyPolicyModal
            modalOpen={modalOpen}
            handleCloseModal={handleCloseModal}
          />
        </Typography>
      </Stack>
    </Box>
  )
}

export default Footer
