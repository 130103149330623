import React, { useState } from "react"
import { useSelector } from "react-redux"

import { styled } from "@mui/material/styles"
import { Box, Paper, Typography } from "@mui/material"

import { selectDocumentsList } from "../../../app/store/documentsSlice"

import PageTitle from "../../../common/sharedComponents/PageTitle.component"
import DocumentsTableToolbar from "./DocumentsTableToolbar.component"
import DocumentsTable from "./DocumentsTable.component"

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: '.5rem',
  width: '100%',
  [theme.breakpoints.up('md')]: {
    padding: '1rem',
  }
}))

const DocumentsPage = () => {
  const documents = useSelector(selectDocumentsList)
  const [search, setSearch] = useState("")
  
  const getVisibleDocuments = (allDocuments) => {
    return allDocuments.filter(document => {
      return document.id.toLowerCase().includes(search.toLowerCase()) ||
             document.filename.toLowerCase().includes(search.toLowerCase()) ||
             document.description.toLowerCase().includes(search.toLowerCase()) ||
             document.createdBy.toLowerCase().includes(search.toLowerCase())
    })
  }

  return (
    <Box>
      <PageTitle title="Documents" />
      <StyledPaper>
        <DocumentsTableToolbar search={search} setSearch={setSearch} />
          {documents.length > 0 ? (
            <DocumentsTable documents={getVisibleDocuments(documents)} />
          ) : (
            <Typography fontSize=".875rem" p={4}>
              No documents found
            </Typography>
          )}
      </StyledPaper>
    </Box>
  )
}

export default DocumentsPage
