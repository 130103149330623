import React, { useState, useEffect } from "react"
import { NavLink } from "react-router-dom"
import { useSelector } from "react-redux"

import { Badge, ListItem, ListItemIcon, ListItemText } from "@mui/material"
import { Mail as MailIcon } from "@mui/icons-material"

import { selectUserRole } from "../../app/store/authSlice"
import { selectCommunicationsList } from "../../app/store/communicationsSlice"

const NavDrawerCommunications = ({ to, handleDrawerToggle }) => {
  const communications = useSelector(selectCommunicationsList)
  const role = useSelector(selectUserRole)
  const [unreadComms, setUnreadComms] = useState(0)
  
  useEffect(() => {
    let unread = 0
    for (let i = 0; i < communications.length; i++) {
      if (role === 'admin' && !communications[i].adminRead) unread += 1
      else if (role === 'user' && !communications[i].read) unread += 1
    }
    setUnreadComms(unread)
  }, [communications, role])

  return (
    <ListItem
      component={NavLink}
      onClick={handleDrawerToggle}
      to={to}
    >
      <ListItemIcon>
      <Badge badgeContent={unreadComms} color="error">
        <MailIcon />
      </Badge>
      </ListItemIcon>
      <ListItemText primary="Communications" />
    </ListItem>
  )
}
 
export default NavDrawerCommunications
