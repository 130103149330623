import React, { useState, useEffect } from "react"
import { Box, Typography } from "@mui/material"

import { checkPasswordStrength } from "../utils/formValidation"

const PasswordStrengthMeter = ({ password }) => {
  const [passwordStrength, setPasswordStrength] = useState("")

  useEffect(() => {
    setPasswordStrength(password ? checkPasswordStrength(password) : "")
  }, [password])

  return (
    <Box display="flex" flexDirection="column">
      <Typography fontSize=".875rem">Password Strength</Typography>
      <Box
        borderRadius="3px"
        sx={{ backgroundColor: 'grey.300' }}
      >
        <Box
          borderRadius="3px"
          color="white"
          height="8px"
          sx={{
            transition: 'width 1s ease-in-out',
            width: '0%',
            ...(passwordStrength === "weak" && { backgroundColor: 'error.dark', width: '33%' }),
            ...(passwordStrength === "medium" && { backgroundColor: 'warning.dark', width: '67%' }),
            ...(passwordStrength === "strong" && { backgroundColor: 'success.dark', width: '100%' }),
          }}
        />
      </Box>
    </Box>
  )
}
 
export default PasswordStrengthMeter
