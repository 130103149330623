import React from "react"

import { AppBar, IconButton, Toolbar, Typography } from "@mui/material"
import MenuIcon from "@mui/icons-material/Menu"

import { drawerWidth } from "./NavDrawer.component"
import UserStack from "./UserStack.component"

const AppHeader = ({ handleDrawerToggle, adminRoute }) => {
  return (
    <AppBar
      color={adminRoute ? "secondary" : "primary" }
      position="fixed"
      sx={{
        width: { lg: `calc(100% - ${drawerWidth}px)` },
        ml: { lg: `${drawerWidth}px` },
      }}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { lg: 'none' } }}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" noWrap component="div">
          Investor Portal
        </Typography>
        <UserStack adminRoute={adminRoute} />
      </Toolbar>
    </AppBar>
  )
}

export default AppHeader
