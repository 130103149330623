import React, { useState } from "react"
import { useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import axios from "axios"

import { Box, LinearProgress, Paper } from "@mui/material"

import { setAlert } from "../../../app/store/alertsSlice"
import { selectGroupById, updateGroup } from "../../../app/store/groupsSlice"

import Breadcrumbs from "../../../common/sharedComponents/Breadcrumbs.component"
import PageTitle from "../../../common/sharedComponents/PageTitle.component"
import GroupForm from "./GroupForm.component"

const EditGroupPage = () => {
  const dispatch = useDispatch()
  const { groupId } = useParams()
  const group = useSelector(selectGroupById(groupId))

  const [saving, setSaving] = useState(false)

  const handleSaveGroup = (formValues) => {
    setSaving(true)

    const groupId = formValues.id
    const dbObj = {
      name: formValues.name,
      members: formValues.members
    }

    axios
      .post("/api/groups/update", {
        groupId,
        dbObj,
      })
      .then((res) => {
        // Update document in store
        dispatch(
          updateGroup({
            id: groupId,
            ...dbObj,
          })
        )

        // udpate is complete
        dispatch(
          setAlert({
            message: `Updates to group have been saved`,
            severity: "success",
          })
        )
        setSaving(false)
      })
      .catch((error) => {
        console.log("error writing to db", error.response.data)
        dispatch(
          setAlert({ message: "Error updating group", severity: "error" })
        )
        setSaving(false)
      })
  }

  return (
    <Box>
      <Breadcrumbs
        pages={[
          { title: "Admin", to: "/admin" },
          { title: "Groups", to: "/admin/groups" },
          { title: group ? group.name : "group" },
        ]}
      />
      <PageTitle title="Edit Group" />
      <Paper elevation={1}>
        <Box sx={{ pt: 2, px: 4, pb: 3 }}>
          {group && (
            <GroupForm group={group} handleSaveGroup={handleSaveGroup} />
          )}
        </Box>
        {saving && <LinearProgress />}
      </Paper>
    </Box>
  )
}

export default EditGroupPage
