import React from "react"
import { NavLink } from "react-router-dom"

import { styled } from "@mui/material/styles"
import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material"
import {
  Group as GroupIcon,
  Person as PersonIcon,
  PieChart as PieChartIcon,
  Settings as SettingsIcon,
  UploadFile as UploadFileIcon,
} from "@mui/icons-material"

import NavDrawerCommunications from "./NavDrawerCommunications.component"

const StyledList = styled(List)(({ theme }) => ({
  "& a, & a:hover, & a:visited": {
    color: theme.palette.common.white,
  },
  "& a:hover": {
    backgroundColor: "rgba(66, 66, 66, 0.25)",
  },
  "& .active": {
    backgroundColor: "rgba(66, 66, 66, 0.4)",
  },
}))

const NavDrawerAdmin = ({ handleDrawerToggle }) => {
  const NavItem = ({ to, primary, children }) => (
    <ListItem component={NavLink} onClick={handleDrawerToggle} to={to}>
      <ListItemIcon>{children}</ListItemIcon>
      <ListItemText primary={primary} />
    </ListItem>
  )

  return (
    <StyledList>
      <NavItem to="/admin/dashboard" primary="Dashboard">
        <SettingsIcon />
      </NavItem>
      <NavItem to="/admin/funds" primary="Portfolio">
        <PieChartIcon />
      </NavItem>
      <NavItem to="/admin/users" primary="Users">
        <PersonIcon />
      </NavItem>
      <NavItem to="/admin/groups" primary="Groups">
        <GroupIcon />
      </NavItem>
      <NavDrawerCommunications
        to="/admin/communications"
        handleDrawerToggle={handleDrawerToggle}
      />
      <NavItem to="/admin/documents" primary="Documents">
        <UploadFileIcon />
      </NavItem>
    </StyledList>
  )
}

export default NavDrawerAdmin
