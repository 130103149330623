import React, { useEffect } from "react"
import { Box, Typography } from "@mui/material"

const RegistrationStepIntro = ({ validateStep, setValidateStep, handleNext }) => {
  // Move on when "Next" is clicked--nothing to validate on this step
  useEffect(() => {
    if (validateStep === 0) {
      setValidateStep(null)
      handleNext()
    }
  }, [validateStep])
  
  return (
    <Box py={4}>
      <Typography pb={2}>
        Before we can create your account, we need to learn a little bit about you.
      </Typography>
      <Typography pb={2}>
        Please respond to the following question(s). When you're done with eachs step,
        hit "Next" and continue filling out the registration form. After you submit 
        your form, a member of the Chenel Capital invsetment team will review your 
        submission and get in touch with you asap. Once your account is approved, you 
        will be able to log in anytime.
      </Typography>
    </Box>
  )
}
 
export default RegistrationStepIntro
