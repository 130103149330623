import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { fetchAllCommunications, fetchCommunicationsByUser } from './communicationsFetch'

const initialState = {
  status: 'idle',
  list: []
}

export const getAllCommunicationsAsync = createAsyncThunk(
  'communications/fetchAllCommunications',
  async () => {
    const response = await fetchAllCommunications()
    return response
  }
)

export const getCommunicationsByUserAsync = createAsyncThunk(
  'communications/fetchCommunicationsByUser',
  async (uid) => {
    const response = await fetchCommunicationsByUser(uid)
    return response
  }
)

export const communicationsSlice = createSlice({
  name: 'communications',
  initialState,
  reducers: {
    setCommunications: (state, action) => ({
      ...state,
      list: action.payload
    }),
    addCommunication: (state, action) => ({
      ...state,
      list: [
        ...state.list,
        action.payload
      ]
    }),
    updateCommunication: (state, action) => ({
      ...state,
      list: state.list.map(communication => {
        if (communication.id === action.payload.id) {
          return {
            ...communication,
            ...action.payload
          }
        } else {
          return communication
        }
      })
    }),
    addReply: (state, action) => ({
      ...state,
      list: state.list.map(communication => {
        const { communicationId, replyId, ...replyObj } = action.payload
        if (communication.id === communicationId) {
          return {
            ...communication,
            replies: {
              ...communication.replies,
              [replyId]: replyObj
            }
          }
        } else {
          return communication
        }
      })
    }),
    removeCommunication: (state, action) => ({
      ...state,
      list: [
        ...state.list.filter(communication => communication.id !== action.payload)
      ]
    })
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllCommunicationsAsync.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(getAllCommunicationsAsync.fulfilled, (state, action) => ({
        status: 'idle',
        list: action.payload
      }))
      .addCase(getCommunicationsByUserAsync.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(getCommunicationsByUserAsync.fulfilled, (state, action) => ({
        status: 'idle',
        list: action.payload
      }))
  },
})

export const {
  setCommunications,
  addCommunication,
  updateCommunication,
  addReply,
  removeCommunication
} = communicationsSlice.actions

export const selectCommunicationsList = state => state.communications.list
export const selectCommunicationById = id => state => state.communications.list.filter(comm => comm.id === id)[0]

export default communicationsSlice.reducer
