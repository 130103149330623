import React from "react"
import { Outlet } from "react-router-dom"

import { styled } from "@mui/material/styles"
import { Box } from "@mui/material"

import AppFooter from "./AppFooter.component"

import { drawerWidth } from "./NavDrawer.component"
export const DrawerHeader = styled('div')(({ theme }) => ({
  minHeight: '48px',
  [theme.breakpoints.up('sm')]: {
    minHeight: '64px',
  }
}))

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    backgroundColor: theme.palette.grey[100],
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: `${theme.spacing(2)} ${theme.spacing(1)}`,
    width: { lg: `calc(100% - ${drawerWidth}px)` },
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
    },
  }),
)

const MainContent = () => {
  return (
    <Main>
      <DrawerHeader />
      <Box display={"flex"} flexDirection={"column"} flexGrow={"1"}>
        <Box flexGrow={"1"}>
          <Outlet />
        </Box>
        <AppFooter />
      </Box>
    </Main>
  )
}
 
export default MainContent
