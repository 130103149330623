import { configureStore } from "@reduxjs/toolkit"
import authReducer from "./authSlice"
import usersReducer from "./usersSlice"
import groupsReducer from "./groupsSlice"
import documentsReducer from "./documentsSlice"
import communicationsReducer from "./communicationsSlice"
import fundsReducer from "./fundsSlice"
import alertsReducer from "./alertsSlice"
import systemInfoReducer from "./systemInfoSlice"

export const store = configureStore({
  reducer: {
    auth: authReducer,
    users: usersReducer,
    groups: groupsReducer,
    documents: documentsReducer,
    communications: communicationsReducer,
    funds: fundsReducer,
    alerts: alertsReducer,
    systemInfo: systemInfoReducer
  },
})
