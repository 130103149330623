import React from "react"

import { Box, Grid } from "@mui/material"

import Breadcrumbs from "../../../common/sharedComponents/Breadcrumbs.component"
import CardTitle from "../../../common/sharedComponents/CardTitle.component"
import PendingUsers from "./PendingUsers.component"
import RecentlyActiveUsers from "./RecentlyActiveUsers.component"
import RecentCommunications from "./RecentCommunications.component"
import ManageSystemInfo from "./ManageSystemInfo.component"
import ManageInvestmentInfo from "./ManageInvestmentInfo.component"

const DashboardPage = () => {
  return (
    <Box>
      <Breadcrumbs
        pages={[
          { title: "Admin", to: "/" },
          { title: "Dashboard" },
        ]}
      />
      <CardTitle title="Users" />
      <Grid container columnSpacing={3} rowSpacing={1}>
        <Grid item display="flex" xs={12} md={6}>
          <PendingUsers />
        </Grid>
        <Grid item display="flex" xs={12} md={6}>
          <RecentlyActiveUsers />
        </Grid>
      </Grid>

      <Box sx={{ m: 4 }} />

      <CardTitle title="Messages" />
      <Grid container columnSpacing={3} rowSpacing={1}>
        <Grid item display="flex" xs={12}>
          <RecentCommunications />
        </Grid>
      </Grid>

      <Box sx={{ m: 4 }} />
      
      <Grid container columnSpacing={3} rowSpacing={1}>
        <Grid item xs={12} md={6}>
          <CardTitle title="System Alert" />
          <ManageSystemInfo />
        </Grid>
        <Grid item xs={12} md={6}>
          <CardTitle title="Investment Data" />
          <ManageInvestmentInfo />
        </Grid>
      </Grid>
    </Box>
  )
}
 
export default DashboardPage
