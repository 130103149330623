import React, { useState, useEffect } from "react"
import { useParams  } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import axios from "axios"

import { Box, LinearProgress, Paper, Tabs, Tab } from "@mui/material"

import { setAlert } from "../../../app/store/alertsSlice"
import { selectUserById, updateUser } from "../../../app/store/usersSlice"

import Breadcrumbs from "../../../common/sharedComponents/Breadcrumbs.component"
import PageTitle from "../../../common/sharedComponents/PageTitle.component"
import TabPanel, { a11yProps } from "../../../common/mui/TabPanel.component"
import UserForm from "./UserForm.component"
import UserAccreditationStatus from "./UserAccreditationStatus.component"
import PendingUserAlert from "./PendingUserAlert.component"
import UserInvestments from "./UserInvestments.component"

const EditUserPage = () => {
  const dispatch = useDispatch()
  const { userId } = useParams()
  const user = useSelector(selectUserById(userId))

  const [saving, setSaving] = useState(false)
  const [delayedAlert, setDelayedAlert] = useState(false)
  const [tabValue, setTabValue] = useState(0)

  useEffect(() => {
    if (delayedAlert) {
      const timer2 = setTimeout(() => {
        setDelayedAlert(false)
        setSaving(false)
        dispatch(setAlert(delayedAlert))
      }, 1000)
      return () => clearTimeout(timer2)
    }
  }, [delayedAlert, dispatch])

  const handleFormChange = (event, newValue) => setTabValue(newValue)
  
  const handleSaveUser = (formValues) => {
    setSaving(true)

    const userId = formValues.id
    const dbObj = {
      address: {
        city: formValues.city,
        country: formValues.country,
        street: formValues.street,
        state: formValues.state,
        zip: formValues.zip
      },
      company: formValues.company,
      fname: formValues.fname,
      lname: formValues.lname,
      phone: formValues.phone,
      role: formValues.role,
      superAdmin: formValues.superAdmin,
      username: formValues.username
    }

    // Update user in rtdb
    axios.post("/api/users/update", {
      userId,
      dbObj
    }).then(res => {
      // Update document in store
      dispatch(updateUser({
        id: userId,
        ...dbObj
      }))

      // udpate is complete
      setDelayedAlert({ message: `Updates to user ${dbObj.fname} ${dbObj.lname} have been saved`, severity: "success" })
    }).catch(error => {
      console.log("error writing to db", error.response.data)
      dispatch(setAlert({ message: "Error updating user", severity: "error" }))
      setSaving(false)
    })

  }

  return (
    <Box>
      <Breadcrumbs
        pages={[
          { title: "Admin", to: "/admin" },
          { title: "Users", to: "/admin/users" },
          { title: user ? `${user.fname} ${user.lname}` : "first last" },
        ]}
      />
      <PageTitle title="Edit User" />
      {user.status === "pending" && <PendingUserAlert user={user} />}
      <Paper elevation={1}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            aria-label="edit user tabs"
            onChange={handleFormChange} 
            value={tabValue}
            variant="fullWidth"
          >
            <Tab label="User Details" sx={{ fontSize: { xs: ".75rem", md: ".875rem" } }} {...a11yProps(0)} />
            <Tab label="Investment Info" sx={{ fontSize: { xs: ".75rem", md: ".875rem" } }} {...a11yProps(1)} />
            <Tab label="Accreditation Status" sx={{ fontSize: { xs: ".75rem", md: ".875rem" } }} {...a11yProps(2)} />
          </Tabs>
        </Box>
        <TabPanel value={tabValue} index={0}>
            {user && <UserForm user={user} handleSaveUser={handleSaveUser} /> }
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          { user && <UserInvestments user={user} /> }
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          { user && <UserAccreditationStatus user={user} /> }
        </TabPanel>
        {saving && <LinearProgress />}
      </Paper>
    </Box>
  )
}
 
export default EditUserPage
