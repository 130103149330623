import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import { Alert as MuiAlert, Snackbar } from "@mui/material"

import { selectAlertInfo, clearAlert } from "../../app/store/alertsSlice"

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" sx={{ width: '100%' }} {...props} />
})

const AlertStack = () => {
  const dispatch = useDispatch()
  const alertInfo = useSelector(selectAlertInfo)
  const [snackPack, setSnackPack] = useState([])
  const [open, setOpen] = useState(false)
  const [messageInfo, setMessageInfo] = useState(undefined)

  useEffect(() => {
    if (alertInfo.key !== null) {
      setOpen(true)
    }
  }, [alertInfo])

  useEffect(() => {
    if (snackPack.length && !messageInfo) {
      // Set a new snack when we don't have an active one
      setMessageInfo({ ...snackPack[0] })
      setSnackPack((prev) => prev.slice(1))
      setOpen(true)
    } else if (snackPack.length && messageInfo && open) {
      // Close an active snack when a new one is added
      setOpen(false)
    }
  }, [snackPack, messageInfo, open])

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpen(false)
    dispatch(clearAlert())
  }

  const handleExited = () => {
    setMessageInfo(undefined)
  }

  return (
    <Snackbar
      key={alertInfo ? alertInfo.key : undefined}
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      TransitionProps={{ onExited: handleExited }}
      sx={{ maxWidth: '600px', minWidth: { xs: 'auto', sm: '300px' } }}
    >
      <Alert
        onClose={handleClose}
        severity={alertInfo ? alertInfo.severity : "info"}
      >
        {alertInfo ? alertInfo.message : undefined}
      </Alert>
    </Snackbar>
  )
}

export default AlertStack
