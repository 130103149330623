import React from "react"

import { styled } from "@mui/material/styles"
import { Box } from "@mui/system"

import logo from "../../assets/images/chenel-capital-logo-light.png"

const StyledLogo = styled('img')(() => ({
  margin: '0 auto',
  maxWidth: '120px',
  width: '100%',
}))

const NavDrawerLogo = () => {
  return (
    <Box p={3} display="flex" justifyContent="center">
      <StyledLogo src={logo} alt="Chenel Capital" />
    </Box>
  )
}
 
export default NavDrawerLogo
