import React from "react"
import { useSelector } from "react-redux"

import { Box, Grid } from "@mui/material"
import Alerts from "./Alerts.component"
import InvestmentOverview from "./InvestmentOverview.component"
import PortfolioOverview from "./PortfolioOverview.component"
import RecentDocuments from "./RecentDocuments.component"
import RecentCommunications from "./RecentCommunications.component"

import { selectInvestmentInfo } from "../../../app/store/systemInfoSlice"

const DashboardPage = () => {
  const investment = useSelector(selectInvestmentInfo)

  return (
    <Box>
      <Alerts />
      {investment?.investedCapital && (
        <InvestmentOverview investment={investment} />
      )}
      <PortfolioOverview />
      <Grid container columnSpacing={4} rowSpacing={2}>
        <Grid item xs={12} md={6}>
          <RecentDocuments />
        </Grid>
        <Grid item xs={12} md={6}>
          <RecentCommunications />
        </Grid>
      </Grid>
    </Box>
  )
}

export default DashboardPage
