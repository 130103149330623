import React from "react"
import { Routes, Route, Navigate, useLocation } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { createBrowserHistory } from "history"
import { getAuth, signOut } from "firebase/auth"

import { selectUser, clearAuth } from "./store/authSlice"

import LoginLayout from "../common/sharedComponents/LoginLayout.component"
import LoginPage from "../features/login/LoginPage.component"
import RegisterPage from "../features/registration/RegisterPage.component"
import ForgotPasswordPage from "../features/login/ForgotPasswordPage.component"
import AccountManagementPage from "../features/login/AccountManagementPage.component"
import PageLayout from "../common/sharedComponents/PageLayout.component"
import AdminDashboardPage from "../features/admin/dashboard/DashboardPage.component"
import AdminUsersPage from "../features/admin/users/UsersPage.component"
import AdminEditUserPage from "../features/admin/users/EditUserPage.component"
import AdminGroupsPage from "../features/admin/groups/GroupsPage.component"
import AdminEditGroupPage from "../features/admin/groups/EditGroupPage.component"
import AdminDocumentsPage from "../features/admin/documents/DocumentsPage.component"
import AdminEditDocumentPage from "../features/admin/documents/EditDocumentPage.component"
import AdminFundsPage from "../features/admin/funds/FundsPage.component"
import AdminEditFundPage from "../features/admin/funds/EditFundPage.component"
import AdminCommunicationsPage from "../features/admin/communications/CommunicationsPage.component"
import DashboardPage from "../features/user/dashboard/DashboardPage.component"
import PortfolioPage from "../features/user/portfolio/PortfolioPage.component"
import FundPage from "../features/user/portfolio/FundPage.component"
import DocumentsPage from "../features/user/documents/DocumentsPage.component"
import CommunicationsPage from "../features/user/communications/CommunicationsPage.component"
import ProfilePage from "../features/user/profile/ProfilePage.component"
import InvestorRelationsPage from "../features/user/investorRelations/InvestorRelationsPage.component"

import NotFoundPage from "../features/NotFoundPage.component"
import PendingPage from "../features/login/PendingPage.component"
import NoAccessPage from "../features/login/NoAccessPage.component"

export const history = createBrowserHistory()

function RequireAnon({ children }) {
  const user = useSelector(selectUser)

  if (user.uid) {
    if (user.role === "admin") {
      return <Navigate to="/admin/dashboard" />
    } else if (user.role === "user") {
      return <Navigate to="/dashboard" />
    }
  }

  return children
}

function RequireAuth({ children }) {
  const auth = getAuth()
  const dispatch = useDispatch()
  const user = useSelector(selectUser)
  let location = useLocation()

  if (!user.uid) {
    // saves the intended location and passes it to the login component
    return <Navigate to="/login" state={{ from: location }} />
  } else if (!user.role) {
    console.log("undefined or unknown role")
    signOut(auth)
      .then(() => {
        dispatch(clearAuth())
      })
      .catch((error) => {
        console.log("error signing out", error)
      })
  } else if (user.status === "pending") {
    return <Navigate to="/pending" />
  } else if (user.status === "denied") {
    return <Navigate to="/no-access" />
  }

  return children
}

function RequireAdmin({ children }) {
  const user = useSelector(selectUser)
  let location = useLocation()

  if (!user.uid) {
    return <Navigate to="/login" state={{ from: location }} />
  } else if (user.role !== "admin") {
    return <Navigate to="/dashboard" />
  }

  return children
}

const AppRouter = () => {
  return (
    <Routes>
      <Route element={<LoginLayout />}>
        <Route path="/pending" element={<PendingPage />} />
        <Route path="/no-access" element={<NoAccessPage />} />
      </Route>
      <Route
        element={
          <RequireAnon>
            <LoginLayout />
          </RequireAnon>
        }
      >
        <Route path="/" element={<LoginPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/forgot-password" element={<ForgotPasswordPage />} />
        <Route path="/account-management" element={<AccountManagementPage />} />
      </Route>
      <Route
        element={
          <RequireAuth>
            <PageLayout adminRoute={false} />
          </RequireAuth>
        }
      >
        <Route path="/dashboard" element={<DashboardPage />} />
        <Route path="/portfolio" element={<PortfolioPage />} />
        <Route path="/portfolio/:fundKey" element={<FundPage />} />
        <Route path="/documents" element={<DocumentsPage />} />
        <Route path="/communications" element={<CommunicationsPage />} />
        <Route path="/profile" element={<ProfilePage />} />
        <Route path="/investor-relations" element={<InvestorRelationsPage />} />
      </Route>
      <Route
        element={
          <RequireAdmin>
            <PageLayout adminRoute={true} />
          </RequireAdmin>
        }
      >
        <Route path="/admin" element={<AdminDashboardPage />} />
        <Route path="/admin/dashboard" element={<AdminDashboardPage />} />
        <Route path="/admin/users" element={<AdminUsersPage />}></Route>
        <Route path="/admin/users/:userId" element={<AdminEditUserPage />} />
        <Route path="/admin/groups" element={<AdminGroupsPage />}></Route>
        <Route path="/admin/groups/:groupId" element={<AdminEditGroupPage />} />
        <Route path="/admin/documents" element={<AdminDocumentsPage />} />
        <Route
          path="/admin/documents/:documentId"
          element={<AdminEditDocumentPage />}
        />
        <Route path="/admin/funds" element={<AdminFundsPage />} />
        <Route path="/admin/funds/:fundId" element={<AdminEditFundPage />} />
        <Route
          path="/admin/communications"
          element={<AdminCommunicationsPage />}
        />
      </Route>
      <Route element={<LoginLayout />}>
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  )
}

export default AppRouter
