import React, { useState } from "react"
import { NavLink } from "react-router-dom"
import { useDispatch } from "react-redux"
import { getAuth, signOut } from "firebase/auth"

import { styled, alpha } from "@mui/material/styles"
import { Menu, MenuItem } from "@mui/material"
import {
  Logout as LogoutIcon,
  Password as PasswordIcon,
  Person as PersonIcon,
} from "@mui/icons-material"

import { clearAuth } from "../../app/store/authSlice"
import ChangePasswordModal from "./ChangePasswordModal.component"
import SwapHorizIcon from "@mui/icons-material/SwapHoriz"

const StyledMenu = styled((props) => (
  <Menu
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    "& .MuiMenuItem-root": {
      padding: ".5rem 1.5rem",
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(2),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}))

const UserMenu = ({ menuAnchor, handleCloseMenu, role, adminRoute }) => {
  const menuOpen = Boolean(menuAnchor)
  const auth = getAuth()
  const dispatch = useDispatch()

  const [modalOpen, setModalOpen] = useState(false)
  const handleOpenModal = () => setModalOpen(true)
  const handleCloseModal = () => setModalOpen(false)

  const handleOpenPasswordModal = () => {
    handleCloseMenu()
    handleOpenModal()
  }

  const handleSignOut = (e) => {
    e.preventDefault()

    signOut(auth)
      .then(() => {
        dispatch(clearAuth())
      })
      .catch((error) => {
        console.log("error signing out", error)
      })
  }

  return (
    <>
      <StyledMenu
        anchorEl={menuAnchor}
        id="user-menu"
        open={menuOpen}
        onClose={handleCloseMenu}
        MenuListProps={{
          "aria-labelledby": "user-menu-button",
        }}
      >
        {role === "admin" && (
          <MenuItem
            component={NavLink}
            onClick={handleCloseMenu}
            to={adminRoute ? "/dashboard" : "/admin/dashboard"}
          >
            <SwapHorizIcon />
            {`Switch to ${adminRoute ? "User" : "Admin"} View`}
          </MenuItem>
        )}
        {role === "admin" && (
          <MenuItem onClick={handleOpenPasswordModal}>
            <PasswordIcon />
            Change Password
          </MenuItem>
        )}
        {role === "user" && (
          <MenuItem component={NavLink} onClick={handleCloseMenu} to="/profile">
            <PersonIcon />
            Profile
          </MenuItem>
        )}
        <MenuItem component={NavLink} onClick={handleSignOut} to="/">
          <LogoutIcon />
          Logout
        </MenuItem>
      </StyledMenu>
      {role === "admin" && (
        <ChangePasswordModal
          modalOpen={modalOpen}
          handleCloseModal={handleCloseModal}
        />
      )}
    </>
  )
}

export default UserMenu
