import React from "react"
import { useSelector } from "react-redux"
import { DateTime } from "luxon"

import { Box, Card, Stack, Typography } from "@mui/material"

import RichTextDisplay from "./RichTextDisplay.component"
import { selectUser } from "../../app/store/authSlice"
import { selectUserById } from "../../app/store/usersSlice"

const Communication = ({ communication }) => {
  const user = useSelector(selectUser)
  const sender = useSelector(selectUserById(communication.sender))

  const getSenderName = () => {
    if (user.role === "user" && communication.sender === user.uid) {
      return `${user.lname}, ${user.fname}`
    }
    if (user.role === "user" && communication.sender !== user.uid) {
      return "Chenel Capital"
    }
    if (user.role === "admin") {
      return `${sender.lname}, ${sender.fname}`
    }
    return ""
  }

  const senderName = getSenderName()

  return (
    <Box component={Card} p={4} sx={{ borderTop: '8px solid', ...(user.role === "user" && communication.sender === user.uid ? { borderColor: 'primary.main' } : { borderColor: 'secondary.main' }) }}>
      <Typography component="h4" variant="h5">{communication.subject}</Typography>
      <Stack direction="row"  spacing={1}>
        <Typography component="span" color="text.secondary" fontSize=".875rem">
          From:
        </Typography>
        <Typography component="span" fontSize=".875rem">
          {senderName}
        </Typography>
      </Stack>
      <Stack direction="row" spacing={1}>
        <Typography component="span" color="text.secondary" fontSize=".875rem">
          Sent:
        </Typography>
        <Typography component="span" fontSize=".875rem">
          {DateTime.fromISO(communication.created).toLocaleString(DateTime.DATETIME_FULL)}
        </Typography>
      </Stack>
      <Box mt={2}>
        <RichTextDisplay
          defaultValue={communication.body}
          handleRichText={body => {}}
          readOnly={true}
        />
      </Box>
    </Box>
  )
}
 
export default Communication
