import React from "react"

import {
  Box,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material"

import PageTitle from "../../../common/sharedComponents/PageTitle.component"
import { NavLink } from "react-router-dom"

const yearToDisplay = 2022 // used to pull current values from investment object

const toUsd = (value) =>
  parseInt(value).toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  })

const MyInvestments = ({ funds, investments }) => {
  const myInvestments = []
  for (const [key, value] of Object.entries(investments)) {
    if (value.investedCapital > 0) {
      myInvestments.push({
        fundId: key,
        ...value,
      })
    }
  }

  return (
    <Box pb={4}>
      <PageTitle title="My Investments" />
      {myInvestments.length > 0 && (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Investment</TableCell>
                <TableCell align="right">Invested Capital</TableCell>
                <TableCell align="right">Unrealized Gains</TableCell>
                <TableCell align="right">Total Value</TableCell>
                <TableCell align="right">Gross MOIC</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {myInvestments.map((investment) => {
                const fund = funds.filter(
                  (fund) => fund.id === investment.fundId
                )[0]

                return (
                  <TableRow
                    key={investment.fundId}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <Link component={NavLink} to={fund.key}>
                        {fund.name}
                      </Link>
                    </TableCell>
                    <TableCell align="right">
                      {toUsd(investment.investedCapital)}
                    </TableCell>
                    <TableCell align="right">
                      {toUsd(investment.unrealizedGains[yearToDisplay])}
                    </TableCell>
                    <TableCell align="right">
                      {toUsd(investment.totalValue[yearToDisplay])}
                    </TableCell>
                    <TableCell align="right">
                      {investment.grossMoic[yearToDisplay] + "x"}
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  )
}

export default MyInvestments
