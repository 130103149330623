import React from "react"
import { useNavigate } from "react-router-dom"

import { styled } from "@mui/styles"
import { Button, ButtonBase, Card, Typography } from "@mui/material"

const StyledImageButton = styled(ButtonBase)(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'flex-start',
  width: '100% !important',
  '&:hover, &.Mui-focusVisible': {
    zIndex: 1,
    '& .MuiImageBackdrop-root': {
      opacity: 0.15,
    },
    '& .MuiImageMarked-root': {
      width: '100%'
    },
  },
}))

const StyledButtonImage = styled('div')(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  height: '75px',
  justifyContent: 'center',
  position: 'relative',
  width: '100% !important',
  [theme.breakpoints.up('sm')]: {
    height: '100px',
  },
  [theme.breakpoints.up('lg')]: {
    height: '150px',
  },
  [theme.breakpoints.up('xl')]: {
    height: '200px',
  },
}))

const StyledImageSrc = styled('span')({
  backgroundSize: 'cover',
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
})

const StyledImageBackdrop = styled('span')(({ theme }) => ({
  backgroundColor: theme.palette.common.black,
  opacity: 0.4,
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  transition: theme.transitions.create('opacity'),
}))

const StyledImageText = styled('div')(({ theme }) => ({
  alignItems: 'center',
  color: theme.palette.common.white,
  display: 'flex',
  flexDirection: 'column',
  fontFamily: theme.typography.fontFamily,
  fontSize: '1rem',
  position: 'relative',
  paddingTop: theme.spacing(2),
  paddingRight: theme.spacing(4),
  paddingBottom: `calc(${theme.spacing(1)} + 6px)`,
  paddingLeft: theme.spacing(4),
  [theme.breakpoints.up('md')]: {
    fontSize: '1.25rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '1.375rem',
  },
}))

const StyledImageUnderline = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  height: 3,
  marginTop: theme.spacing(2),
  transition: theme.transitions.create('width'),
  width: 18,
}))

const StyledBody = styled(Typography)(({ theme }) => ({
  display: "-webkit-box",
  fontSize: '.875rem',
  overflow: "hidden",
  padding: "1rem 1rem 0 1rem",
  textAlign: 'left',
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 10,
  [theme.breakpoints.up('xl')]: {
    fontSize: '1rem',
  },
}))

const PortfolioCard = ({ fund }) => {
  const navigate = useNavigate()

  return (
    <Card
      elevation={1}
      sx={{
        height: '100%',
        pb: 1,
      }}
    >
    <StyledImageButton
      focusRipple
      onClick={() => navigate(`/portfolio/${fund.key}`, { state: { fund } })}
    >
      <StyledButtonImage>
        <StyledImageSrc style={{ backgroundImage: `url(${fund.imageSm})` }} />
        <StyledImageBackdrop className="MuiImageBackdrop-root" />
        <StyledImageText>
          {fund.name}
          <StyledImageUnderline className="MuiImageMarked-root" />
        </StyledImageText>
      </StyledButtonImage>
      <StyledBody dangerouslySetInnerHTML={{ __html: fund.overview }} />
      <Button component="span" disableRipple sx={{ marginTop: 'auto' }}>Learn More</Button>
    </StyledImageButton>
    </Card>
  )
}
 
export default PortfolioCard
