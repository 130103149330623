import React from "react"
import PropTypes from "prop-types"
import { NavLink } from "react-router-dom"

import { Breadcrumbs as MuiBreadcrumbs, Link, Typography } from "@mui/material"
import NavigateNextIcon from "@mui/icons-material/NavigateNext"

function handleClick(e) {
  e.preventDefault()
}

const Breadcrumbs = ({ pages }) => {
  return (
    <div role="presentation" onClick={handleClick}>
      <MuiBreadcrumbs
        aria-label="breadcrumb"
        separator={<NavigateNextIcon fontSize="small" />}
        sx={{ fontSize: { xs: ".75rem", sm: ".875rem" }, marginBottom: 2 }}
      >
        {pages.map((page, i) =>
          page.to ? (
            <Link
              key={i}
              component={NavLink}
              to={page.to}
              underline="hover"
              sx={{
                color: "text.secondary",
                fontSize: { xs: ".75rem", sm: ".875rem" },
              }}
            >
              {page.title}
            </Link>
          ) : (
            <Typography
              key={i}
              component="span"
              sx={{
                color: "text.primary",
                fontSize: { xs: ".75rem", sm: ".875rem" },
              }}
            >
              {page.title}
            </Typography>
          )
        )}
      </MuiBreadcrumbs>
    </div>
  )
}

Breadcrumbs.propTypes = {
  pages: PropTypes.array.isRequired,
}

export default Breadcrumbs
