import React, { useState, useCallback } from "react"
import { useDropzone } from "react-dropzone"

import useMediaQuery from "@mui/material/useMediaQuery"
import { Box, Button, Typography } from "@mui/material"
import FileUploadIcon from "@mui/icons-material/FileUpload"
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile"

import CircularProgressWithLabel from "../../../common/mui/CircularProgressWithLabel.component"

const fileUploadStyle = {
  alignItems: 'center',
  bgcolor: 'grey.100',
  border: '1px solid',
  borderColor: 'grey.400',
  borderRadius: 2,
  display: 'flex',
  flexDirection: 'column',
  minHeight: '200px',
  justifyContent: 'center',
  p: 4,
  '&:hover': {
    borderColor: 'grey.800',
  }
}

const acceptedFileExtensions = ["pdf", "docx", "xlsx", "pptx", "mp3", "mp4"]
const acceptedFileTypes = [
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "text/csv",
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "audio/mpeg",
  "video/mp4"
]

const AddDocumentUpload = ({ file, setFile, uploading, percentUploaded }) => {
  const isSmScreen = useMediaQuery("(min-width: 600px)")
  const [uploadHelperText, setUploadHelperText] = useState("")

  const onDrop = useCallback(acceptedFiles => {
    if (acceptedFileTypes.includes(acceptedFiles[0].type)) {
      setFile(acceptedFiles[0])
      setUploadHelperText("")
    } else {
      setUploadHelperText(`Filetype not accepted. Accepted file types include: ${acceptedFileExtensions.join(", ")}`)
    }
    
  }, [setFile])
  
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, multiple: false })

  return (
    <Box mb={2}>
      <Box {...getRootProps()} sx={fileUploadStyle}>
        <input {...getInputProps()} />
        <Box display="flex" flexDirection="column" alignItems="center">
          {isDragActive ? (
            <Typography>Drop the file here</Typography>
          ) : (
            uploading ? (
              <>
                <Typography fontSize={'1rem'}>Uploading...</Typography>
                <CircularProgressWithLabel value={percentUploaded} />
              </>
            ) : (
              file ? (
                <Box alignItems="center" display="flex" flexDirection="column">
                  <InsertDriveFileIcon color="action" sx={{ fontSize: '3rem' }} />
                  <Typography fontSize={'.75rem'} py={2}>{file.name}</Typography>
                  <Button variant="contained">Replace File</Button>
                </Box>
              ) : (
                <>
                  <FileUploadIcon color="action" sx={{ fontSize: '3rem' }} />
                  {isSmScreen && <Typography py={2}>Select a file or drag here</Typography>}
                  <Button variant="contained">Select a File</Button>
                </>
              )
            )
          )}
        </Box>
      </Box>
      {uploadHelperText && <Typography color="error" fontSize=".75rem">{uploadHelperText}</Typography>}
    </Box>
  )
}
 
export default AddDocumentUpload
