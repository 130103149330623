import React from "react"
import { NavLink } from "react-router-dom"

import { styled } from "@mui/material/styles"
import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material"
import {
  Contacts as ContactsIcon,
  Home as HomeIcon,
  PieChart as PieChartIcon,
} from "@mui/icons-material"

import NavDrawerCommunications from "./NavDrawerCommunications.component"
import NavDrawerDocuments from "./NavDrawerDocuments.component"

const StyledList = styled(List)(({ theme }) => ({
  "& a, & a:hover, & a:visited": {
    color: theme.palette.common.white,
  },
  "& a:hover": {
    backgroundColor: "rgba(66, 66, 66, 0.25)",
  },
  "& .active": {
    backgroundColor: "rgba(66, 66, 66, 0.4)",
  },
}))

const NavDrawerUser = ({ handleDrawerToggle }) => {
  const NavItem = ({ to, primary, children }) => (
    <ListItem component={NavLink} onClick={handleDrawerToggle} to={to}>
      <ListItemIcon>{children}</ListItemIcon>
      <ListItemText primary={primary} />
    </ListItem>
  )

  return (
    <StyledList>
      <NavItem to="/dashboard" primary="Dashboard">
        <HomeIcon />
      </NavItem>
      <NavItem to="/portfolio" primary="My Portfolio">
        <PieChartIcon />
      </NavItem>
      <NavItem to="/investor-relations" primary="Investor Relations">
        <ContactsIcon />
      </NavItem>
      <NavDrawerCommunications
        to="/communications"
        handleDrawerToggle={handleDrawerToggle}
      />
      <NavDrawerDocuments
        to="/documents"
        handleDrawerToggle={handleDrawerToggle}
      />
    </StyledList>
  )
}

export default NavDrawerUser
