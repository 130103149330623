import React from "react"
import PropTypes from "prop-types"

import useMediaQuery from "@mui/material/useMediaQuery"
import { styled } from "@mui/material/styles"
import { Box, Grid, TableSortLabel } from "@mui/material"
import { visuallyHidden } from "@mui/utils"

const StyledCell = styled(Grid)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  fontSize: '.875rem',
  fontWeight: '500',
  padding: '.5rem .5rem 0',
  [theme.breakpoints.up('sm')]: {
    padding: '0 .25rem',
  },
}))

const DocumentsTableHead = ({ order, orderBy, onRequestSort }) => {
  const isSmScreen = useMediaQuery("(min-width: 600px)")

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  return (
    <>
      <StyledCell item xs={6} sm={5}>
        <TableSortLabel
          active={orderBy === "description"}
          direction={orderBy === "description" ? order : "asc"}
          onClick={createSortHandler("description")}
        >
          File
          {orderBy === "description" ? (
            <Box component="span" sx={visuallyHidden}>
              {order === "desc" ? "sorted descending" : "sorted ascending"}
            </Box>
          ) : null}
        </TableSortLabel>
      </StyledCell>
      {isSmScreen && (
        <StyledCell item sm={2}>
          Type
        </StyledCell>
      )}
      <StyledCell item xs={4} sm={3}>
        <TableSortLabel
          active={orderBy === "created"}
          direction={orderBy === "created" ? order : "asc"}
          onClick={createSortHandler("created")}
        >
          Date
          {orderBy === "created" ? (
            <Box component="span" sx={visuallyHidden}>
              {order === "desc" ? "sorted descending" : "sorted ascending"}
            </Box>
          ) : null}
        </TableSortLabel>
      </StyledCell>
      <StyledCell item xs={2} sm={2} sx={{ justifyContent: 'flex-end' }}>
        Actions
      </StyledCell>
    </>
  )
}

DocumentsTableHead.propTypes = {
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  onRequestSort: PropTypes.func.isRequired,
}

export default DocumentsTableHead
