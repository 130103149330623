import React, { useState } from "react"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import axios from "axios"
import { nanoid } from "nanoid"
import { DateTime } from "luxon"

import {
  Box,
  IconButton,
  LinearProgress,
  Modal,
  Typography,
} from "@mui/material"
import { Close as CloseIcon } from "@mui/icons-material"

import { setAlert } from "../../app/store/alertsSlice"
import { addCommunication } from "../../app/store/communicationsSlice"
import { selectUsersList } from "../../app/store/usersSlice"

import ComposeForm from "./ComposeForm.component"

const outerStyle = {
  bgcolor: "background.paper",
  border: "1px solid #666",
  borderRadius: "5px",
  boxShadow: 24,
  left: "50%",
  maxHeight: "90vh",
  maxWidth: "800px",
  overflow: "auto",
  position: "absolute",
  top: "50%",
  transform: "translate(-50%, -50%)",
  width: "98%",
}

const innerStyle = {
  px: 3,
  py: 2,
}

const ComposeCommunicationModal = ({
  modalOpen,
  handleCloseModal,
  senderRole,
  subject,
  stringifiedDefaultBody,
}) => {
  const dispatch = useDispatch()
  const userList = useSelector(selectUsersList)
  const [saving, setSaving] = useState(false)

  const handleComposeCommunication = (formValues, recipientIds, sendEmail) => {
    setSaving(true)

    recipientIds.forEach((recipientId) => {
      const communicationId = nanoid()
      const dbObj = {
        ...formValues,
        adminRead: senderRole === "admin" ? true : false,
        created: DateTime.now().setZone("America/New_York").toISO(),
        recipientId,
      }
      // Add communication to rtdb
      axios
        .post("/api/communications/add", {
          communicationId,
          dbObj,
        })
        .then(() => {
          // send email notification to user(s)
          if (senderRole === "admin" && sendEmail) {
            const recipient = userList.filter(
              (user) => user.id === recipientId
            )[0]
            if (recipient) {
              const emailOptions = {
                from: "Chenel Capital <noreply@mg.chenelcapital.com>",
                to: recipient.username,
                subject: "New message from Chenel Capital",
                template: "new-message",
                mgVariables: {
                  "v:description": formValues.subject,
                  "v:unsubURL":
                    "https://investors.chenelcapital.com/unsubscribe/abc123",
                },
              }
              axios
                .post("/api/mail/send", emailOptions)
                .catch((error) => {
                  console.log("error sending email", error.message)
                  dispatch(
                    setAlert({
                      message: "Error sending email(s)",
                      severity: "warning",
                    })
                  )
                })
            }
          } else if (senderRole === "user") {
            const emailOptions = {
              from: "Chenel Capital <noreply@mg.chenelcapital.com>",
              to: "admin@chenelcapital.com",
              subject: "New message from a Chenel Capital investor",
              template: "new-message",
              mgVariables: {
                "v:description": formValues.subject,
                "v:unsubURL":
                  "https://investors.chenelcapital.com/unsubscribe/abc123",
              },
            }
            axios.post("/api/mail/send", emailOptions).catch((error) => {
              console.log("error sending email", error.message)
              dispatch(
                setAlert({
                  message: "Error sending email(s)",
                  severity: "warning",
                })
              )
            })
          }

          // Add new communication to store
          dispatch(
            addCommunication({
              id: communicationId,
              read: true,
              ...dbObj,
            })
          )

          handleCloseModal()
          dispatch(
            setAlert({ message: `Communication sent`, severity: "success" })
          )
        })
        .catch((error) => {
          console.log("error sending communication: ", error.message)
          dispatch(
            setAlert({
              message: "error sending communication: ",
              severity: "error",
            })
          )
        })
    })
    setSaving(false)
  }

  return (
    <Modal
      open={modalOpen}
      onClose={handleCloseModal}
      aria-labelledby="modal-title"
    >
      <Box sx={outerStyle}>
        <Box sx={innerStyle}>
          <Box
            alignItems="center"
            display="flex"
            justifyContent="space-between"
          >
            <Typography component="h2" variant="h6" id="modal-title">
              Compose a New Message
            </Typography>
            <IconButton onClick={handleCloseModal}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box sx={{ pt: 3 }}>
            <ComposeForm
              handleComposeCommunication={handleComposeCommunication}
              senderRole={senderRole}
              subject={subject}
              stringifiedDefaultBody={stringifiedDefaultBody}
            />
          </Box>
        </Box>
        {saving && <LinearProgress />}
      </Box>
    </Modal>
  )
}

ComposeCommunicationModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  senderRole: PropTypes.string.isRequired,
}

ComposeCommunicationModal.defaultProps = {
  senderRole: "user",
  subject: "",
  stringifiedDefaultBody: ""
}

export default ComposeCommunicationModal
