import React from "react"
import ReactDOM from "react-dom"
import { BrowserRouter } from "react-router-dom"
import { Provider } from "react-redux"
import { getAuth } from "firebase/auth"

import "./app/firebase"
import "./style/index.css"
import { store } from "./app/store/store"
import { startSetAuth } from "./app/store/authSlice"
import { getAllUsersAsync } from "./app/store/usersSlice"
import { getAllGroupsAsync } from "./app/store/groupsSlice"
import {
  getAllDocumentsAsync,
  getDocumentsByUserAsync,
} from "./app/store/documentsSlice"
import {
  getAllCommunicationsAsync,
  getCommunicationsByUserAsync,
} from "./app/store/communicationsSlice"
import { getAllFundsAsync } from "./app/store/fundsSlice"

import * as serviceWorker from "./serviceWorker"

import CustomizedTheme from "./app/CustomizedTheme"
import AppRouter from "./app/AppRouter"
import LoadingPage from "./features/loadingPage/LoadingPage"
import { getSystemInfoAsync } from "./app/store/systemInfoSlice"
import Analytics from "./app/Analytics.component"

const auth = getAuth()

const jsx = (
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <Analytics />
        <CustomizedTheme>
          <AppRouter />
        </CustomizedTheme>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
)

let hasRendered = false
const renderApp = () => {
  if (!hasRendered) {
    ReactDOM.render(jsx, document.getElementById("root"))
    hasRendered = true
  }
}

ReactDOM.render(<LoadingPage />, document.getElementById("root"))

auth.onAuthStateChanged((user) => {
  if (user) {
    store.dispatch(startSetAuth(user)).then(() => {
      store.dispatch(getSystemInfoAsync()).then(() => {
        store.dispatch(getAllFundsAsync()).then(() => {
          const role = store.getState().auth.role
          const status = store.getState().auth.status
          if (role === "admin") {
            store.dispatch(getAllUsersAsync()).then(() => {
              store.dispatch(getAllGroupsAsync()).then(() => {
                store.dispatch(getAllDocumentsAsync()).then(() => {
                  store.dispatch(getAllCommunicationsAsync()).then(() => {
                    renderApp()
                  })
                })
              })
            })
          } else if (
            role === "user" &&
            status !== "pending" &&
            status !== "denied"
          ) {
            store.dispatch(getDocumentsByUserAsync(user.uid)).then(() => {
              store.dispatch(getCommunicationsByUserAsync(user.uid)).then(() => {
                renderApp()
              })
            })
          } else {
            renderApp()
          }
        })
      })
    })
  } else {
    renderApp()
  }
})

serviceWorker.unregister()
