import React from "react"
import { Typography } from "@mui/material"

const PageTitle = ({ title }) => (
  <Typography
    component="h2"
    variant="h4"
    sx={{
      marginBottom: { xs: "1rem", md: "1.25rem", lg: "1.5rem" },
      fontSize: { xs: "1.25rem", sm: "1.5rem", md: "1.75rem", lg: "2.125rem" },
    }}
  >
    {title}
  </Typography>
)

export default PageTitle
