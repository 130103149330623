import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { DateTime } from "luxon"
import axios from "axios"

import useMediaQuery from "@mui/material/useMediaQuery"
import { styled } from "@mui/material/styles"
import { Grid, Link } from "@mui/material"

import { selectUser } from "../../../app/store/authSlice"
import { updateDocument } from "../../../app/store/documentsSlice"

import ForwardLinkButton from "./ForwardLinkButton.component"

const StyledRow = styled(Grid)(({ theme }) => ({
  borderBottom: '1px solid ' + theme.palette.grey[300],
  overflow: 'hidden',
}))

const StyledCell = styled(Grid)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  padding: '.875rem .5rem',
  width: '200px', // prevents cell from expanding to fit text
  [theme.breakpoints.up('sm')]: {
    padding: '1rem .5rem',
  },
}))

const StyledSpanTruncated = styled('span')(() => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}))

const DocumentsRow = ({ document }) => {
  const isSmScreen = useMediaQuery("(min-width: 600px)")
  const dispatch = useDispatch()
  const user = useSelector(selectUser)

  const handleDocumentClick = () => {
    // mark document as read
    if (!document.read) {
      axios.post("/api/documents/mark-read-user", {
        userId: user.uid,
        documentId: document.id
      }).then(() => {
        dispatch(updateDocument({
          id: document.id,
          read: true
        }))
      }).catch(error => {
        console.log("error marking document as read")
      })
    }
  }

  return (
    <StyledRow
      container
      fontWeight={!document.read ? "600" : "400"}
    >
      <StyledCell item xs={6} sm={5}>
        <StyledSpanTruncated>
          <Link
            href={document.downloadUrl}
            onMouseDown={handleDocumentClick}
            target="_blank"
            rel="noreferrer">
            {document.description}
          </Link>
        </StyledSpanTruncated>
      </StyledCell>
      {isSmScreen && (
        <StyledCell item sm={2}>
          {/* {row.filetype} */}
          PDF
        </StyledCell>
      )}
      <StyledCell item xs={4} sm={3}>
        <StyledSpanTruncated>
          {document.created && (
            isSmScreen ? (
              DateTime.fromISO(document.created).toFormat('LL/dd/yyyy')
            ) : (
              DateTime.fromISO(document.created).toFormat('L/d/yy')
            )
          )}
        </StyledSpanTruncated>
      </StyledCell>
      <StyledCell item xs={2} sm={2} sx={{ justifyContent: 'flex-end' }}>
        <ForwardLinkButton downloadUrl={document.downloadUrl} />
      </StyledCell>
    </StyledRow>
  )
}
 
export default DocumentsRow
