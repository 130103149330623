import { createTheme, ThemeProvider } from '@mui/material/styles'

const theme = createTheme({
  typography: {
    "fontFamily": `"Open Sans", "Helvetica", "Arial", sans-serif`,
   },
  palette: {
    primary: {
      main: '#165ecc',
      light: '#628bff',
      dark: '#00369a',
      contrastText: '#fff',
    },
    secondary: {
      main: '#ccba16',
      light: '#d6c744',
      dark: '#8e820f',
      contrastText: '#121212',
    },
    tertiary: {
      main: '#cc163a',
      light: '#d64461',
      dark: '#8e0f28',
      contrastText: '#fff',
    }
  }
})

const CustomizedTheme = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  )
}
 
export default CustomizedTheme
