import React, { useState } from "react"

import { Button } from "@mui/material"

import ComposeCommunicationModal from "../../../common/sharedComponents/ComposeCommunicationModal.component"

const FundInquiry = ({ fundName }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const handleOpenModal = () => setModalOpen(true)
  const handleCloseModal = () => setModalOpen(false)

  const stringifiedDefaultBody =
    `{"blocks":[{"key":"b76h6","text":"I would like to speak with a Chenel Capital representative about the ${fundName} fund.","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":7,"style":"MY-STYLE"}],"entityRanges":[],"data":{}}],"entityMap":{}}`

  return (
    <>
      <Button onClick={handleOpenModal} size="large" variant="contained">
        Inquire About the Fund
      </Button>
      <ComposeCommunicationModal
        modalOpen={modalOpen}
        handleCloseModal={handleCloseModal}
        senderRole="user"
        subject={`${fundName} Inquiry`}
        stringifiedDefaultBody={stringifiedDefaultBody}
      />
    </>
  )
}

export default FundInquiry
