import React from "react"
import MUIRichTextEditor from "mui-rte"
import { convertToRaw } from "draft-js"

import useMediaQuery from "@mui/material/useMediaQuery"
import { createTheme, ThemeProvider, } from "@mui/material/styles"

const defaultTheme = createTheme()

const richTextTheme = createTheme({
  overrides: {
    MUIRichTextEditor: {
      root: {
        backgroundColor: defaultTheme.palette.background.paper,
        border: '1px solid',
        borderColor: defaultTheme.palette.grey[300],
        width: '100%',
      },
      container: {
        fontSize: '.875rem',
        marginTop: 0,
        position: 'relative',
      },
      editor: {
        height: '20vh',
        overflowY: 'auto',
        padding: '.5rem 1rem',
        '& figure': {
          margin: 0,
        },
        '& ul': {
          listStyleType: 'disc',
          margin: '1rem 0 1rem 1.5rem',
        },
        '& ol': {
          listStyleType: 'numeric',
          margin: '1rem 0 1rem 1.5rem',
        },
      },
      placeHolder: {
        color: defaultTheme.palette.text.secondary,
        fontSize: '.875rem',
        height: '25vh',
        padding: '.5rem 1rem',
        position: 'absolute',
        fontStyle: 'italic',
      },
      toolbar: {
        borderBottom: '1px solid',
        borderBottomColor: defaultTheme.palette.grey[300]
      },
      blockQuote: {
        color: 'red',
      }
    }
  }
})

const RichTextEditor = ({ controls, defaultValue, label, handleRichText }) => {
  const isLarge = useMediaQuery("(min-width: 1200px)")
  const full = ["title", "bold", "italic", "underline", "strikethrough", "undo", "redo", "link", "media", "numberList", "bulletList", "quote", "clear"]
  const partial = ["bold", "italic", "underline", "link", "media", "numberList", "bulletList", "clear"]
  const handleChange = state => {
    // setSaved(false)
    handleRichText(convertToRaw(state.getCurrentContent()))
  }

  const handleSave = data => {
    console.log('saved data', data)
  }

  return (
    <ThemeProvider theme={richTextTheme}>
      <MUIRichTextEditor 
        controls={controls === "full" ? full : partial}
        defaultValue={defaultValue}
        label={label}
        onChange={handleChange}
        onSave={handleSave}
        toolbarButtonSize={isLarge ? "medium" : "small"}
      />
    </ThemeProvider>
  )
}

RichTextEditor.defaultProps = {
  controls: "full",
  defaultValue: "",
  label: "Start typing...",
  toolbarButtonSize: "medium",
}
 
export default RichTextEditor
