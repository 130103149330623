import React from "react"
import PropTypes from "prop-types"

import useMediaQuery from "@mui/material/useMediaQuery"
import { styled } from "@mui/material/styles"
import { visuallyHidden } from "@mui/utils"
import { Box, Checkbox, Grid, TableSortLabel } from "@mui/material"

const StyledCell = styled(Grid)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  fontSize: '.75rem',
  fontWeight: '500',
  padding: '.5rem .5rem 0',
  [theme.breakpoints.up('sm')]: {
    padding: '0 .25rem',
  },
}))

const UsersTableHead = (props) => {
  const isSmScreen = useMediaQuery("(min-width: 600px)")
  const isMdScreen = useMediaQuery("(min-width: 900px)")
  
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props
  const createSortHandler = property => e => {
    onRequestSort(e, property)
  }

  return (
    <>
      <StyledCell item xs={8} sm={5} md={3}>
        <Checkbox
          color="primary"
          indeterminate={numSelected > 0 && numSelected < rowCount}
          checked={rowCount > 0 && numSelected === rowCount}
          onChange={onSelectAllClick}
          inputProps={{
            'aria-label': 'select all users',
          }}
        />
        <TableSortLabel
          active={orderBy === "lname"}
          direction={orderBy === "lname" ? order : "asc"}
          onClick={createSortHandler("lname")}
        >
          Name
          {orderBy === "lname" ? (
            <Box component="span" sx={visuallyHidden}>
              {order === "desc" ? "sorted descending" : "sorted ascending"}
            </Box>
          ) : null}
        </TableSortLabel>
      </StyledCell>
      <StyledCell item xs={3} sm={2} md={1}>
        Role
      </StyledCell>
      <StyledCell item xs={1} sm={1} md={1} justifyContent="center">
        {isSmScreen && 'Status'}
      </StyledCell>
      {isMdScreen && (
        <StyledCell item md={3}>
          <TableSortLabel
            active={orderBy === "company"}
            direction={orderBy === "company" ? order : "asc"}
            onClick={createSortHandler("company")}
          >
            Company
            {orderBy === "company" ? (
              <Box component="span" sx={visuallyHidden}>
                {order === "desc" ? "sorted descending" : "sorted ascending"}
              </Box>
            ) : null}
          </TableSortLabel>
        </StyledCell>
      )}
      {isSmScreen && (
        <StyledCell item xs={5} sm={4} md={4}>
          <TableSortLabel
            active={orderBy === "email"}
            direction={orderBy === "email" ? order : "asc"}
            onClick={createSortHandler("email")}
          >
            Email
            {orderBy === "email" ? (
              <Box component="span" sx={visuallyHidden}>
                {order === "desc" ? "sorted descending" : "sorted ascending"}
              </Box>
            ) : null}
          </TableSortLabel>
        </StyledCell>
      )}
    </>
  )
}

UsersTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
}

export default UsersTableHead
