import { getDatabase, ref, get, set } from "firebase/database"

const database = getDatabase()

export const fetchUser = uid => {
  return new Promise(resolve => {
    get(ref(database, "users/" + uid)).then((snapshot) => {
      resolve(snapshot.val())
    }).catch(error => {
      console.error(error)
    })
  })
}

export const setLastActive = (uid, lastActive) => {
  set(ref(database, "users/" + uid + "/lastActive"), lastActive).catch(error => {
    console.error(error)
  })
}
