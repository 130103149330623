import React, { useState } from "react"
import { useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import axios from "axios"

import { Box, LinearProgress, Paper } from "@mui/material"

import { setAlert } from "../../../app/store/alertsSlice"
import { selectFundById, updateFund } from "../../../app/store/fundsSlice"

import Breadcrumbs from "../../../common/sharedComponents/Breadcrumbs.component"
import PageTitle from "../../../common/sharedComponents/PageTitle.component"
import FundForm from "./FundForm.component"

const EditFundPage = () => {
  const dispatch = useDispatch()
  const { fundId } = useParams()
  const fund = useSelector(selectFundById(fundId))

  const [saving, setSaving] = useState(false)

  const handleSaveFund = (formValues) => {
    setSaving(true)

    const { id, ...dbObj } = formValues

    axios
      .post("/api/funds/update", {
        fundId,
        dbObj,
      })
      .then(() => {
        // Update document in store
        dispatch(
          updateFund({
            id: fundId,
            ...dbObj,
          })
        )

        // udpate is complete
        dispatch(
          setAlert({
            message: `Updates to fund have been saved`,
            severity: "success",
          })
        )
        setSaving(false)
      })
      .catch((error) => {
        console.log("error writing to db", error.response.data)
        dispatch(
          setAlert({ message: "Error updating fund", severity: "error" })
        )
        setSaving(false)
      })
  }

  return (
    <Box>
      <Breadcrumbs
        pages={[
          { title: "Admin", to: "/admin" },
          { title: "Funds", to: "/admin/funds" },
          { title: fund ? fund.name : "fund" },
        ]}
      />
      <PageTitle title="Edit Fund" />
      <Paper elevation={1}>
        <Box sx={{ pt: 2, px: 4, pb: 3 }}>
          {fund && (
            <FundForm fund={fund} handleSaveFund={handleSaveFund} />
          )}
        </Box>
        {saving && <LinearProgress />}
      </Paper>
    </Box>
  )
}

export default EditFundPage
