import React from "react"
import { Typography } from "@mui/material"

const CardTitle = ({ title }) => (
  <Typography
    variant="h5"
    component="h3"
    sx={{
      marginBottom: { xs: "1rem", lg: "1.5rem" },
      fontSize: { xs: "1rem", sm: "1.125rem", md: "1.25rem", lg: "1.5rem " },
    }}
  >
    {title}
  </Typography>
)

export default CardTitle
