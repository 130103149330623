import { getDatabase, ref, get } from "firebase/database"
import axios from "axios"

const database = getDatabase()

export const fetchAllDocuments = () => {
  return new Promise(resolve => {

    axios.get("/api/documents/all").then(res => {
      const data = res.data
      const dataArray = []
      for (const [key, value] of Object.entries(data)) {
        dataArray.push({
          id: key,
          assigned: value.permissions ? Object.keys(value.permissions).length : 0,
          ...value
        })
      }
      resolve(dataArray)
    }).catch(error => {
      console.log(error.response.data)
      resolve([])
    })

  })
}

export const fetchDocumentsByUser = (uid) => {
  return new Promise(resolve => {
    get(ref(database, "userDocuments/" + uid)).then(async (userDocsSnapshot) => {
      const documentArray = []
      if (userDocsSnapshot.exists()) {
        const documentIdArray = Object.keys(userDocsSnapshot.val())

        await Promise.all(documentIdArray.map(async (documentId) => {
          await get(ref(database, "documents/" + documentId)).then((docsSnapshot) => {
            if (docsSnapshot.exists()) {
              const data = docsSnapshot.val()
              documentArray.push({
                id: documentId,
                read: userDocsSnapshot.val()[documentId].read,
                assigned: data.permissions ? Object.keys(data.permissions).length : 0,
                ...data
              })
            }
          }).catch(error => {
            console.error(error)
          })
        }))
      }
      resolve(documentArray)
    }).catch(error => {
      console.error(error)
      resolve([])
    })
  })
}
