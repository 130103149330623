import React from "react"

import {
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput
} from "@mui/material"
import CancelIcon from "@mui/icons-material/Cancel"

const DocumentsTableToolbar = ({ search, setSearch }) => (
  <Box sx={{ my: 2, px: { xs: 0, md: 1 } }}>
    <FormControl sx={{ width: '100%' }} variant="outlined">
      <InputLabel htmlFor="outlined-adornment-search">Search Documents</InputLabel>
      <OutlinedInput
        id="outlined-adornment-search"
        value={search}
        onChange={e => setSearch(e.target.value)}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              disabled={!search}
              edge="end"
              onClick={() => setSearch("")}
            >
              <CancelIcon color={search ? "error" : ""} />
            </IconButton>
          </InputAdornment>
        }
        label="Search Documents"
      />
    </FormControl>
  </Box>
)

export default DocumentsTableToolbar
