import React, { useState } from "react"

import {
  Box,
  Button,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material"

const isValidNumericValue = (value) => {
  const regex = new RegExp(/^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/)
  return regex.test(value) || value === ""
}

const CompanyInvestmentForm = ({ investment, handleSaveData }) => {
  const [investedCapital, setInvestedCapital] = useState(
    investment?.investedCapital ? investment.investedCapital : ""
  )
  const [unrealizedGains, setUnrealizedGains] = useState(
    investment?.unrealizedGains
      ? investment.unrealizedGains
      : {
          2020: "",
          2021: "",
          2022: "",
        }
  )
  const [totalValue, setTotalValue] = useState(
    investment?.totalValue
      ? investment.totalValue
      : {
          2020: "",
          2021: "",
          2022: "",
        }
  )
  const [grossMoic, setGrossMoic] = useState(
    investment?.grossMoic
      ? investment.grossMoic
      : {
          2020: "",
          2021: "",
          2022: "",
        }
  )

  const onInvestedCapitalChange = (e) => {
    if (isValidNumericValue(e.target.value)) setInvestedCapital(e.target.value)
  }
  const onUnrealizedGainsChange = (property, value) => {
    if (isValidNumericValue(value))
      setUnrealizedGains((prevState) => ({ ...prevState, [property]: value }))
  }
  const onTotalValueChange = (property, value) => {
    if (isValidNumericValue(value))
    setTotalValue((prevState) => ({ ...prevState, [property]: value }))
  }
  const onGrossMoicChange = (property, value) => {
    if (isValidNumericValue(value))
      setGrossMoic((prevState) => ({ ...prevState, [property]: value }))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    handleSaveData({
      investedCapital,
      unrealizedGains,
      totalValue,
      grossMoic
    })
  }

  return (
    <Box mt={2}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} xl={6}>
            <TextField
              fullWidth
              id="investedCapital"
              label="Invested Capital"
              inputProps={{ style: { textAlign: "right" } }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              onChange={onInvestedCapitalChange}
              size="small"
              value={investedCapital}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container columns={17} spacing={1}>
              <Grid item xs={17} sm={2} sx={{ alignItems: "center", display: "flex" }}>
                <Typography fontSize=".875rem">2020:</Typography>
              </Grid>
              <Grid item xs={17} sm={5}>
                <TextField
                  fullWidth
                  id="unrealizedGains2020"
                  inputProps={{ style: { textAlign: "right" } }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  label="Unrealized Gains"
                  onChange={(e) =>
                    onUnrealizedGainsChange("2020", e.target.value)
                  }
                  size="small"
                  value={unrealizedGains[2020]}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={17} sm={5}>
                <TextField
                  fullWidth
                  id="totalValue2020"
                  inputProps={{ style: { textAlign: "right" } }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  label="Total Value"
                  onChange={(e) => onTotalValueChange("2020", e.target.value)}
                  size="small"
                  value={totalValue[2020]}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={17} sm={5}>
                <TextField
                  fullWidth
                  id="grossMoic2020"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">x</InputAdornment>
                    ),
                  }}
                  label="Gross MOIC"
                  onChange={(e) => onGrossMoicChange("2020", e.target.value)}
                  size="small"
                  value={grossMoic[2020]}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={17} sm={2} sx={{ alignItems: "center", display: "flex" }}>
                <Typography fontSize=".875rem">2021:</Typography>
              </Grid>
              <Grid item xs={17} sm={5}>
                <TextField
                  fullWidth
                  id="unrealizedGains2021"
                  inputProps={{ style: { textAlign: "right" } }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  label="Unrealized Gains"
                  onChange={(e) =>
                    onUnrealizedGainsChange("2021", e.target.value)
                  }
                  size="small"
                  value={unrealizedGains[2021]}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={17} sm={5}>
                <TextField
                  fullWidth
                  id="totalValue2021"
                  inputProps={{ style: { textAlign: "right" } }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  label="Total Value"
                  onChange={(e) => onTotalValueChange("2021", e.target.value)}
                  size="small"
                  value={totalValue[2021]}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={17} sm={5}>
                <TextField
                  fullWidth
                  id="grossMoic2021"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">x</InputAdornment>
                    ),
                  }}
                  label="Gross MOIC"
                  onChange={(e) => onGrossMoicChange("2021", e.target.value)}
                  size="small"
                  value={grossMoic[2021]}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={17} sm={2} sx={{ alignItems: "center", display: "flex" }}>
                <Typography fontSize=".875rem">2022:</Typography>
              </Grid>
              <Grid item xs={17} sm={5}>
                <TextField
                  fullWidth
                  id="unrealizedGains2022"
                  inputProps={{ style: { textAlign: "right" } }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  label="Unrealized Gains"
                  onChange={(e) =>
                    onUnrealizedGainsChange("2022", e.target.value)
                  }
                  size="small"
                  value={unrealizedGains[2022]}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={17} sm={5}>
                <TextField
                  fullWidth
                  id="totalValue2022"
                  inputProps={{ style: { textAlign: "right" } }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  label="Total Value"
                  onChange={(e) => onTotalValueChange("2022", e.target.value)}
                  size="small"
                  value={totalValue[2022]}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={17} sm={5}>
                <TextField
                  fullWidth
                  id="grossMoic2022"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">x</InputAdornment>
                    ),
                  }}
                  label="Gross MOIC"
                  onChange={(e) => onGrossMoicChange("2022", e.target.value)}
                  size="small"
                  value={grossMoic[2022]}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} textAlign="right">
            <Button type="submit" variant="contained">
              Save investment data
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  )
}

export default CompanyInvestmentForm
