import React, { useState } from "react"
import { useDispatch } from "react-redux"
import axios from "axios"

import { styled } from "@mui/styles"
import { Alert, Box, Button, Stack, Typography } from "@mui/material"

import { setAlert } from "../../../app/store/alertsSlice"
import { updateUser } from "../../../app/store/usersSlice"

import ConfirmModal from "../../../common/sharedComponents/ConfirmModal.component"

const StyledAlert = styled(Alert)(({ theme }) => ({
  '& .MuiAlert-icon': {
    alignItems: 'center',
  },
  '& .MuiAlert-message': {
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      flexWrap: 'nowrap',
    }
  }
}))

const StyledStack = styled(Stack)(({ theme }) => ({
  marginTop: '.5rem',
  [theme.breakpoints.up('sm')]: {
    marginLeft: '1rem',
    marginTop: '0',
  }
}))

const PendingUserAlert = ({ user }) => {
  const dispatch = useDispatch()
  const [approveModalOpen, setApproveModalOpen] = useState(false)
  const [denyModalOpen, setDenyModalOpen] = useState(false)

  const handleApproveModalResponse = res => {
    if (res === true) approveUser()
  }

  const handleDenyModalResponse = res => {
    if (res === true) denyUser()
  }

  const approveUser = () => {
    // Update user in rtdb
    axios.post("/api/users/update", {
      userId: user.id,
      dbObj: { status: 'approved' }
    }).then(res => {
      // Update document in store
      dispatch(updateUser({
        id: user.id,
        status: 'approved'
      }))

      // notify via email
      const emailOptions = {
        from: "Chenel Capital <noreply@mg.chenelcapital.com>",
        to: user.username,
        subject: "You now have access to the Chenel Capital Investor Portal",
        template: "access-approved",
        mgVariables: {
          'v:fname': user.fname
        }
      }
      axios.post("/api/mail/send", emailOptions).catch(error => {
        console.log('error sending email', error.message)
        dispatch(setAlert({ message: "Error sending email", severity: "warning" }))
      })

      // udpate is complete
      dispatch(setAlert({ message: `User has been approved`, severity: "success" }))
    }).catch(error => {
      console.log("error writing to db", error.response.data)
      dispatch(setAlert({ message: "Error updating user status", severity: "error" }))
    })
  }

  const denyUser = () => {
    // Update user in rtdb
    axios.post("/api/users/update", {
      userId: user.id,
      dbObj: { status: 'denied' }
    }).then(res => {
      // Update document in store
      dispatch(updateUser({
        id: user.id,
        status: 'denied'
      }))

      // notify via email
      const emailOptions = {
        from: "Chenel Capital <noreply@mg.chenelcapital.com>",
        to: user.username,
        subject: "Your request to access the Chenel Capital Investor Portal",
        template: "access-denied",
        mgVariables: {
          'v:fname': user.fname
        }
      }
      axios.post("/api/mail/send", emailOptions).catch(error => {
        console.log('error sending email', error.message)
        dispatch(setAlert({ message: "Error sending email", severity: "warning" }))
      })

      // udpate is complete
      dispatch(setAlert({ message: `User has been denied access`, severity: "success" }))
    }).catch(error => {
      console.log("error writing to db", error.response.data)
      dispatch(setAlert({ message: "Error updating user status", severity: "error" }))
    })
  }

  return (
    <Box mb={3}>
      <StyledAlert
        severity="error"
        sx={{  }}
      >
        <Typography component="span">
          This user is pending approval to access the Investor Portal.
        </Typography>
        <StyledStack direction="row" spacing={2}>
          <Button
            color="success"
            onClick={() => setApproveModalOpen(true)}
            variant="contained"
          >
            Approve
          </Button>
          <ConfirmModal
            modalOpen={approveModalOpen}
            handleCloseModal={() => setApproveModalOpen(false)}
            message="Are you sure you want to APPROVE this user? They will receive an email notification and will be able to access the portal immediately."
            handleResponse={handleApproveModalResponse}
          />
          <Button 
            color="error"
            onClick={() => setDenyModalOpen(true)}
            variant="outlined"
            >
            Deny
          </Button>
          <ConfirmModal
            modalOpen={denyModalOpen}
            handleCloseModal={() => setDenyModalOpen(false)}
            message="Are you sure you want to DENY this user? They will receive an email notification and will not be able to access the portal."
            handleResponse={handleDenyModalResponse}
          />
        </StyledStack>
      </StyledAlert>
    </Box>
  )
}

export default PendingUserAlert