import React from "react"
import { useDispatch, useSelector } from "react-redux"
import axios from "axios"

import { selectUser } from "../../../app/store/authSlice"
import { setAlert } from "../../../app/store/alertsSlice"

import { IconButton, Tooltip } from "@mui/material"
import { ForwardToInbox as ForwardToInboxIcon } from "@mui/icons-material"

const ForwardLinkButton = ({ downloadUrl }) => {
  const dispatch = useDispatch()
  const user = useSelector(selectUser)

  const handleSendDocumentLink = () => {
    const emailOptions = {
      from: "Chenel Capital <noreply@mg.chenelcapital.com>",
      to: user.username,
      subject: "Your document download link",
      template: "document-link",
      mgVariables: {
        'v:fname': user.fname,
        'v:downloadUrl': downloadUrl,
        'v:unsubURL': 'https://investors.chenelcapital.com/unsubscribe/abc123'
      }
    }
    axios.post("/api/mail/send", emailOptions).then(res => {
      dispatch(setAlert({ message: "Email sent", severity: "success" }))
    }).catch(error => {
      console.log('error sending email', error.message)
      dispatch(setAlert({ message: "Error sending email", severity: "error" }))
    })
  }
  return (
    <Tooltip title="Send a download link to my email">
      <IconButton
        onClick={handleSendDocumentLink}
      >
        <ForwardToInboxIcon />
      </IconButton>
    </Tooltip>
  )
}
 
export default ForwardLinkButton
