import React, { useState } from "react"
import { useDispatch } from "react-redux"
import axios from "axios"

import useMediaQuery from "@mui/material/useMediaQuery"
import { Box, Button, Grid, TextField } from "@mui/material"

import { setAlert } from "../../../app/store/alertsSlice"

import CardTitle from "../../../common/sharedComponents/CardTitle.component"
import PasswordStrengthMeter from "../../../common/sharedComponents/PasswordStrengthMeter.component"

const ChangePassword = ({ user }) => {
  const dispatch = useDispatch()
  const isLarge = useMediaQuery("(min-width: 1200px)")
  
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [confirmPasswordError, setConfirmPasswordError] = useState(null)

  const handlePasswordReset = () => {
    if (password !== confirmPassword) {
      setConfirmPasswordError("Passwords do not match")
    } else {
      axios.post("/api/firebase/auth/update", {
        uid: user.uid,
        updates: {
          password
        }
      }).then(() => {
        setPassword("")
        setConfirmPassword("")
        dispatch(setAlert({ message: "Password has been updated", severity: "success" }))
      }).catch(() => {
        dispatch(setAlert({ message: "Error updating password", severity: "error" }))
      })
    }
  }

  return (
    <Box mt={isLarge ? 2 : 0}>
      <CardTitle title="Change Your Password" />
      <Grid container spacing={3} mt={2}>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="New Password"
            name="password"
            onChange={e => setPassword(e.target.value)}
            type="password"
            value={password}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            error={!!confirmPasswordError}
            fullWidth
            helperText={confirmPasswordError}
            label="Confirm Password"
            name="confirmPassword"
            onChange={e => {
              setConfirmPasswordError(null)
              setConfirmPassword(e.target.value)
            }}
            type="password"
            value={confirmPassword}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <PasswordStrengthMeter password={password} />
        </Grid>
        <Grid item xs={12}>
          <Button
            onClick={handlePasswordReset}
            variant="contained"
          >
            Update Password
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}
 
export default ChangePassword
