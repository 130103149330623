import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { fetchAllGroups } from './groupsFetch'

const initialState = {
  status: 'idle',
  list: []
}

export const getAllGroupsAsync = createAsyncThunk(
  'groups/fetchAllGroups',
  async () => {
    const response = await fetchAllGroups()
    return response
  }
)

export const groupsSlice = createSlice({
  name: 'groups',
  initialState,
  reducers: {
    setGroups: (state, action) => ({
      ...state,
      list: action.payload
    }),
    addGroup: (state, action) => ({
      ...state,
      list: [
        ...state.list,
        action.payload
      ]
    }),
    updateGroup: (state, action) => ({
      ...state,
      list: state.list.map(group => {
        if (group.id === action.payload.id) {
          return {
            ...group,
            ...action.payload
          }
        } else {
          return group
        }
      })
    }),
    removeGroup: (state, action) => ({
      ...state,
      list: [
        ...state.list.filter(group => group.id !== action.payload)
      ]
    })
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllGroupsAsync.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(getAllGroupsAsync.fulfilled, (state, action) => ({
        status: 'idle',
        list: action.payload
      }))
  },
});

export const {
  setGroups,
  addGroup,
  updateGroup,
  removeGroup
} = groupsSlice.actions

export const selectGroupsList = state => state.groups.list
export const selectGroupById = id => state => state.groups.list.filter(group => group.id === id)[0]

export default groupsSlice.reducer
