import React, { useState } from "react"
import { useSelector } from "react-redux"

import { styled } from "@mui/material/styles"
import { Box, Paper, Tabs, Tab } from "@mui/material"

import { selectUser } from "../../../app/store/authSlice"

import TabPanel, { a11yProps } from "../../../common/mui/TabPanel.component"
import PageTitle from "../../../common/sharedComponents/PageTitle.component"
import ProfileForm from "./ProfileForm.component"
import ChangePassword from "./ChangePassword.component"
import AccreditationStatus from "./AccreditationStatus.component"

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: ".25rem .5rem 1rem",
  width: "100%",
  [theme.breakpoints.up("md")]: {
    padding: ".25rem 1rem 2rem",
  },
}))

const ProfilePage = () => {
  const user = useSelector(selectUser)
  const [tabValue, setTabValue] = useState(0)

  const handleFormChange = (event, newValue) => setTabValue(newValue)

  return (
    <Box>
      <PageTitle title="Profile" />
      <StyledPaper>
        <Box sx={{ borderBottom: 1, borderColor: "divider", }}>
          <Tabs
            aria-label="edit user tabs"
            onChange={handleFormChange}
            value={tabValue}
            variant="fullWidth"
          >
            <Tab label="Profile" {...a11yProps(0)} />
            <Tab label="Accreditation Status" {...a11yProps(1)} />
            <Tab label="Password" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <TabPanel
          value={tabValue}
          index={0}
          sx={{ border: "1px solid red" }}
          className="TabPanelRoot"
        >
          <ProfileForm user={user} />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <AccreditationStatus user={user} />
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <ChangePassword user={user} />
        </TabPanel>
      </StyledPaper>
    </Box>
  )
}

export default ProfilePage
