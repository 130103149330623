import React from "react"

import {
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Modal,
  Stack,
  Typography,
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import GroupIcon from "@mui/icons-material/Group"

const style = {
  bgcolor: "background.paper",
  border: "1px solid #666",
  borderRadius: "5px",
  boxShadow: 24,
  left: "50%",
  maxHeight: "100vh",
  maxWidth: "500px",
  overflow: "auto",
  px: 3,
  py: 2,
  position: "absolute",
  top: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
}

const ConfirmDeleteModal = ({
  modalOpen,
  handleCloseModal,
  handleDeleteGroups,
  groups,
  selected,
}) => {
  return (
    <Modal
      open={modalOpen}
      onClose={handleCloseModal}
      aria-labelledby="modal-title"
    >
      <Box sx={style}>
        <Box alignItems="center" display="flex" justifyContent="space-between">
          <Typography component="h2" variant="h6" id="modal-title">
            Confirm Action
          </Typography>
          <IconButton onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box sx={{ pt: 3 }}>
          <Typography paddingBottom={2}>
            You are about to delete {selected.length} group
            {selected.length > 1 && "s"}. This process is irreversible.
          </Typography>
          <Typography paddingBottom={2}>Do you want to proceed?</Typography>
          <Typography>Groups to be deleted:</Typography>
          <List>
            {groups
              .filter((group) => selected.includes(group.id))
              .map((group) => (
                <ListItem disablePadding key={group.id}>
                  <ListItemIcon sx={{ minWidth: "32px" }}>
                    <GroupIcon />
                  </ListItemIcon>
                  <ListItemText primary={group.name} />
                </ListItem>
              ))}
          </List>
        </Box>
        <Box display="flex" justifyContent="flex-end" paddingTop={4}>
          <Stack spacing={2} direction="row">
            <Button onClick={handleCloseModal} variant="outlined">
              Cancel
            </Button>
            <Button
              onClick={handleDeleteGroups}
              color="error"
              type="submit"
              variant="contained"
            >
              Delete Group{selected.length > 1 && "s"}
            </Button>
          </Stack>
        </Box>
      </Box>
    </Modal>
  )
}

export default ConfirmDeleteModal
