import React from "react"
import { useSelector } from "react-redux"
import { DateTime } from "luxon"

import useMediaQuery from "@mui/material/useMediaQuery"
import { Box, Card, Stack } from "@mui/material"

import { selectUser } from "../../app/store/authSlice"
import { selectUserById } from "../../app/store/usersSlice"

import RichTextDisplay from "./RichTextDisplay.component"

const CommunicationReply = ({ reply }) => {
  const sender = useSelector(selectUserById(reply.sender))
  const user = useSelector(selectUser)
  const isSmall = useMediaQuery("(min-width: 600px)")

  const isAdmin = user.role === 'admin'

  return (
    <Box
      component={Card}
      sx={{
        borderTop: '8px solid',
        my: 2,
        padding: 1,
        width: '80%',
        ...((isAdmin && sender.role === 'user') && { borderColor: 'primary.main' }),
        ...((isAdmin && sender.role === 'admin') && { borderColor: 'secondary.main' }),
        ...((isAdmin && sender.id === user.uid) && { marginLeft: 'auto' }),
        ...((!isAdmin && reply.sender === user.uid) && { borderColor: 'primary.main', marginLeft: 'auto' }),
        ...((!isAdmin && reply.sender !== user.uid) && { borderColor: 'secondary.main' }),
      }}
    >
      <Box px={2} py={1}>
        <RichTextDisplay
          defaultValue={reply.body}
          handleRichText={body => {}}
          readOnly={true}
        />
      </Box>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        spacing={{ xs: 0, sm: 1 }}
        sx={{
          color: 'text.secondary',
          ...(isSmall ? {
            fontSize: '.875rem',
            justifyContent: 'flex-end',
          } : {
            alignItems: 'flex-end',
            fontSize: '.75rem',
          })
        }}
      >
        <span>
          {isAdmin ? (
            `${sender.fname} ${sender.lname}`
          ) : (
            reply.sender === user.uid ? (
              `${user.fname} ${user.lname}`
            ) : (
              "Chenel Capital"
            )
          )}
        </span>
        <span>
          {isSmall ? (
            DateTime.fromISO(reply.created).toFormat("(ccc, LLL d, H:mm a)")
          ): (
            DateTime.fromISO(reply.created).toFormat("ccc, LLL d, H:mm a")
          )}
        </span>
      </Stack>
    </Box>
  )
}
 
export default CommunicationReply
