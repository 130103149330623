import React from "react"

import { styled } from "@mui/styles"
import {
  Box,
  Collapse,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography 
} from "@mui/material"

const StyledFormLabel = styled(FormLabel)(() => ({
  fontSize: '.875rem',
  paddingBottom: '.5rem',
}))

const StyledFormControlLabel = styled(FormControlLabel)(() => ({
  alignItems: 'flex-start',
  paddingBottom: '8px',
  '& > span': {
    fontSize: '.875rem',
    '&.MuiRadio-root': {
      padding: '0 8px',
    },
  },
  '& > span:last-child': {
    alignSelf: 'center',
  },
}))

const UserAccreditationStatusForm = ({ formValues, handleFormChange, disabled }) => {
  return (
    <form>
      <Grid container spacing={3} pb={4}>
        <Grid item xs={12}>
          <Typography fontSize=".875rem" pb={2}>
            User represents and warrants that it is an “accredited investor” within the meaning of Rule 
            501 of Regulation D under the Securities Act of 1933, as amended (the “Securities Act”) and 
            has selected the item below which is next to the category under which it qualifies as an 
            accredited investor:
          </Typography>
          <FormControl>
            <StyledFormLabel id="accredited-investor-status-label">Accredited Investor Status</StyledFormLabel>
            <RadioGroup
              aria-labelledby="accredited-investor-status-label"
              name="accredited-investor-radio-group"
              onChange={handleFormChange('investorStatus')}
              value={formValues.investorStatus}
            >
              <StyledFormControlLabel
                disabled={disabled}
                value="Investment Company"
                control={<Radio />}
                label="An “investment company” registered under the Investment Company Act of 1940, as amended (the “Investment Company Act”)"
              />
              <StyledFormControlLabel
                disabled={disabled}
                value="Business Development Company"
                control={<Radio />}
                label="A “business development company” as defined in Section 2(a)(48) of the Investment Company Act."
              />
              <StyledFormControlLabel
                disabled={disabled}
                value="Small Business Investment Company"
                control={<Radio />}
                label="A “small business investment company” licensed by the Small Business Administration under Section 301(c) or (d) of the Small Business Investment Act of 1958."
              />
              <StyledFormControlLabel
                disabled={disabled}
                value="Private Business Development Company"
                control={<Radio />}
                label="A “private business development company” as defined in Section 202(a)(22) of the Investment Advisers Act of 1940."
              />
              <StyledFormControlLabel
                disabled={disabled}
                value="Any of the following types of entities..."
                control={<Radio />}
                label="Any of the following types of entities: (i) an organization described in Section 501(c)(3) of the Internal Revenue Code of 1986, as amended (the “Code”), (ii) a corporation, (iii) a Massachusetts or similar business trust, or (iv) a partnership or limited liability company, in each case not formed for the specific purpose of acquiring fund interests on the Site, with total assets in excess of $5 million."
              />
              <StyledFormControlLabel
                disabled={disabled}
                value="Trust With Total Assets"
                control={<Radio />}
                label="A trust with total assets in excess of $5 million not formed for the specific purpose of acquiring fund interests on the Site, whose purchase is directed by a person with such knowledge and experience in financial and business matters as to be capable of evaluating the merits and risks of an investment in such interests."
              />
              <StyledFormControlLabel
                disabled={disabled}
                value="Entity in which all equity owners are accredited"
                control={<Radio />}
                label={
                  <>
                    <span>
                      An entity, including a grantor trust, in which all of the equity owners are accredited investors under any of the categories in (A) through (K) above or as described in (L)(1) or (L)(2) below (note, for this purpose, a beneficiary of a trust is not an equity owner, but the grantor of a grantor trust may be an equity owner):
                    </span>
                    <ol
                      style={{ listStyleType: 'upper-alpha', margin: '.5rem 0 0 1rem' }}
                    >
                      <li style={{ padding: '0 0 .5rem .5rem' }}>
                        A natural person who has an individual net worth, or joint net worth with such person's spouse, 
                        in excess of $1,000,000. As used herein, “net worth” means the excess of total assets over total 
                        liabilities. For purposes of determining “net worth” (a) the value of an individual's primary 
                        residence, and any amount of indebtedness secured by the primary residence up to the fair market 
                        value thereof, should be excluded; and (b) indebtedness secured by the primary residence in 
                        excess of the value of the home should be considered a liability.
                      </li>
                      <li style={{ padding: '0 0 .5rem .5rem' }}>
                        A natural person who had individual income (exclusive of any income attributable to such person's 
                        spouse) of more than $200,000 in each of the past two years, or joint income with such person's 
                        spouse of more than $300,000 in each of those years, and reasonably expects to reach the same 
                        income level in the current year.
                      </li>
                    </ol>
                  </>
                }
              />
              <StyledFormControlLabel
                disabled={disabled}
                value="Natural Person"
                control={<Radio />}
                label="A natural person who meets the eligibility criteria set forth in (L)(1) or (L)(2) above."
              />
            </RadioGroup>
          </FormControl>
          <Box
            component={Collapse}
            in={formValues.investorStatus === "Natural Person"}
            sx={{
              borderLeft: '2px solid',
              borderColor: 'primary.main',
              marginTop: '.5rem',
              marginLeft: '2rem',
              paddingLeft: '1rem',
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={5}>
                <FormControl>
                  <StyledFormLabel id="risk-tolerance-label">
                    Risk Tolerance
                  </StyledFormLabel>
                  <RadioGroup
                    aria-labelledby="risk-tolerance-label"
                    onChange={handleFormChange('riskTolerance')}
                    value={formValues.riskTolerance}
                  >
                    <StyledFormControlLabel
                    disabled={disabled}
                      value="Speculative / Very High"
                      control={<Radio />}
                      label="Speculative / Very High"
                    />
                    <StyledFormControlLabel
                    disabled={disabled}
                      value="High"
                      control={<Radio />}
                      label="High"
                    />
                    <StyledFormControlLabel
                      disabled={disabled}
                      value="Moderate"
                      control={<Radio />}
                      label="Moderate"
                    />
                    <StyledFormControlLabel
                      disabled={disabled}
                      value="Low"
                      control={<Radio />}
                      label="Low"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={7}>
                <FormControl>
                  <StyledFormLabel id="investment-experience-label">
                    Previous investment experience
                  </StyledFormLabel>
                  <RadioGroup
                    aria-labelledby="investment-experience-label"
                    onChange={handleFormChange('investmentExperience')}
                    value={formValues.investmentExperience}
                  >
                    <StyledFormControlLabel
                      disabled={disabled}
                      value="Hedge fund / fund of funds: extensive; moderate; occasional; never"
                      control={<Radio />}
                      label="Hedge fund / fund of funds: extensive; moderate; occasional; never"
                    />
                    <StyledFormControlLabel
                      disabled={disabled}
                      value="Private equity"
                      control={<Radio />}
                      label="Private equity"
                    />
                    <StyledFormControlLabel
                      disabled={disabled}
                      value="Stocks"
                      control={<Radio />}
                      label="Stocks"
                    />
                    <StyledFormControlLabel
                      disabled={disabled}
                      value="Bonds"
                      control={<Radio />}
                      label="Bonds"
                    />
                    <StyledFormControlLabel
                      disabled={disabled}
                      value="Other private funds"
                      control={<Radio />}
                      label="Other private funds"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={5}>
                <FormControl>
                  <StyledFormLabel id="investment-years-label">
                    How many years of private investing?
                  </StyledFormLabel>
                  <RadioGroup
                    aria-labelledby="investment-years-label"
                    onChange={handleFormChange('investmentYears')}
                    value={formValues.investmentYears}
                  >
                    <StyledFormControlLabel
                      disabled={disabled}
                      value="Over 10"
                      control={<Radio />}
                      label="Over 10"
                    />
                    <StyledFormControlLabel
                      disabled={disabled}
                      value="5-10 years"
                      control={<Radio />}
                      label="5-10 years"
                    />
                    <StyledFormControlLabel
                      disabled={disabled}
                      value="1-5 years"
                      control={<Radio />}
                      label="1-5 years"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={7}>
                <FormControl>
                  <StyledFormLabel id="investment-size-label">
                    Investment size generally
                  </StyledFormLabel>
                  <RadioGroup
                    aria-labelledby="investment-size-label"
                    onChange={handleFormChange('investmentSize')}
                    value={formValues.investmentSize}
                  >
                    <StyledFormControlLabel
                      disabled={disabled}
                      value="Over $10 million"
                      control={<Radio />}
                      label="Over $10 million"
                    />
                    <StyledFormControlLabel
                      disabled={disabled}
                      value="$5-10 million"
                      control={<Radio />}
                      label="$5-10 million"
                    />
                    <StyledFormControlLabel
                      disabled={disabled}
                      value="$1-5 million"
                      control={<Radio />}
                      label="$1-5 million"
                    />
                    <StyledFormControlLabel
                      disabled={disabled}
                      value="$500,000 - $1 million"
                      control={<Radio />}
                      label="$500,000 - $1 million"
                    />
                    <StyledFormControlLabel
                      disabled={disabled}
                      value="$250,000-500,000"
                      control={<Radio />}
                      label="$250,000-500,000"
                    />
                    <StyledFormControlLabel
                      disabled={disabled}
                      value="$100,000-250,000"
                      control={<Radio />}
                      label="$100,000-250,000"
                    />
                    <StyledFormControlLabel
                      disabled={disabled}
                      value="Under $100,000"
                      control={<Radio />}
                      label="Under $100,000"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </form>
  )
}
 
export default UserAccreditationStatusForm
