import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { fetchAllFunds } from "./fundsFetch"

const initialState = {
  status: "idle",
  list: [],
}

export const getAllFundsAsync = createAsyncThunk(
  "funds/fetchAllFunds",
  async () => {
    const response = await fetchAllFunds()
    return response
  }
)

export const fundsSlice = createSlice({
  name: "funds",
  initialState,
  reducers: {
    setFunds: (state, action) => ({
      ...state,
      list: action.payload,
    }),
    addFund: (state, action) => ({
      ...state,
      list: [...state.list, action.payload],
    }),
    updateFund: (state, action) => ({
      ...state,
      list: state.list.map((fund) => {
        if (fund.id === action.payload.id) {
          return {
            ...fund,
            ...action.payload,
          }
        } else {
          return fund
        }
      }),
    }),
    removeFund: (state, action) => ({
      ...state,
      list: [...state.list.filter((fund) => fund.id !== action.payload)],
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllFundsAsync.pending, (state) => {
        state.status = "loading"
      })
      .addCase(getAllFundsAsync.fulfilled, (state, action) => ({
        status: "idle",
        list: action.payload,
      }))
  },
})

export const { setFunds, addFund, updateFund, removeFund } = fundsSlice.actions

export const selectFundsList = (state) => state.funds.list
export const selectFundById = (id) => (state) =>
  state.funds.list.filter((fund) => fund.id === id)[0]
export const selectFundByKey = (key) => (state) =>
  state.funds.list.filter((fund) => fund.key === key)[0]

export default fundsSlice.reducer
