import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { getDatabase, ref, update } from "firebase/database"
import MuiPhoneNumber from "material-ui-phone-number"

import { updateAuth } from "../../../app/store/authSlice"
import { setAlert } from "../../../app/store/alertsSlice"

import useMediaQuery from "@mui/material/useMediaQuery"
import {
  Box,
  Button,
  Grid,
  Stack,
  TextField
} from "@mui/material"

import CardTitle from "../../../common/sharedComponents/CardTitle.component"

const ProfileForm = ({ user }) => {
  const dispatch = useDispatch()
  const database = getDatabase()
  const isLarge = useMediaQuery("(min-width: 1200px)")

  const [company, setCompany] = useState(user.company)
  const [companyError, setCompanyError] = useState(null)
  const [username, setUsername] = useState(user.username)
  const [usernameError, setUsernameError] = useState(null)
  const [fname, setFname] = useState(user.fname)
  const [fnameError, setFnameError] = useState(null)
  const [lname, setLname] = useState(user.lname)
  const [lnameError, setLnameError] = useState(null)
  const [phone, setPhone] = useState({
    formatted: `+${user.phone.dialCode} ${user.phone.subscriber}`,
    dialCode: user.phone.dialCode,
    subscriber: user.phone.subscriber
  })
  const [phoneError, setPhoneError] = useState(null)
  const [city, setCity] = useState(user.address ? user.address.city || "" : "")
  const [country, setCountry] = useState(user.address ? user.address.country || "" : "")
  const [state, setState] = useState(user.address ? user.address.state || "" : "")
  const [street, setStreet] = useState(user.address ? user.address.street || "" : "")
  const [zip, setZip] = useState(user.address ? user.address.zip || "" : "")

  const handlePhoneChange = (value, country) => {
    setPhoneError(null)

    const regex = new RegExp("(^\\+" + country.dialCode + ")")
    const subscriber = value.replace(/[^0-9+]/ig, "").replace(regex, "")

    setPhone({
      formatted: value,
      dialCode: country.dialCode,
      subscriber
    })
  }

  const saveUser = (userObj) => {
    const { uid, ...dbObj } = userObj

    // Update document in rtdb
    update(ref(database, "users/" + user.uid), dbObj).catch(error => {
      console.error(error)
    }).then(() => {
      // Update document in store
      dispatch(updateAuth({
        ...userObj
      }))

      // udpate is complete
      dispatch(setAlert({ message: "Profile updates saved", severity: "success" }))
    }).catch(error => {
      console.log('error writing to db', error.response.data)
      dispatch(setAlert({ message: "Error saving profile updates. Please refresh and try again.", severity: "error" }))
    })

  }

  const handleSubmit = e => {
    e.preventDefault()
    let error = false

    if (fname === "") {
      setFnameError("First Name is required")
      error = true
    }
    if (lname === "") {
      setLnameError("Last Name is required")
      error = true
    }
    if (username === "") {
      setUsernameError("Email Address is required")
      error = true
    }
    if (company === "") {
      setCompanyError("Company is required")
      error = true
    }

    if (!error) {
      saveUser({
        address: {
          city,
          country,
          state,
          street,
          zip,
        },
        uid: user.uid,
        company,
        username,
        fname,
        lname,
        phone: {
          dialCode: phone.dialCode,
          subscriber: phone.subscriber
        },
      })
    }
 }

  return (
    <Box mt={isLarge ? 2 : 0}>
      <CardTitle title="Review and Update Your Profile" />
      <form onSubmit={handleSubmit}>
        <Grid container spacing={isLarge ? 3 : 2} mt={isLarge ? 1 : .5}>
          <Grid item xs={12} md={6}>
            <TextField
              error={!!fnameError}
              fullWidth
              helperText={fnameError}
              label="First Name *"
              name="fname"
              onChange={e => {
                setFnameError(null)
                setFname(e.target.value)
              }}
              size={isLarge ? "medium" : "small"}
              value={fname}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              error={!!lnameError}
              fullWidth
              helperText={lnameError}
              label="Last Name *"
              name="lname"
              onChange={e => {
                setLnameError(null)
                setLname(e.target.value)
              }}
              size={isLarge ? "medium" : "small"}
              value={lname}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              error={!!usernameError}
              fullWidth
              helperText={usernameError}
              label="Email *"
              name="username"
              onChange={e => {
                setUsernameError(null)
                setUsername(e.target.value)
              }}
              size={isLarge ? "medium" : "small"}
              type="text"
              value={username}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              error={!!companyError}
              fullWidth
              helperText={companyError}
              label="Company *"
              name="company"
              onChange={e => {
                setCompanyError(null)
                setCompany(e.target.value)
              }}
              size={isLarge ? "medium" : "small"}
              value={company}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <MuiPhoneNumber
              defaultCountry={'us'}
              disableAreaCodes={true}
              error={!!phoneError}
              fullWidth
              helperText={phoneError}
              label="Phone"
              onChange={handlePhoneChange}
              preferredCountries={['us','uk','cn','de','fr','ca']}
              size={isLarge ? "medium" : "small"}
              value={phone.formatted}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Address"
              name="street"
              onChange={e => setStreet(e.target.value)}
              size={isLarge ? "medium" : "small"}
              type="text"
              value={street}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="City"
              name="city"
              onChange={e => setCity(e.target.value)}
              size={isLarge ? "medium" : "small"}
              type="text"
              value={city}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="State"
              name="state"
              onChange={e => setState(e.target.value)}
              size={isLarge ? "medium" : "small"}
              type="text"
              value={state}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Zip Code"
              name="zip"
              onChange={e => setZip(e.target.value)}
              size={isLarge ? "medium" : "small"}
              type="text"
              value={zip}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Country"
              name="country"
              onChange={e => setCountry(e.target.value)}
              size={isLarge ? "medium" : "small"}
              type="text"
              value={country}
            />
          </Grid>
          <Grid item xs={12} sx={{ mt: '.75rem' }}>
            <Stack spacing={2} direction="row">
              <Button
                size={isLarge ? "medium" : "small"}
                type="submit"
                variant="contained"
              >
                Save Profile Changes
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </Box>
  )
}
 
export default ProfileForm
