import React from "react"

import { styled } from '@mui/material/styles'
import { Box, Card, Link, Typography } from "@mui/material"

import logo from "../assets/images/chenel-capital-logo-light.png"

const StyledLogo = styled('img')(() => ({
  margin: '0 auto',
  maxWidth: '160px',
  width: '100%'
}))

const NotFoundPage = () => (
  <Card sx={{ backgroundColor: 'primary.main', maxWidth: '500px', width: '90%',}}>
    <Box sx={{ display: 'flex', flexDirection: 'column', padding: 4 }}>
      <StyledLogo src={logo} alt="Chenel Capital" />
    </Box>
    <Box sx={{ backgroundColor: 'white', padding: 4, width: '100%' }}>
      <Typography component="h1" variant="h4" pb={4}>404 Page Not Found</Typography>
      <Typography pb={2}>We couldn't find the page you are looking for.</Typography>
      <Link
        href="https://chenelcapital.com"
      >
        Head over to ChenelCapital.com
      </Link>
    </Box>
  </Card>
)

export default NotFoundPage
