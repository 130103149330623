import React from "react"
import { useDispatch, useSelector } from "react-redux"
import axios from "axios"

import { Card, Typography } from "@mui/material"

import {
  selectInvestmentInfo,
  updateSystemInfo,
} from "../../../app/store/systemInfoSlice"
import { setAlert } from "../../../app/store/alertsSlice"

import CompanyInvestmentForm from "./CompanyInvestmentForm.component"

const ManageInvestmentInfo = () => {
  const dispatch = useDispatch()
  const investment = useSelector(selectInvestmentInfo)

  const saveUpdate = (updatesObj) => {
    axios
      .post("/api/system/update", {
        updatesObj,
      })
      .then(() => {
        // Update systemInfo in store
        dispatch(updateSystemInfo(updatesObj))

        // udpate is complete
        dispatch(
          setAlert({
            message: `Investment updates saved`,
            severity: "success",
          })
        )
      })
      .catch((error) => {
        console.log("error writing to db", error.response.data)
        dispatch(
          setAlert({ message: "Error saving updates", severity: "error" })
        )
      })
  }

  const handleSaveData = (investment) => saveUpdate({ investment })

  return (
    <Card sx={{ fontSize: ".875rem", p: { xs: 2, md: 4 }, width: "100%" }}>
      <Typography fontSize=".875rem" pb={1}>
        Manage overall company investment info. This data will display for all
        users on the user dashboard.
      </Typography>
      <CompanyInvestmentForm investment={investment} handleSaveData={handleSaveData} />
    </Card>
  )
}

export default ManageInvestmentInfo
