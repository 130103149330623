import React, { useState } from "react"
import { useSelector } from "react-redux"

import useMediaQuery from "@mui/material/useMediaQuery"
import { Box, Button, Grid, Stack, TextField, Typography } from "@mui/material"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"

import { StyledListBox, StyledListBoxItem } from "../../../common/styledComponents/list-boxes"

import { selectUsersList } from "../../../app/store/usersSlice"

const sortByLname = (list) => list.sort((a, b) => (a.lname > b.lname ? 1 : -1))

const GroupForm = ({ group = {}, handleSaveGroup }) => {
  const isLarge = useMediaQuery("(min-width: 1200px)")
  const users = useSelector(selectUsersList)
  const [name, setName] = useState(group.name || "")
  const [nameError, setNameError] = useState("")
  const [search, setSearch] = useState("")
  const [selectedUsers, setSelectedUsers] = useState(
    group.members ? users.filter((user) => group.members.includes(user.id)) : []
  )
  const [unselectedUsers, setUnselectedUsers] = useState(
    group.members
      ? users.filter(
          (user) => !group.members.includes(user.id) && user.role === "user"
        )
      : users.filter((user) => user.role === "user")
  )

  const onNameChange = (e) => {
    setNameError("")
    setName(e.target.value)
  }

  const onSearchChange = (e) => setSearch(e.target.value)

  const getVisibleUnselectedUsers = () => {
    return unselectedUsers
      .filter((user) => {
        return (
          user.fname.toLowerCase().includes(search.toLowerCase()) ||
          user.lname.toLowerCase().includes(search.toLowerCase()) ||
          user.username.toLowerCase().includes(search.toLowerCase()) ||
          user.company.toLowerCase().includes(search.toLowerCase())
        )
      })
      .sort((a, b) => (a.lname > b.lname ? 1 : -1))
  }

  const getVisibleSelectedUsers = () => {
    return selectedUsers.sort((a, b) => (a.lname > b.lname ? 1 : -1))
  }

  const handleSelectUser = (user) => {
    setUnselectedUsers((prevState) =>
      prevState.filter((prevUser) => prevUser.id !== user.id)
    )
    setSelectedUsers((prevState) => sortByLname([...prevState, user]))
  }

  const handleSelectAllUsers = () => {
    setSelectedUsers((prevState) =>
      sortByLname([...prevState, ...unselectedUsers])
    )
    setUnselectedUsers([])
  }

  const handleUnselectUser = (user) => {
    setSelectedUsers((prevState) =>
      prevState.filter((prevUser) => prevUser.id !== user.id)
    )
    setUnselectedUsers((prevState) => sortByLname([...prevState, user]))
  }

  const handleUnselectAllUsers = () => {
    setUnselectedUsers((prevState) => [...prevState, ...selectedUsers])
    setSelectedUsers([])
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    let error = false

    if (name === "") {
      setNameError("Name is required")
      error = true
    }

    if (!error) {
      handleSaveGroup({
        id: group.id,
        name,
        members: selectedUsers.map((user) => user.id),
      })
    }
  }

  return (
    <Box
      sx={{
        maxHeight: "90vh",
        minHeight: { xs: "60vh", md: "40vh" },
        py: { xs: 1, md: 3 },
      }}
    >
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              error={!!nameError}
              fullWidth
              helperText={nameError}
              label="Group Name"
              name="name"
              onChange={onNameChange}
              required
              size={isLarge ? "medium" : "small"}
              sx={{ marginBottom: 2 }}
              value={name}
            />
          </Grid>
          <Grid item xs={6} sx={{ display: "flex", flexDirection: "column" }}>
            <Typography fontSize=".875rem" fontWeight="500" pb={1}>
              Add Users
            </Typography>
            <TextField
              fullWidth
              label="Search users"
              name="search"
              onChange={onSearchChange}
              size="small"
              sx={{ paddingBottom: "8px" }}
              value={search}
            />
            <StyledListBox>
              {unselectedUsers.length > 0 &&
                getVisibleUnselectedUsers(unselectedUsers).map((user) => (
                  <StyledListBoxItem
                    key={user.username}
                    selected={false}
                    onClick={() => handleSelectUser(user)}
                  >
                    <Typography
                      component="span"
                      sx={{
                        flexGrow: 1,
                        fontSize: { xs: ".75rem", lg: ".875rem" },
                      }}
                    >
                      {`${user.lname}, ${user.fname} (${user.username})`}
                    </Typography>
                    <ArrowForwardIcon
                      className="member-icon"
                      sx={{
                        flexGrow: 0,
                        fontSize: { xs: ".75rem", lg: ".875rem" },
                        marginLeft: "3px",
                      }}
                    />
                  </StyledListBoxItem>
                ))}
            </StyledListBox>
            <Button
              onClick={handleSelectAllUsers}
              sx={{ alignSelf: "flex-start", fontSize: ".75rem", mt: 0.5 }}
            >
              Add all
              <ArrowForwardIcon sx={{ fontSize: ".75rem", marginLeft: "3px" }} />
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Typography fontSize=".875rem" fontWeight="500" pb={1} pt="48px">
              Group Members
            </Typography>
            <StyledListBox>
              {selectedUsers.length > 0 &&
                getVisibleSelectedUsers(selectedUsers).map((user) => (
                  <StyledListBoxItem
                    key={user.username}
                    selected={true}
                    onClick={() => handleUnselectUser(user)}
                  >
                    <ArrowBackIcon
                      className="member-icon"
                      sx={{
                        flexGrow: 0,
                        fontSize: { xs: ".75rem", lg: ".875rem" },
                        marginRight: "3px",
                      }}
                    />
                    <Typography
                      component="span"
                      sx={{
                        flexGrow: 1,
                        fontSize: { xs: ".75rem", lg: ".875rem" },
                      }}
                    >
                      {`${user.lname}, ${user.fname} (${user.username})`}
                    </Typography>
                  </StyledListBoxItem>
                ))}
            </StyledListBox>
            <Button
              onClick={handleUnselectAllUsers}
              sx={{ alignSelf: "flex-end", fontSize: ".75rem", mt: 0.5 }}
            >
              <ArrowBackIcon sx={{ fontSize: ".75rem", marginRight: "3px" }} />
              Remove all
            </Button>
          </Grid>
          <Grid item xs={12} sx={{ mt: ".75rem" }}>
            <Stack spacing={2} direction="row">
              <Button
                type="submit"
                variant="contained"
                size={isLarge ? "medium" : "small"}
              >
                {group.id ? "Save Group" : "Add Group"}
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </Box>
  )
}

export default GroupForm
