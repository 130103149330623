import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { DateTime } from "luxon"
import axios from "axios"

import useMediaQuery from "@mui/material/useMediaQuery"
import { styled } from "@mui/material/styles"
import { Grid } from "@mui/material"

import { selectUser } from "../../../app/store/authSlice"
import { updateCommunication } from "../../../app/store/communicationsSlice"

import CommunicationsDrawer from "../../../common/sharedComponents/CommunicationsDrawer.component"

const StyledRow = styled(Grid)(({ theme }) => ({
  borderBottom: '1px solid ' + theme.palette.grey[300],
  cursor: 'pointer',
  overflow: 'hidden',
  '&:hover': {
    backgroundColor: theme.palette.grey[100],
  },
}))

const StyledCell = styled(Grid)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  padding: '.875rem .5rem',
  width: '200px', // prevents cell from expanding to fit text
  [theme.breakpoints.up('sm')]: {
    padding: '1rem .5rem',
  },
}))

const StyledSpanTruncated = styled('span')(() => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}))

const CommunicationsRow = ({ communication }) => {
  const isSmScreen = useMediaQuery("(min-width: 600px)")
  const dispatch = useDispatch()
  const user = useSelector(selectUser)

  const [drawerOpen, setDrawerOpen] = useState(false)
  const [activeCommunication, setActiveCommunication] = useState(null)

  const handleOpenCommunication = communication => {
    // mark communication as read
    if (!communication.read) {
      axios.post("/api/communications/mark-read-user", {
        userId: user.uid,
        communicationId: communication.id
      }).then(() => {
        dispatch(updateCommunication({
          id: communication.id,
          read: true
        }))
      }).catch(error => {
        console.log("error marking communication as read")
      })
    }

    // open drawer
    setActiveCommunication(communication)
    setDrawerOpen(true)
  }

  const handleCloseCommunication = () => {
    setDrawerOpen(false)
    setActiveCommunication(null)
  }

  return (
    <>
      <StyledRow
        container
        onClick={() => handleOpenCommunication(communication)}
        fontWeight={!communication.read ? "600" : "400"}
      >
        <StyledCell item xs={9} sm={4} md={4} lg={6}>
          <StyledSpanTruncated>
            {communication.subject}
          </StyledSpanTruncated>
        </StyledCell>
        {isSmScreen && (
          <>
            <StyledCell item xs={3} sm={3} md={3} lg={2}>
              <StyledSpanTruncated>
                {communication.recipientId === user.uid ? (
                  `${user.lname}, ${user.fname}`
                ) : (
                  "Chenel Capital"
                )}
              </StyledSpanTruncated>
            </StyledCell>
            <StyledCell item sm={3} md={3} lg={2}>
              <StyledSpanTruncated>
                {communication.recipientId === user.uid ? (
                  "Chenel Capital"
                ) : (
                  `${user.lname}, ${user.fname}`
                )}
              </StyledSpanTruncated>
            </StyledCell>
          </>
        )}
        <StyledCell item xs={3} sm={2} md={2} lg={2}>
          <StyledSpanTruncated>
            {communication.created && (
              isSmScreen ? (
                DateTime.fromISO(communication.created).toFormat('LL/dd/yyyy')
              ) : (
                DateTime.fromISO(communication.created).toFormat('L/d/yy')
              )
            )}
          </StyledSpanTruncated>
        </StyledCell>
      </StyledRow>
      <CommunicationsDrawer
        drawerOpen={drawerOpen}
        handleDrawerClose={handleCloseCommunication}
        communicationId={activeCommunication ? activeCommunication.id : ""}
      />
    </>
  )
}
 
export default CommunicationsRow
