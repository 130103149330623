import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import axios from "axios"

import { Box, Tab, Tabs } from "@mui/material"

import { updateDocument } from "../../../app/store/documentsSlice"
import { selectUsersUserList } from "../../../app/store/usersSlice"
import { selectGroupsList } from "../../../app/store/groupsSlice"
import { setAlert } from "../../../app/store/alertsSlice"

import TabPanel, { a11yProps } from "../../../common/mui/TabPanel.component"
import AssignUsersUserSearch from "./AssignUsersUserSearch.component"
import AssignUsersList from "./AssignUsersList.component"
import AssignUsersGroupSearch from "./AssignUsersGroupSearch.component"

const AssignUsers = ({ document, assignedUsers, setAssignedUsers }) => {
  const dispatch = useDispatch()
  const allUsersList = useSelector(selectUsersUserList)
  const allGroupsList = useSelector(selectGroupsList)

  const [unassignedUsers, setUnassignedUsers] = useState([])
  const [tabValue, setTabValue] = useState(0)

  const handleTabValueChange = (e, value) => setTabValue(value)

  // Load list of users from store into state
  useEffect(() => {
    setUnassignedUsers([
      ...allUsersList.filter((user) => !document.permissions.hasOwnProperty(user.id))
    ])
    setAssignedUsers([
      ...allUsersList.filter((user) => document.permissions.hasOwnProperty(user.id))
    ])
  }, [allUsersList, document.permissions, setAssignedUsers])

  const assignAllUsers = () => {
    if (unassignedUsers.length) {
      const numAssigned = unassignedUsers.length

      // assign everyone not already assigned and clear list of unassigned users
      setAssignedUsers([...assignedUsers, ...unassignedUsers])
      setUnassignedUsers([])

      dispatch(
        setAlert({ message: numAssigned + " users added", severity: "success" })
      )
    }
  }

  const assignUser = (newUser) => {
    setAssignedUsers(prevState => [...prevState, newUser])
    setUnassignedUsers(prevState => prevState.filter((user) => user.id !== newUser.id))
  }

  const assignGroup = (group) => {
    const newUsers = allUsersList.filter(user => group.members.includes(user.id))
    setAssignedUsers(prevState => [...prevState, ...newUsers])
    setUnassignedUsers(prevState => prevState.filter((user) => group.members.includes(user.id)))
  }

  const handleUnassignUser = (removeUser) => {
    const updatedAssignedUsers = assignedUsers.filter(
      (user) => user.id !== removeUser.id
    )
    // remove doc in database. this change is saved in realtime unlike other changes
    axios
      .post("/api/documents/remove-user-from-doc", {
        documentId: document.id,
        userId: removeUser.id,
      })
      .then(() => {
        dispatch(
          updateDocument({
            id: document.id,
            permissions: updatedAssignedUsers.reduce(
              (obj, item) => Object.assign(obj, { [item.id]: true }),
              {}
            ),
          })
        )

        setAssignedUsers(updatedAssignedUsers)
        setUnassignedUsers([...unassignedUsers, removeUser])

        dispatch(
          setAlert({ message: "User permissions removed", severity: "success" })
        )
      })
      .catch((error) => {
        console.log("unable to remove user", error)
        dispatch(
          setAlert({ message: "Unable to remove user", severity: "error" })
        )
      })
  }

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={tabValue}
          onChange={handleTabValueChange}
          aria-label="users and groups tabs"
        >
          <Tab label="Assign by User" {...a11yProps(0)} />
          <Tab label="Assign by Group" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={tabValue} index={0}>
        <AssignUsersUserSearch
          unassignedUsers={unassignedUsers}
          assignUser={assignUser}
          assignAllUsers={assignAllUsers}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <AssignUsersGroupSearch
          groupList={allGroupsList}
          assignGroup={assignGroup}
        />
      </TabPanel>
      {assignedUsers.length > 0 && (
        <AssignUsersList
          assignedUsers={assignedUsers}
          handleUnassignUser={handleUnassignUser}
        />
      )}
    </>
  )
}

export default AssignUsers
