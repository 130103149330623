import React, { useState } from "react"
import { useSelector } from "react-redux"

import useMediaQuery from "@mui/material/useMediaQuery"
import {
  Autocomplete,
  Box,
  TextField,
} from "@mui/material"

import { selectUsersUserList } from "../../app/store/usersSlice"
import { selectGroupsList } from "../../app/store/groupsSlice"

const mapGroupsListToOptions = (list) => {
  return list
    .map((group) => ({
      id: group.id,
      label: `[Group] ${group.name}`,
      type: "group"
    }))
    .sort((a, b) => (a.label > b.label ? 1 : -1))
}

const mapUsersListToOptions = (list) => {
  return list
    .map((user) => ({
      id: user.id,
      label: `${user.lname}, ${user.fname} (${user.username})`,
      type: "user"
    }))
    .sort((a, b) => (a.label > b.label ? 1 : -1))
}

const AssignUsers = ({ setRecipients }) => {
  const isLarge = useMediaQuery("(min-width: 1200px)")
  const groups = useSelector(selectGroupsList)
  const groupsList = mapGroupsListToOptions(groups)
  const users = useSelector(selectUsersUserList)
  const usersList = mapUsersListToOptions(users)

  const [toValue, setToValue] = useState([])

  const handleOnChange = (e, valuesArray) => {
    setToValue(valuesArray)
    if (valuesArray.length > 0) {
      const recipientArray = []
      valuesArray.forEach(value => {
        if (value.type === "user") recipientArray.push(value.id)
        else if (value.type === "group") {
          const group = groups.filter(g => g.id === value.id)[0]
          recipientArray.push(...group.members)
        }
      })
      setRecipients([...new Set(recipientArray)])
    } else {
      setRecipients([])
    }
  }

  return (
    <Box>
      <Autocomplete
        disablePortal
        fullWidth
        ListboxProps={{ style: { maxHeight: "25rem" } }}
        multiple
        options={[...groupsList, ...usersList]}
        renderInput={(params) => <TextField {...params} label="To" />}
        size={isLarge ? "medium" : "small"}
        value={toValue}
        onChange={handleOnChange}
      />
    </Box>
  )
}

export default AssignUsers
