import React from "react"

import useMediaQuery from "@mui/material/useMediaQuery"
import { Box, Card, Grid, Stack, Typography } from "@mui/material"

import CardTitle from "../../../common/sharedComponents/CardTitle.component"
import UnrealizedGainsChart from "../portfolio/UnrealizedGainsChart.component"
import GrossMoicChart from "../portfolio/GrossMoicChart.component"

const yearToDisplay = 2022 // used to pull current values from investment object

const DataType = ({ text, ...restOfProps }) => {
  return (
    <Typography
      {...restOfProps}
      sx={{ color: "text.secondary", fontSize: { xs: ".875rem", sm: "1rem" } }}
    >
      {text}
    </Typography>
  )
}
const Data = ({ value, formatUsd = false }) => {
  return (
    <Typography
      sx={{
        fontSize: { xs: "1.25rem", sm: "1.5rem" },
        ...(formatUsd && parseInt(value) < 0
          ? { color: "error.main" }
          : { color: "text.primary" }),
      }}
    >
      {formatUsd
        ? parseInt(value).toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })
        : value}
    </Typography>
  )
}

const InvestmentOverview = ({ investment }) => {
  const mobile = useMediaQuery("(max-width: 600px)")

  return (
    <Box>
      <CardTitle title="Chenel Capital Investment Overview" />
      <Card sx={{ p: { xs: 2, md: 4 }, mt: { xs: 1, md: 2 } }}>
        <Grid container columnSpacing={2} rowSpacing={4}>
          <Grid item xs={12} sm={6} md={3}>
            <Stack
              alignItems={mobile ? "center" : "center"}
              direction={mobile ? "row" : "column"}
              justifyContent="space-between"
              spacing={1}
            >
              <DataType text="Invested Capital" />
              {investment.investedCapital ? (
                <Data
                  formatUsd={true}
                  value={investment.investedCapital}
                />
              ) : (
                ""
              )}
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Stack
              alignItems={mobile ? "center" : "center"}
              direction={mobile ? "row" : "column"}
              justifyContent="space-between"
              spacing={1}
            >
              <DataType text="Unrealized Gains" />
              {investment.unrealizedGains ? (
                <Data
                  formatUsd={true}
                  value={investment.unrealizedGains[yearToDisplay]}
                />
              ) : (
                ""
              )}
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Stack
              alignItems={mobile ? "center" : "center"}
              direction={mobile ? "row" : "column"}
              justifyContent="space-between"
              spacing={1}
            >
              <DataType text="Total Value" />
              {investment.totalValue && (
                <Data
                  formatUsd={true}
                  value={investment.totalValue[yearToDisplay]}
                />
              )}
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Stack
              alignItems={mobile ? "center" : "center"}
              direction={mobile ? "row" : "column"}
              justifyContent="space-between"
              spacing={1}
            >
              <DataType text="Gross MOIC" />
              {investment.grossMoic && (
                <Data
                  value={investment.grossMoic[yearToDisplay] + "x"}
                />
              )}
            </Stack>
          </Grid>
          {investment.unrealizedGains && (
            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <DataType text="Unrealized Gains" pb={1} />
            <UnrealizedGainsChart unrealizedGains={investment.unrealizedGains} />
            </Grid>
          )}
          {investment.grossMoic && (
            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <DataType text="MOIC" pb={1} />
            <GrossMoicChart grossMoic={investment.grossMoic} />
            </Grid>
          )}
        </Grid>
      </Card>
    </Box>
  )
}

export default InvestmentOverview
