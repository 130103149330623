import React from "react"
import PropTypes from "prop-types"

import useMediaQuery from "@mui/material/useMediaQuery"
import { styled } from "@mui/material/styles"
import {
  Box,
  Grid,
  TableSortLabel
} from "@mui/material"
import { visuallyHidden } from "@mui/utils"

const StyledCell = styled(Grid)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  fontSize: '.75rem',
  fontWeight: '500',
  padding: '.5rem .5rem 0',
  [theme.breakpoints.up('sm')]: {
    padding: '0 .25rem',
  },
}))

const CommunicationsTableHead = ({ order, orderBy, onRequestSort }) => {
  const isSmScreen = useMediaQuery("(min-width: 600px)")

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  return (
    <>
      <StyledCell item xs={6} sm={4} md={4} lg={6}>
        <TableSortLabel
          active={orderBy === "subject"}
          direction={orderBy === "subject" ? order : "asc"}
          onClick={createSortHandler("subject")}
        >
          Subject
          {orderBy === "subject" ? (
            <Box component="span" sx={visuallyHidden}>
              {order === "desc" ? "sorted descending" : "sorted ascending"}
            </Box>
          ) : null}
        </TableSortLabel>
      </StyledCell>
      <StyledCell item xs={3} sm={3} md={3} lg={2}>
        <TableSortLabel
          active={orderBy === "to"}
          direction={orderBy === "to" ? order : "asc"}
          onClick={createSortHandler("to")}
        >
          Recipients
          {orderBy === "to" ? (
            <Box component="span" sx={visuallyHidden}>
              {order === "desc" ? "sorted descending" : "sorted ascending"}
            </Box>
          ) : null}
        </TableSortLabel>
      </StyledCell>
      {isSmScreen && (
        <StyledCell item sm={3} md={3} lg={2}>
          <TableSortLabel
            active={orderBy === "sender"}
            direction={orderBy === "sender" ? order : "asc"}
            onClick={createSortHandler("sender")}
          >
            Sender
            {orderBy === "sender" ? (
              <Box component="span" sx={visuallyHidden}>
                {order === "desc" ? "sorted descending" : "sorted ascending"}
              </Box>
            ) : null}
          </TableSortLabel>
        </StyledCell>
      )}
      <StyledCell item xs={3} sm={2} md={2} lg={2}>
        <TableSortLabel
          active={orderBy === "created"}
          direction={orderBy === "created" ? order : "asc"}
          onClick={createSortHandler("created")}
        >
          Sent
          {orderBy === "created" ? (
            <Box component="span" sx={visuallyHidden}>
              {order === "desc" ? "sorted descending" : "sorted ascending"}
            </Box>
          ) : null}
        </TableSortLabel>
      </StyledCell>
    </>
  )
}

CommunicationsTableHead.propTypes = {
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  onRequestSort: PropTypes.func.isRequired,
}

export default CommunicationsTableHead
