import React from "react"

import { styled } from '@mui/material/styles'
import Box from "@mui/material/Box"
import CircularProgress from "@mui/material/CircularProgress"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"

const OuterBox = styled(Box)(() => ({
  alignItems: 'center',
  backgroundSize: 'cover',
  display: 'flex',
  height: '100vh',
  justifyContent: 'center',
  width: '100vw',
}))

const LoadingPage = () => {
  return (
    <OuterBox>
      <Stack>
        <CircularProgress color="primary" size={'4rem'} />
        <Typography color="primary" fontSize="1.25rem" pt={2}>Loading</Typography>
      </Stack>
    </OuterBox>
  )
}
 
export default LoadingPage
