import { styled } from "@mui/material/styles"
import { Box } from "@mui/material"

export const StyledListBox = styled(Box)(({ theme }) => ({
  border: "1px solid #c4c4c4",
  borderRadius: "4px",
  height: "282px",
  overflowY: "auto",
  [theme.breakpoints.up("lg")]: {
    minHeight: "56px",
  },
}))

export const StyledListBoxItem = styled(Box, {
  shouldForwardProp: (prop) => prop !== "selected",
})(({ theme, selected }) => ({
  alignItems: "center",
  cursor: "pointer",
  display: "flex",
  padding: "3px .5rem",
  "& span": {
    fontSize: ".75rem",
    overflow: "hidden", 
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: "200px",
  },
  ".member-icon": {
    visibility: "hidden",
  },
  "&:hover": {
    ...(selected
      ? { backgroundColor: theme.palette.error.light }
      : { backgroundColor: theme.palette.success.light }),
    ".member-icon": {
      visibility: "visible",
    },
  },
  [theme.breakpoints.up('lg')]: {
    padding: "4px .5rem",
    "& span": {
      fontSize: ".875rem",
    }
  },
}))
