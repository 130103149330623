import React, { useState, useEffect } from "react"
import MuiPhoneNumber from 'material-ui-phone-number'

import { Box, Grid, TextField, Typography } from "@mui/material"

const RegistrationStepUserInfo = ({
  formValues,
  setFormValues,
  handleFormChange,
  validateStep,
  setValidateStep,
  handleNext,
  setRegistrationError
}) => {

  const handlePhoneChange = (value, country) => {
    const regex = new RegExp("(^\\+" + country.dialCode + ")")
    const subscriber = value.replace(/[^0-9+]/ig, "").replace(regex, "")

    setFormValues({
      ...formValues,
      phone: {
        formatted: value,
        dialCode: country.dialCode,
        subscriber
      }
    })
  }
  
  // Validate all fields in this registration step when "Next" is clicked
  useEffect(() => {
    if (validateStep === 2) {
      let valid = false
      
      if (!(formValues.fname
          && formValues.lname
          && formValues.username
          && formValues.company
          && formValues.phone.subscriber
          && formValues.address
          && formValues.city
          && formValues.state
          && formValues.zip
          && formValues.country)
      ) {
        setRegistrationError("Please fill out all fields")
      } else {
        setRegistrationError("")
        valid = true
      }

      setValidateStep(null)
      if (valid) handleNext()
    }
  // only want to re-render when validate step changes, ignore other data
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validateStep])

  return (
    <Box py={2}>
      <Typography component={"h2"} variant={"h5"} pb={1}>Investor Information</Typography>
      <Typography color="text.secondary" pb={2}>All fields required</Typography>
      <form>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="First Name"
              name="fname"
              onChange={handleFormChange("fname")}
              value={formValues.fname}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Last Name"
              name="lname"
              onChange={handleFormChange("lname")}
              value={formValues.lname}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Email"
              name="username"
              onChange={handleFormChange("username")}
              type="text"
              value={formValues.username}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Company (or enter N/A)"
              name="company"
              onChange={handleFormChange("company")}
              value={formValues.company}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <MuiPhoneNumber
              defaultCountry={'us'}
              disableAreaCodes={true}
              fullWidth
              label="Phone"
              onChange={handlePhoneChange}
              preferredCountries={['us','uk','cn','de','fr','ca']}
              value={formValues.phone.formatted}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Address"
              name="address"
              onChange={handleFormChange("address")}
              type="text"
              value={formValues.address}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="City"
              name="city"
              onChange={handleFormChange("city")}
              type="text"
              value={formValues.city}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="State / Region"
              name="state"
              onChange={handleFormChange("state")}
              type="text"
              value={formValues.state}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Zip Code"
              name="zip"
              onChange={handleFormChange("zip")}
              type="text"
              value={formValues.zip}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Country"
              name="country"
              onChange={handleFormChange("country")}
              type="text"
              value={formValues.country}
            />
          </Grid>
        </Grid>
      </form>
    </Box>
  )
}
 
export default RegistrationStepUserInfo
