import React from "react"

import { styled } from "@mui/styles"
import { Card, Stack, Typography } from "@mui/material"

import logo from "../../assets/images/chenel-capital-logo-wide.png"
import Registration from "./Registration.component"

const StyledCard = styled(Card)(({ theme }) => ({
    alignSelf: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    overflowY: 'auto',
    padding: '2rem 1rem',
    width: '100vw',
    [theme.breakpoints.up('sm')]: {
      alignSelf: 'inherit',
      minHeight: 0,
      maxWidth: '800px',
      overflow: 'hidden',
      padding: '2rem',
      width: '90%',
    }
}))

const StyledStack = styled(Stack)(({ theme }) => ({
  flexGrow: 1,
}))

const StyledLogo = styled('img')(() => ({
  marginBottom: '2rem',
  maxWidth: '180px',
  width: '100%'
}))

const RegisterPage = () => {

  return (
    <StyledCard>
      <StyledStack spacing={3}>
        <StyledLogo src={logo} alt="Chenel Capital" />
        <Typography component="h1" variant="h5">
          Register for the Chenel Capital Investor Portal.
        </Typography>
        <Registration />
      </StyledStack>
    </StyledCard>
  )
}
 
export default RegisterPage
