import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import axios from "axios"

import { Box, Paper, Typography } from "@mui/material"

import { setAlert } from "../../../app/store/alertsSlice"
import { removeFund, selectFundsList } from "../../../app/store/fundsSlice"

import Breadcrumbs from "../../../common/sharedComponents/Breadcrumbs.component"
import PageTitle from "../../../common/sharedComponents/PageTitle.component"
import FundsTableToolbar from "./FundsTableToolbar.component"
import FundsTable from "./FundsTable.component"

const PortfolioPage = () => {
  const dispatch = useDispatch()
  const funds = useSelector(selectFundsList)

  const [selected, setSelected] = useState([])
  const [search, setSearch] = useState("")

  const handleDeleteFunds = (fundsToDelete) => {
    for (let i = 0; i < fundsToDelete.length; i++) {
      const fundId = fundsToDelete[i]

      axios
        .post("/api/funds/remove", {
          fundId,
        })
        .then(() => {
          // remove fund from store
          dispatch(removeFund(fundId))

          // remove is complete
          dispatch(
            setAlert({
              message: `${
                selected.length > 1 ? "Funds have" : "Fund has"
              } been permanently deleted`,
              severity: "success",
            })
          )
        })
        .catch((error) => {
          console.log("error deleting fund", error.response.data)
          dispatch(
            setAlert({
              message: "Unable to remove fund from database",
              severity: "error",
            })
          )
        })
    }

    setSelected([])
  }

  const getVisibleFunds = () => {
    return funds.filter((fund) =>
      fund.name.toLowerCase().includes(search.toLowerCase())
    )
  }

  return (
    <>
      <Breadcrumbs
        pages={[
          { title: "Admin", to: "/admin" },
          { title: "Funds" },
        ]}
      />
      <PageTitle title="Manage Portfolio" />
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <FundsTableToolbar
            funds={funds}
            selected={selected}
            handleDeleteFunds={handleDeleteFunds}
            search={search}
            setSearch={setSearch}
          />
          {funds.length > 0 ? (
            <FundsTable
              funds={getVisibleFunds()}
              selected={selected}
              setSelected={setSelected}
            />
          ) : (
            <Typography fontSize=".875rem" p={4}>
              No funds found
            </Typography>
          )}
        </Paper>
      </Box>
    </>
  )
}

export default PortfolioPage
