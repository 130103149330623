import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { fetchAllDocuments, fetchDocumentsByUser } from './documentsFetch'

const initialState = {
  status: 'idle',
  list: []
}

export const getAllDocumentsAsync = createAsyncThunk(
  'documents/fetchAllDocuments',
  async () => {
    const response = await fetchAllDocuments()
    return response
  }
)

export const getDocumentsByUserAsync = createAsyncThunk(
  'documents/fetchDocumentsByUser',
  async (uid) => {
    const response = await fetchDocumentsByUser(uid)
    return response
  }
)

export const documentsSlice = createSlice({
  name: 'documents',
  initialState,
  reducers: {
    setDocuments: (state, action) => ({
      ...state,
      list: action.payload
    }),
    addDocument: (state, action) => ({
      ...state,
      list: [
        ...state.list,
        action.payload
      ]
    }),
    updateDocument: (state, action) => ({
      ...state,
      list: state.list.map(document => {
        if (document.id === action.payload.id) {
          return {
            ...document,
            ...action.payload
          }
        } else {
          return document
        }
      })
    }),
    removeDocument: (state, action) => ({
      ...state,
      list: [
        ...state.list.filter(document => document.id !== action.payload)
      ]
    })
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllDocumentsAsync.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(getAllDocumentsAsync.fulfilled, (state, action) => ({
        status: 'idle',
        list: action.payload
      }))
      .addCase(getDocumentsByUserAsync.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(getDocumentsByUserAsync.fulfilled, (state, action) => ({
        status: 'idle',
        list: action.payload
      }))
  },
});

export const {
  setDocuments,
  addDocument,
  updateDocument,
  removeDocument
} = documentsSlice.actions

export const selectDocumentsList = (state) => state.documents.list
export const selectDocumentById = id => state => state.documents.list.filter(doc => doc.id === id)[0]

export default documentsSlice.reducer
