import React, { useState } from "react"
import { useSelector } from "react-redux"

import useMediaQuery from "@mui/material/useMediaQuery"
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Stack,
  TextField
} from "@mui/material"

import { selectUserId } from "../../app/store/authSlice"

import RichTextEditor from "./RichTextEditor.component"
import AssignUsersToCommunication from "./AssignUsersToCommunication.component"

const ComposeForm = ({ handleComposeCommunication, senderRole, subject, stringifiedDefaultBody }) => {
  const isLarge = useMediaQuery("(min-width: 1200px)")
  const sender = useSelector(selectUserId)
  const senderName = senderRole === "admin" ? "Chenel Capital" : ""

  const initialFormValues = {
    body: "",
    subject,
  }
  const initialErrorValues = {
    body: "",
    subject: "",
  }

  const [formValues, setFormValues] = useState(initialFormValues)
  const [recipients, setRecipients] = useState(senderRole === 'admin' ? [] : ['admins'])
  const [errorValues, setErrorValues] = useState(initialErrorValues)
  const [sendEmail, setSendEmail] = useState(false)

  const handleFormChange = (property, value) => {
    setFormValues({ ...formValues, [property]: value })
  }

  const handleErrorChange = (property, value) => {
    setErrorValues({ ...errorValues, [property]: value })
  }

  const handleRichText = body => {
    setFormValues({ ...formValues, body: JSON.stringify(body) })
  }

 const handleSubmit = e => {
    e.preventDefault()
    let error = false

    if (formValues.subject === "") {
      handleErrorChange("subject", "Subject is required")
      error = true
    }
    if (formValues.body === "") {
      handleErrorChange("body", "Body is required")
      error = true
    }

    if (!error) {
      handleComposeCommunication({
        body: formValues.body,
        sender,
        senderName,
        subject: formValues.subject,
      }, recipients, sendEmail)
    }
 }

  return (
    <Box sx={{ py: 3 }}>
      <form
        onSubmit={handleSubmit}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {senderRole === 'admin' ? (
              <AssignUsersToCommunication setRecipients={setRecipients} />
            ) : (
              <TextField
                disabled
                fullWidth
                label="To"
                size={isLarge ? "medium" : "small"}
                value="Chenel Capital"
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <TextField
              error={!!errorValues.subject}
              fullWidth
              helperText={errorValues.subject}
              label="Subject"
              name="subject"
              onChange={e => {
                handleErrorChange("subject", null)
                handleFormChange("subject", e.target.value)
              }}
              required
              size={isLarge ? "medium" : "small"}
              value={formValues.subject}
            />
          </Grid>
          <Grid item xs={12}>
            <RichTextEditor
              defaultValue={stringifiedDefaultBody}
              handleRichText={handleRichText}
            />
          </Grid>
          {senderRole === 'admin' && (
            <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox checked={sendEmail} />}
                label="Notify recipients by email"
                onChange={() => setSendEmail(!sendEmail)}
              />
            </Grid>
          )}
          <Grid item xs={12} sx={{ mt: '.75rem' }}>
            <Stack spacing={2} direction="row">
              <Button
                size={isLarge ? "medium" : "small"}
                type="submit"
                variant="contained"
              >
                Send Communication
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </Box>
  )
}

ComposeForm.defaultProps = {
  senderRole: "user",
  subject: "",
  stringifiedDefaultBody: "",
}
 
export default ComposeForm
