import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { fetchSystemInfo } from "./systemInfoFetch"

const initialState = {
  status: "idle",
  info: {},
}

export const getSystemInfoAsync = createAsyncThunk(
  "systemInfo/fetchSystemInfo",
  async () => {
    const response = await fetchSystemInfo()
    return response
  }
)

export const systemInfoSlice = createSlice({
  name: "systemInfo",
  initialState,
  reducers: {
    setSystemInfo: (state, action) => ({
      ...state,
      info: action.payload,
    }),
    updateSystemInfo: (state, action) => ({
      ...state,
      info: {
        ...state.info,
        ...action.payload
      },
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSystemInfoAsync.pending, (state) => {
        state.status = "loading"
      })
      .addCase(getSystemInfoAsync.fulfilled, (state, action) => ({
        status: "idle",
        info: action.payload,
      }))
  },
})

export const { setSystemInfo, updateSystemInfo } = systemInfoSlice.actions

export const selectSystemInfo = (state) => state.systemInfo.info
export const selectInvestmentInfo = (state) => state.systemInfo.info.investment || {}

export default systemInfoSlice.reducer
