import React, { useEffect, useState } from "react"
import { NavLink, useNavigate, useSearchParams } from "react-router-dom"
import { useDispatch } from 'react-redux'
import { getAuth, confirmPasswordReset, verifyPasswordResetCode } from "firebase/auth"

import { styled } from '@mui/material/styles'
import {
  Button,
  Card,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  OutlinedInput,
  Stack,
  Typography
} from "@mui/material"
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon
} from "@mui/icons-material"

import { setAlert } from "../../app/store/alertsSlice"

import logo from "../../assets/images/chenel-capital-logo-wide.png"
const StyledLogo = styled('img')(() => ({
  marginBottom: '2rem',
  maxWidth: '180px',
  width: '100%'
}))

const formStyle = { width: '100%', }

const AccountManagementPage = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const auth = getAuth()
  
  const [searchParams, setSearchParams] = useSearchParams()
  const mode = searchParams.get("mode")
  const code = searchParams.get("oobCode")
  
  const [password, setPassword] = useState("")
  const [passwordError, setPasswordError] = useState("")
  const [showPassword, setShowPassword] = useState(false)
  const [validCode, setValidCode] = useState(true)

  useEffect(() => {
    verifyPasswordResetCode(auth, code).catch((error) => setValidCode(false))
  }, [auth, code])

  const handleSubmit = async e => {
    e.preventDefault()
    confirmPasswordReset(auth, code, password)
    .then(function() {
      dispatch(setAlert({ message: "Password has been reset", severity: "success" }))
      navigate("/login")
    })
    .catch(error => {
      setPasswordError("Error saving password. Try requesting a new password rest link.")
      console.log(error)
    })
  }

  return (
    <Card sx={{ maxWidth: '500px', padding: 4, width: '90%',}}>
      <Stack spacing={2}>
        <StyledLogo src={logo} alt="Chenel Capital" />
        {validCode ? (
          <>
            <Typography component="h1" variant="h5" sx={{ maxWidth: '400px', pb: 4 }}>
              Reset your password
            </Typography>
            <form
              method="post"
              onSubmit={e => handleSubmit(e)}
              style={formStyle}
            >
              <Stack spacing={3} alignItems="center">
                <FormControl variant="outlined" sx={{ width: '100%' }}>
                  <InputLabel htmlFor="outlined-adornment-password">New Password</InputLabel>
                  <OutlinedInput
                    error={!!passwordError}
                    id="outlined-adornment-password"
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={e => {
                      setPasswordError("")
                      setPassword(e.target.value)
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                          onMouseDown={e => e.preventDefault()}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="New Password"
                  />
                  <FormHelperText error={!!passwordError}>
                    {passwordError}
                  </FormHelperText>
                </FormControl>
                <Button fullWidth type="submit" variant="contained">
                  Save Password
                </Button>
                <Typography>
                  Nevermind, take me{" "}
                  <Link component={NavLink} to="/login" underline="none">back to login</Link>
                </Typography>
              </Stack>
            </form>  
          </>   
        ) : (
          <>
            <Typography>Your reset password link has expired or has already been used.</Typography>
            <Typography>
              Try{" "}
              <Link component={NavLink} to="/forgot-password" underline="none">resetting your password</Link>
              {" "}again.
              </Typography>
          </>
        )}
      </Stack>
    </Card>
  )
}

export default AccountManagementPage
