import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import axios from "axios"

import { Box, Paper, Typography } from "@mui/material"

import { setAlert } from "../../../app/store/alertsSlice"
import { removeGroup, selectGroupsList } from "../../../app/store/groupsSlice"

import Breadcrumbs from "../../../common/sharedComponents/Breadcrumbs.component"
import PageTitle from "../../../common/sharedComponents/PageTitle.component"
import GroupsTableToolbar from "./GroupsTableToolbar.component"
import GroupsTable from "./GroupsTable.component"

const GroupsPage = () => {
  const dispatch = useDispatch()
  const groups = useSelector(selectGroupsList)

  const [selected, setSelected] = useState([])
  const [search, setSearch] = useState("")

  const handleDeleteGroups = (groupsToDelete) => {
    for (let i = 0; i < groupsToDelete.length; i++) {
      const groupId = groupsToDelete[i]

      axios
        .post("/api/groups/remove", {
          groupId,
        })
        .then(() => {
          // remove group from store
          dispatch(removeGroup(groupId))

          // remove is complete
          dispatch(
            setAlert({
              message: `${
                selected.length > 1 ? "Groups have" : "Group has"
              } been permanently deleted`,
              severity: "success",
            })
          )
        })
        .catch((error) => {
          console.log("error deleting group", error.response.data)
          dispatch(
            setAlert({
              message: "Unable to remove group from database",
              severity: "error",
            })
          )
        })
    }

    setSelected([])
  }

  const getVisibleGroups = () => {
    return groups.filter((group) =>
      group.name.toLowerCase().includes(search.toLowerCase())
    )
  }

  return (
    <>
      <Breadcrumbs
        pages={[
          { title: "Admin", to: "/admin" },
          { title: "Groups" },
        ]}
      />
      <PageTitle title="Manage Groups" />
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <GroupsTableToolbar
            groups={groups}
            selected={selected}
            handleDeleteGroups={handleDeleteGroups}
            search={search}
            setSearch={setSearch}
          />
          {groups.length > 0 ? (
            <GroupsTable
              groups={getVisibleGroups()}
              selected={selected}
              setSelected={setSelected}
            />
          ) : (
            <Typography fontSize=".875rem" p={4}>
              No groups found
            </Typography>
          )}
        </Paper>
      </Box>
    </>
  )
}

export default GroupsPage
