import React, { useState } from "react"
import { NavLink, useNavigate } from "react-router-dom"
import { useDispatch } from 'react-redux'
import { getAuth, sendPasswordResetEmail  } from "firebase/auth"

import { styled } from '@mui/material/styles'
import {
  Button,
  Card,
  Link,
  Stack,
  TextField,
  Typography
} from "@mui/material"

import { setAlert } from "../../app/store/alertsSlice"

import logo from "../../assets/images/chenel-capital-logo-wide.png"
const StyledLogo = styled('img')(() => ({
  marginBottom: '2rem',
  maxWidth: '180px',
  width: '100%'
}))

const formStyle = { width: '100%', }

const ForgotPasswordPage = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const auth = getAuth()

  const [email, setEmail] = useState("")
  const [emailError, setEmailError] = useState("")

  const handleSubmit = async e => {
    e.preventDefault()

    if (!email) setEmailError("Please enter a valid email")
    else {
      sendPasswordResetEmail(auth, email)
        .then(() => {
          dispatch(setAlert({ message: `Check ${email} for password reset instructions`, severity: "success" }))
          navigate("/login")
        })
        .catch((error) => {
          if (error.code === "auth/invalid-email") setEmailError("Please enter a valid email")
          else if (error.code === "auth/user-not-found") setEmailError("Email address not found")
          else setEmailError("Error sending reset email" + error.code)
        })
    }
    
  }

  return (
    <Card sx={{ maxWidth: '500px', padding: 4, width: '90%',}}>
      <Stack spacing={2}>
        <StyledLogo src={logo} alt="Chenel Capital" />
        <Typography sx={{ maxWidth: '400px', pb: 4 }}>
          To reset your password, enter the email address you use to sign in.
        </Typography>
        <form
          method="post"
          onSubmit={e => handleSubmit(e)}
          style={formStyle}
        >
          <Stack spacing={3} alignItems="center">
            <TextField
              error={!!emailError}
              fullWidth
              helperText={emailError}
              label="Email"
              name="email"
              onChange={e => {
                setEmailError("")
                setEmail(e.target.value)
              }}
              value={email}
              variant="outlined"
            />
            <Button fullWidth type="submit" variant="contained">
              Send reset link
            </Button>
            <Typography>
              Nevermind, take me{" "}
              <Link component={NavLink} to="/login" underline="none">back to login</Link>
            </Typography>
          </Stack>
        </form>
      </Stack>
    </Card>
  )
}

export default ForgotPasswordPage
