import React, { useState } from "react"
import { useDispatch } from "react-redux"
import axios from "axios"

import { Box, Button, Stack, Switch, Tooltip, Typography } from "@mui/material"
import LockIcon from "@mui/icons-material/Lock"
import LockOpenIcon from "@mui/icons-material/LockOpen"

import { setAlert } from "../../../app/store/alertsSlice"
import { updateUser } from "../../../app/store/usersSlice"

import UserAccreditationStatusForm from "../../../common/sharedComponents/UserAccreditationStatusForm.component"

const UserAccreditationStatus = ({ user }) => {
  const dispatch = useDispatch()
  const [formDisabled, setFormDisabled] = useState(true)
  const [formValues, setFormValues] = useState({
    investorStatus: user.questionnaire ? user.questionnaire.investorStatus || "" : "",
    riskTolerance: user.questionnaire ? user.questionnaire.riskTolerance || "" : "",
    investmentExperience: user.questionnaire ? user.questionnaire.investmentExperience || "" : "",
    investmentYears: user.questionnaire ? user.questionnaire.investmentYears || "" : "",
    investmentSize: user.questionnaire ? user.questionnaire.investmentSize || "" : ""
  })

  const handleFormChange = (prop) => (event) => setFormValues({ ...formValues, [prop]: event.target.value })

  const handleSaveChanges = () => {
    const dbObj = {
      questionnaire: {
        investorStatus: formValues.investorStatus,
        riskTolerance: formValues.investorStatus === "Natural Person" ? formValues.riskTolerance : "",
        investmentExperience: formValues.investorStatus === "Natural Person" ? formValues.investmentExperience : "",
        investmentYears: formValues.investorStatus === "Natural Person" ? formValues.investmentYears : "",
        investmentSize: formValues.investorStatus === "Natural Person" ? formValues.investmentSize : ""
      }
    }

    // Update user in rtdb
    axios.post("/api/users/update", {
      userId: user.id,
      dbObj
    }).then(res => {
      // Update document in store
      dispatch(updateUser({
        id: user.id,
        ...dbObj
      }))

      // udpate is complete
      dispatch(setAlert({ message: `Updates to user ${user.fname} ${user.lname} have been saved`, severity: "success" }))
    }).catch(error => {
      console.log("error writing to db", error.response.data)
      dispatch(setAlert({ message: "Error updating user", severity: "error" }))
    })

  }

  return (
    <Box sx={{ pt: 2, px: 4, pb: 3 }}>
      <Box display="flex" justifyContent="flex-end">
        <Stack alignItems="center" direction="row" pb={2}>
          <Typography color="text.secondary" fontSize=".75rem">Unlock form to edit</Typography>
          <Tooltip title={formDisabled ? "Unlock Form" : "Lock Form"}>
            <Switch
              aria-label="toggle form lock"
              checked={!formDisabled}
              onClick={() => setFormDisabled(!formDisabled)}
            />
          </Tooltip>
          {formDisabled ? (
            <LockIcon sx={{ color: 'text.secondary', marginBottom: '3px' }} />
          ) : (
            <LockOpenIcon sx={{ color: 'text.secondary', marginBottom: '3px' }} />
          )}
        </Stack>
      </Box>
      <UserAccreditationStatusForm
        formValues={formValues}
        handleFormChange={handleFormChange}
        disabled={formDisabled}
      />
      <Button
        disabled={formDisabled}
        onClick={() => handleSaveChanges()}
        variant="contained"
      >
        Save Changes
      </Button>
    </Box>
  )
}
 
export default UserAccreditationStatus
