import React from "react"
import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"
import { DateTime } from "luxon"

import useMediaQuery from "@mui/material/useMediaQuery"
import { styled } from "@mui/material/styles"
import { Box, Checkbox, Grid, TablePagination } from "@mui/material"

import { selectUsersAdminList } from "../../../app/store/usersSlice"

import DocumentsTableHead from "./DocumentsTableHead.component"

const StyledContainer = styled(Box)(({ theme }) => ({
  fontSize: '.875rem',
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    fontSize: '.875rem',
  },
  '& .MuiCheckbox-root': {
    padding: '4px',
    [theme.breakpoints.up('sm')]: {
      padding: '9px',
    },
  }
}))

const StyledHead = styled(Grid)(({ theme }) => ({
  borderBottom: '1px solid ' + theme.palette.grey[300],
}))

const StyledRow = styled(Grid)(({ theme }) => ({
  borderBottom: '1px solid ' + theme.palette.grey[300],
  cursor: 'pointer',
  overflow: 'hidden',
  '&:hover': {
    backgroundColor: theme.palette.grey[100],
  },
}))

const StyledCell = styled(Grid)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  padding: '.5rem',
  width: '200px', // prevents cell from expanding to fit text
  [theme.breakpoints.up('sm')]: {
    padding: '0.25rem',
  },
}))

const StyledSpanTruncated = styled('span')(() => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}))

const StyledTablePagination = styled(TablePagination)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    marginTop: '.5rem',
    '& .MuiToolbar-root': {
      justifyContent: 'space-between',
      '& .MuiTablePagination-spacer': {
        display: 'none',
      },
      '& .MuiTablePagination-selectLabel': {
        display: 'none',
      },
      '& .MuiInputBase-root': {
        display: 'none',
      },
      '& .MuiTablePagination-actions': {
        margin: 0,
      }
    },
  }
}))

const DocumentsTable = ({ documents, selected, setSelected }) => {
  const navigate = useNavigate()
  const admins = useSelector(selectUsersAdminList)
  const isSmScreen = useMediaQuery("(min-width: 600px)")
  const isMdScreen = useMediaQuery("(min-width: 900px)")

  const [order, setOrder] = React.useState('asc')
  const [orderBy, setOrderBy] = React.useState('description')
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(25)

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = documents.map((n) => n.id);
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      )
    }

    setSelected(newSelected)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const isSelected = (id) => selected.indexOf(id) !== -1

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - documents.length) : 0

  return (
    <StyledContainer>
      <StyledHead container>
        <DocumentsTableHead
          numSelected={selected.length}
          order={order}
          orderBy={orderBy}
          onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          rowCount={documents.length}
        />
      </StyledHead>
      {documents
        .slice()
        .sort((a, b) => {
          if (orderBy === "description" && order === "asc") {
            return a.description > b.description ? 1 : -1
          } else if (orderBy === "description" && order === "desc") {
            return a.description < b.description ? 1 : -1
          } else if (orderBy === "filename" && order === "asc") {
            return a.filename > b.filename ? 1 : -1
          } else if (orderBy === "filename" && order === "desc") {
            return a.filename < b.filename ? 1 : -1
          } else if (orderBy === "createdBy" && order === "asc") {
            return a.createdBy > b.createdBy ? 1 : -1
          } else if (orderBy === "createdBy" && order === "desc") {
            return a.createdBy < b.createdBy ? 1 : -1
          } else if (orderBy === "created" && order === "asc") {
            return a.created > b.created ? 1 : -1
          } else if (orderBy === "created" && order === "desc") {
            return a.created < b.created ? 1 : -1
          } else { return 1 }
        })
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((document, index) => {
          const isItemSelected = isSelected(document.id)
          const labelId = `enhanced-table-checkbox-${index}`

          const createdBy = admins.filter(admin => admin.id === document.createdBy)[0]

          if (document.filename) {
            return (
              <StyledRow
                container
                aria-checked={isItemSelected}
                key={document.id}
                onClick={() => navigate(`/admin/documents/${document.id}`)}
                role="checkbox"
                selected={isItemSelected}
                tabIndex={-1}
              >
                <StyledCell item id={document.id} xs={8} sm={6} md={4}>
                  <Checkbox
                    color="primary"
                    checked={isItemSelected}
                    id={labelId}
                    inputProps={{ 'aria-labelledby': labelId }}
                    onClick={e => {
                      e.stopPropagation()
                      handleClick(e, document.id)
                    }}
                    padding="checkbox"
                  />
                  <StyledSpanTruncated>
                    {document.description}
                  </StyledSpanTruncated>
                </StyledCell>
                {isMdScreen && (
                  <StyledCell item md={3}>
                    <StyledSpanTruncated>
                      {document.filename}
                    </StyledSpanTruncated>
                  </StyledCell>
                )}
                <StyledCell item xs={3} sm={2} md={2}>
                  <StyledSpanTruncated>
                    {document.created && (
                      isSmScreen ? (
                        DateTime.fromISO(document.created).toFormat('LL/dd/yyyy')
                      ) : (
                        DateTime.fromISO(document.created).toFormat('L/d/yy')
                      )
                    )}
                  </StyledSpanTruncated>
                </StyledCell>
                {isSmScreen && (
                  <StyledCell item xs={3} sm={3} md={2}>
                    <StyledSpanTruncated>
                      {`${createdBy.lname}, ${createdBy.fname}`}
                    </StyledSpanTruncated>
                  </StyledCell>
                )}
                <StyledCell item xs={1} sm={1} md={1} justifyContent="center">
                  {document.assigned || 0}
                </StyledCell>
              </StyledRow>
            )
          } else {
            return <></>
          }
        })
      }
      {emptyRows > 0 && (
        <Grid item xs={12} style={{ height: 53 * emptyRows }}>
          <></>
        </Grid>
      )}
      <StyledTablePagination
        rowsPerPageOptions={[25, 50, 75]}
        component="div"
        count={documents.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </StyledContainer>
  )
}
 
export default DocumentsTable
