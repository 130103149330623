import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { DateTime } from "luxon"
import axios from "axios"

import useMediaQuery from "@mui/material/useMediaQuery"
import { styled } from "@mui/material/styles"
import { Box, Card, Grid, Stack, Typography } from "@mui/material"

import { selectUsersList } from "../../../app/store/usersSlice"
import { selectCommunicationsList, updateCommunication } from "../../../app/store/communicationsSlice"

import CommunicationsDrawer from "../../../common/sharedComponents/CommunicationsDrawer.component"

const StyledRow = styled(Grid)(({ theme }) => ({
  borderBottom: '1px solid ' + theme.palette.grey[300],
  cursor: 'pointer',
  overflow: 'hidden',
  '&:hover': {
    backgroundColor: theme.palette.grey[100],
  },
}))

const StyledCell = styled(Grid)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  width: '200px', // prevents cell from expanding to fit text
}))

const StyledSpanTruncated = styled('span')(() => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}))

const RecentCommunications = () => {
  const isMedium = useMediaQuery("(min-width: 900px)")
  const dispatch = useDispatch()

  const users = useSelector(selectUsersList)
  const communications = useSelector(selectCommunicationsList)
  const unreadCommunications = communications
    .slice()
    .sort((a, b) => {
      if (a.created === b.created) return 0
      else if (a.created === null) return 1
      else if (b.created === null) return -1
      else return a.created > b.created ? -1 : 1
    })
    .slice(0, 8)

  const [drawerOpen, setDrawerOpen] = useState(false)
  const [activeCommunication, setActiveCommunication] = useState(null)

  const handleOpenCommunication = communication => {
    // mark communication as read
    if (!communication.read) {
      axios.post("/api/communications/mark-read-admin", {
        communicationId: communication.id
      }).then(() => {
        dispatch(updateCommunication({
          id: communication.id,
          adminRead: true
        }))
      }).catch(error => {
        console.log("error marking communication as read")
      })
    }

    // open drawer
    setActiveCommunication(communication)
    setDrawerOpen(true)
  }

  const handleCloseCommunication = () => {
    setDrawerOpen(false)
    setActiveCommunication(null)
  }

  return (
    <Box display="flex" flexDirection="column" flexGrow="1">
      <Card sx={{
        flexGrow: '1',
        fontSize: '.875rem',
        p: { xs: 2, md: 4 }
      }}>
        <Typography component="h6" variant="h6" fontSize="1rem" pb={2}>
          Recent Messages
        </Typography>
        {unreadCommunications.length > 0 ? (
          <>
            <Grid container
              borderBottom="1px solid"
              borderColor="grey.300"
              columnSpacing={1}
            >
              <Grid item xs={8} md={4} lg={6}>
                <Typography component="span" fontSize=".875rem" fontWeight="500">Subject</Typography>
              </Grid>
              {isMedium && (
                <>
                  <Grid item md={3} lg={2}>
                    <Typography component="span" fontSize=".875rem" fontWeight="500">Recipients</Typography>
                  </Grid>
                  <Grid item md={3} lg={2}>
                    <Typography component="span" fontSize=".875rem" fontWeight="500">Sender</Typography>
                  </Grid>
                </>
              )}
              <Grid item xs={4} md={2} lg={2} align="right">
                <Typography component="span" fontSize=".875rem" fontWeight="500">
                  Sent
                </Typography>
              </Grid>
            </Grid>
            {unreadCommunications.map(communication => {
              let sender = "", recipient = ""
              if (users.length) {
                const senderObj = users.filter(user => user.id === communication.sender)[0]
                sender = senderObj ? `${senderObj.lname}, ${senderObj.fname}` : ""
                const recipientObj = users.filter(user => user.id === communication.recipientId)[0]
                recipient = recipientObj ? `${recipientObj.lname}, ${recipientObj.fname}` : ""
              }

              return (
                <React.Fragment key={communication.id}>
                  <StyledRow container
                    columnSpacing={1}
                    onClick={() => handleOpenCommunication(communication)}
                    py={1}
                  >
                    <StyledCell item 
                      fontWeight={!communication.adminRead ? "600" : "400"}
                      xs={8}
                      md={4}
                      lg={6}
                    >
                      <StyledSpanTruncated>
                        {communication.subject}
                      </StyledSpanTruncated>
                    </StyledCell>
                    {isMedium && (
                      <>
                        <Grid item md={3} lg={2}>
                          <StyledSpanTruncated>
                            {recipient}
                          </StyledSpanTruncated>
                        </Grid>
                        <Grid item md={3} lg={2}>
                          <StyledSpanTruncated>
                            {sender}
                          </StyledSpanTruncated>
                        </Grid>
                      </>
                    )}
                    <Grid item xs={4} md={2} lg={2} align="right">
                      {communication.created && (
                        isMedium ? (
                          DateTime.fromISO(communication.created).toFormat('LL/dd/yyyy')
                        ) : (
                          DateTime.fromISO(communication.created).toFormat('L/d/yy')
                        )
                      )}
                    </Grid>
                  </StyledRow>
                  <CommunicationsDrawer
                    drawerOpen={drawerOpen}
                    handleDrawerClose={handleCloseCommunication}
                    communicationId={activeCommunication ? activeCommunication.id : ""}
                  />
                </React.Fragment>
              )
            })}
          </>
        ) : (
          <Stack spacing={1} alignItems="center">
            <Typography fontSize={{ xs: '4rem', sm: '6rem' }}>0</Typography>
            <Typography fontSize=".875rem">Unread Messages</Typography>
          </Stack>
        )}
      </Card>
    </Box>
  )
}
 
export default RecentCommunications
