import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import axios from "axios"

import { Box, Paper, Typography } from "@mui/material"

import { setAlert } from "../../../app/store/alertsSlice"
import { removeUser, selectUsersList } from "../../../app/store/usersSlice"

import Breadcrumbs from "../../../common/sharedComponents/Breadcrumbs.component"
import PageTitle from "../../../common/sharedComponents/PageTitle.component"
import UsersTableToolbar from "./UsersTableToolbar.component"
import UsersTable from "./UsersTable.component"

const UsersPage = () => {
  const dispatch = useDispatch()
  const users = useSelector(selectUsersList)
  
  const [selected, setSelected] = useState([])
  const [search, setSearch] = useState("")

  const handleDeleteUsers = usersToDelete => {
    for (let i = 0; i < usersToDelete.length; i++) {
      const userId = usersToDelete[i]
      const email = users.filter(user => user.id === userId)[0].username
 
      // delete user from auth
      axios.post("/api/firebase/auth/delete", { email }).then(res => {
        // delete database node
        axios.post("/api/users/remove", {
          userId
        }).then(res => {
          // remove user from store
          dispatch(removeUser(userId))

          // remove is complete
          dispatch(setAlert({ message: `${selected.length > 1 ? "Users have" : "User has"} been permanently deleted`, severity: "success" }))
        }).catch(error => {
          console.log('error deleting user', error.response.data)
          dispatch(setAlert({ message: "Unable to remove user from database", severity: "error" }))
        })
      }).catch(error => {
        console.log("Unable to delete user: ", error.response.data.message)
        dispatch(setAlert({ message: "Unable to delete user", severity: "error" }))
      })
    }
    
    setSelected([])
  }

  const getVisibleUsers = () => {
    return users.filter(user => {
      return user.fname.toLowerCase().includes(search.toLowerCase()) ||
            user.lname.toLowerCase().includes(search.toLowerCase()) ||
            user.username.toLowerCase().includes(search.toLowerCase()) ||
            user.company.toLowerCase().includes(search.toLowerCase())
    })
  }
  
  return (
    <>
      <Breadcrumbs
        pages={[
          { title: "Admin", to: "/admin" },
          { title: "Users" },
        ]}
      />
      <PageTitle title="Manage Users" />
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <UsersTableToolbar
            users={users}
            selected={selected}
            handleDeleteUsers={handleDeleteUsers}
            search={search}
            setSearch={setSearch}
          />
          {users.length > 0 ? (
            <UsersTable
              users={getVisibleUsers()}
              selected={selected}
              setSelected={setSelected}
            />
          ) : (
            <Typography fontSize=".875rem" p={4}>
              No users found
            </Typography>
          )}
        </Paper>
      </Box>
    </>
  )
}

export default UsersPage
