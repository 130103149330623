import React from "react"

import {
  Avatar,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Link,
  Stack,
  Typography,
} from "@mui/material"

import aquilaImg from "../../../assets/images/aquila-lee.jpg"
import richardImg from "../../../assets/images/richard-chenel.jpg"
import robertImg from "../../../assets/images/robert-malonoski-profile.jpg"
import skyline from "../../../assets/images/heroXs.jpg"

const team = [
  {
    fname: "Richard",
    lname: "Chenel",
    img: richardImg,
    title: "Founder & Managing Partner",
    email: "richard.chenel@chenelcapital.com",
    hubspot: "",
  },
  {
    fname: "Robert",
    lname: "Malonoski",
    img: robertImg,
    title: "Vice President",
    email: "robert.malonoski@chenelcapital.com",
    hubspot: "https://meetings.hubspot.com/bobby-malonoski",
  },
  {
    fname: "Aquila",
    lname: "Lee",
    img: aquilaImg,
    title: "Associate",
    email: "aquila.lee@chenelcapital.com",
    hubspot: "",
  },
]

const ProfileCard = ({ person }) => {
  const { fname, lname, img, title, email, hubspot } = person

  return (
    <Card sx={{ maxWidth: "400px" }}>
      <CardMedia
        component="img"
        image={skyline}
        sx={{ backgroundColor: "primary.main", height: "100px" }}
      />
      <CardContent>
        <Stack spacing={2} sx={{ alignItems: "center", marginTop: "-72px" }}>
          <Avatar
            alt={`${fname} ${lname}`}
            src={img}
            sx={{
              border: "3px solid #fff",
              height: 128,
              width: 128,
            }}
          />
          <Stack pb={2} pt={1}>
            <Typography
              sx={{
                fontSize: "1.125rem",
                fontWeight: "500",
              }}
            >
              {`${fname} ${lname}`}
            </Typography>
            <Typography
              sx={{
                color: "text.secondary",
                fontSize: ".875rem",
              }}
            >
              {title}
            </Typography>
            <Typography
              component="span"
              sx={{
                alignItems: "center",
                display: "flex",
                fontSize: ".875rem",
                marginTop: 2,
              }}
            >
              <Link href={`mailto:${email}`}>{email}</Link>
            </Typography>
            {hubspot && (
              <Typography
                sx={{
                  alignItems: "center",
                  display: "flex",
                  fontSize: ".875rem",
                  mt: .5
                }}
              >
                <Link href={hubspot}>Meet with {fname}</Link>
              </Typography>
            )}
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  )
}

const InvestmentTeam = () => {
  return (
    <Grid container spacing={3}>
      {team.map(person => (   
        <Grid item key={person.email} xs={12} sm={6} md={12}>
          <ProfileCard person={person} />
        </Grid>
      ))}
    </Grid>
  )
}

export default InvestmentTeam
