import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  key: null,
  message: "",
  severity: "info",
}

export const alertsSlice = createSlice({
  name: 'alerts',
  initialState,
  reducers: {
    setAlert: (state, action) => ({
      key: new Date().getTime(),
      ...action.payload
    }),
    clearAlert: () => (initialState),
  },
});

export const {
  setAlert,
  clearAlert
} = alertsSlice.actions

export const selectAlertInfo = (state) => state.alerts

export default alertsSlice.reducer
