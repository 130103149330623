import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getStorage, ref as sRef, uploadBytesResumable, getDownloadURL } from "firebase/storage"
import axios from "axios"
import { nanoid } from "nanoid"

import { Box, IconButton, Modal, Typography } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"

import { selectUserId } from "../../../app//store/authSlice"
import { setAlert } from "../../../app/store/alertsSlice"
import { addDocument } from "../../../app/store/documentsSlice"

import AddDocumentUpload from "./AddDocumentUpload.component"
import DocumentForm from "./DocumentForm.component"

export const getFilenameBase = (filename) => filename ? filename.substring(0, filename.search(/\.[^/.]+$/)) : ""
export const getFilenameExt = (filename) => filename ? filename.substring(filename.search(/\.[^/.]+$/), filename.length) : ""

const outerStyle = {
  bgcolor: 'background.paper',
  border: '1px solid #666',
  borderRadius: '5px',
  boxShadow: 24,
  height: 'auto',
  left: '50%',
  maxHeight: '90vh',
  maxWidth: '800px',
  overflowY: 'auto',
  position: 'absolute',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  width: '98%',
}

const innerStyle = {
  px: 3,
  py: 2
}

const defaultDocument = {
  id: "",
  asigned: "",
  created: "",
  createdBy: "",
  description: "",
  downloadUrl: "",
  filename: "",
  path: "",
  permissions: []
}

const AddDocumentModal = ({ modalOpen, handleCloseModal }) => {
  const storage = getStorage()
  const dispatch = useDispatch()
  const userId = useSelector(selectUserId)
  const path = "documents/"
  
  const [uploading, setUploading] = useState(false)
  const [percentUploaded, setPercentUploaded] = useState(0)
  const [file, setFile] = useState(null)
  const [document, setDocument] = useState(defaultDocument)

  // Update document with filename when file is added
  useEffect(() => {
    if (file) {
      const filenameBase = getFilenameBase(file.name).replace(/[^a-z0-9]/gi, '-').toLowerCase().substring(0,21)
      const filenameExt = getFilenameExt(file.name)
      setDocument({
        ...defaultDocument,
        filename: filenameBase + filenameExt
      })
    }
  }, [file])

  const handleSaveDocument = (formData, sendEmail = false, sendTo = []) => {
    setUploading(true)

    // upload file to storage
    const storageRef = sRef(storage, path + formData.filename)
    const metadata = { customMetadata: { owner: userId } }
    const uploadTask = uploadBytesResumable(storageRef, file, metadata)

    // track file upload progress and get downloadURL on complete
    uploadTask.on('state_changed', (snap) => {
      const percentUploaded = Math.round((snap.bytesTransferred / snap.totalBytes) * 100)
      setPercentUploaded(percentUploaded)
    }, (error) => {
      setPercentUploaded(0)
      dispatch(setAlert({ message: "Error uploading file. Please try again.", severity: "error" }))
      console.error("error uploading file:", error)
    }, () => {
      getDownloadURL(uploadTask.snapshot.ref).then(downloadUrl => {
        // Add new document to database after retreiving downloadUrl
        const dbObj = {
          ...formData,
          filetype: file.type,
          path,
          downloadUrl
        }
        const documentId = nanoid()

        axios.post("/api/documents/add", {
          documentId,
          dbObj
        }).then(() => {
          // send email notification to user(s)
          if (sendEmail && sendTo) {
            sendTo.forEach(email => {
              const emailOptions = {
                from: "Chenel Capital <noreply@mg.chenelcapital.com>",
                to: email,
                subject: "New document is available",
                template: "new-document",
                mgVariables: {
                  'v:description': formData.description,
                  'v:unsubURL': 'https://investors.chenelcapital.com/unsubscribe/abc123'
                }
              }
              axios.post("/api/mail/send", emailOptions).catch(error => {
                console.log('error sending email', error.message)
                dispatch(setAlert({ message: "Document created but error sending email(s)", severity: "warning" }))
              })
            })
          }
          
          // Add new document to store
          dispatch(addDocument({
            id: documentId,
            assigned: formData.permissions ? formData.permissions.length : 0,
            ...formData,
            path,
            downloadUrl
          }))
          
          // document(s) add complete
          dispatch(setAlert({ message: "Document has been added", severity: "success" }))

          setFile(null)
          setDocument(defaultDocument)
          handleCloseModal()
        }).catch(error => {
          console.log('unable to upload file', error.response.data)
          dispatch(setAlert({ message: "Unable to upload file", severity: "error" }))
        })
      })
      setPercentUploaded(0)
      setUploading(false)
    })
  }

  return (
    <Modal
      open={modalOpen}
      onClose={() => {
        setFile(null)
        setDocument(defaultDocument)
        handleCloseModal()
      }}
      aria-labelledby="modal-title"
    >
      <Box sx={outerStyle}>
        <Box sx={innerStyle}>
          <Box alignItems="center" display="flex" justifyContent="space-between">
            <Typography component="h2" variant="h6" id="modal-title">
              Add a New Document
            </Typography>
            <IconButton
              onClick={handleCloseModal}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Box sx={{ pt: 3 }}>
            <AddDocumentUpload
              file={file}
              setFile={setFile}
              uploading={uploading}
              percentUploaded={percentUploaded}
            />
            <DocumentForm
              document={document}
              handleCloseModal={() => {
                setFile(null)
                setDocument(defaultDocument)
                handleCloseModal()
              }}
              handleSaveDocument={handleSaveDocument}
            />
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}
 
export default AddDocumentModal
