import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getStorage, ref as sRef, deleteObject } from "firebase/storage"
import axios from "axios"

import { Box, Paper, Typography } from "@mui/material"

import { setAlert } from "../../../app/store/alertsSlice"
import { removeDocument, selectDocumentsList } from "../../../app/store/documentsSlice"

import Breadcrumbs from "../../../common/sharedComponents/Breadcrumbs.component"
import PageTitle from "../../../common/sharedComponents/PageTitle.component"
import DocumentsTableToolbar from "./DocumentsTableToolbar.component"
import DocumentsTable from "./DocumentsTable.component"

const DocumentsPage = () => {
  const dispatch = useDispatch()
  const documents = useSelector(selectDocumentsList)
  const storage = getStorage()

  const [selected, setSelected] = useState([])
  const [search, setSearch] = useState("")

  const handleDeleteDocuments = documentsToDelete => {
    for (let i = 0; i < documentsToDelete.length; i++) {
      const documentId = documentsToDelete[i]
      const documentToDelete = documents.filter(document => document.id === documentId)[0]
      const file = documentToDelete.path + documentToDelete.filename

      // delete database node
      axios.post("/api/documents/remove", {
        documentId
      }).then(res => {
        // remove document from store
        dispatch(removeDocument(documentId))

        // delete the file from storage
        deleteObject(sRef(storage, file)).then(() => {
          // remove process is complete
          dispatch(setAlert({ message: `${selected.length > 1 ? "Documents have" : "Document has"} been permanently deleted`, severity: "success" }))
        }).catch((error) => {
          dispatch(setAlert({ message: "Document removed from database but unable to delete from storage. ", severity: "warning" }))
        })
      }).catch(error => {
        console.log('error deleting document', error.response.data)
        dispatch(setAlert({ message: "Unable to delete document from database", severity: "error" }))
      })

    }
    
    setSelected([])
  }

  const getVisibleDocuments = (allDocuments) => {
    return allDocuments.filter(document => {
      return document.id.toLowerCase().includes(search.toLowerCase()) ||
             document.filename.toLowerCase().includes(search.toLowerCase()) ||
             document.description.toLowerCase().includes(search.toLowerCase()) ||
             document.createdBy.toLowerCase().includes(search.toLowerCase())
    })
  }

  return (
    <>
      <Breadcrumbs
        pages={[
          { title: "Admin", to: "/admin" },
          { title: "Documents" },
        ]}
      />
      <PageTitle title="Manage Documents" />
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <DocumentsTableToolbar
            selected={selected}
            handleDeleteDocuments={handleDeleteDocuments}
            search={search}
            setSearch={setSearch}
          />
          {documents.length > 0 ? (
            <DocumentsTable
              documents={getVisibleDocuments(documents)}
              selected={selected}
              setSelected={setSelected}
            />
          ) : (
            <Typography fontSize=".875rem" p={4}>
              No documents found
            </Typography>
          )}
        </Paper>
      </Box>
    </>
  )
}
 
export default DocumentsPage
