import React from "react"
import { Outlet } from "react-router-dom"

import { styled } from '@mui/material/styles'
import { Box } from "@mui/material"

import AlertStack from "./AlertStack.component"

import heroXs from "../../assets/images/heroXs.jpg"
import heroSm from "../../assets/images/heroSm.jpg"
import heroMd from "../../assets/images/heroMd.jpg"
import heroLg from "../../assets/images/heroLg.jpg"
import heroXl from "../../assets/images/heroXl.jpg"

const StyledBackground = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  backgroundImage: `url(${heroXs})`,
  backgroundSize: 'cover',
  display: 'flex',
  height: '100vh',
  justifyContent: 'center',
  width: '100vw',
  [theme.breakpoints.up('sm')]: {
    backgroundImage: `url(${heroSm})`,
  },
  [theme.breakpoints.up('md')]: {
    backgroundImage: `url(${heroMd})`,
  },
  [theme.breakpoints.up('lg')]: {
    backgroundImage: `url(${heroLg})`,
  },
  [theme.breakpoints.up('xl')]: {
    backgroundImage: `url(${heroXl})`,
  },
}))

const LoginLayout = () => {
  return (
    <StyledBackground>
      <Outlet />
      <AlertStack />
    </StyledBackground>
  )
}
 
export default LoginLayout
